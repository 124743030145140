import { computed, makeObservable, observable } from 'mobx';
import { Contact, CONTACT_ALL_GQL_QUERY_FIELDS } from './contact';
import { ContactLocation } from './contact_location';
import { ContactEmail } from './contact_email';
import { ContactPhone } from './contact_phone';
import { formatMessage } from "@gazelle/shared/utils";
import { MSG_unnamedClientTitle } from "@gazelle/shared/strings";

class Client {
  id: string = null;
  companyName: string = null;
  contacts: Contact[] = null;
  hasMoreContacts: boolean = null;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.companyName = attrs.companyName;

    this.contacts = [];
    if (attrs.allContacts) {
      for (let node of attrs.allContacts.nodes) {
        this.contacts.push(new Contact(node));
      }
    }

    makeObservable(this, {
      id: observable,
      companyName: observable,
      contacts: observable,
      hasMoreContacts: observable,
      isCompany: computed,
      displayName: computed,
      defaultContact: computed,
      defaultBillingContact: computed,
      streetLocation: computed,
      mailingLocation: computed,
      billingLocation: computed,
      defaultPhone: computed,
      defaultEmail: computed
    });
  }

  get isCompany(): boolean {
    return !!this.companyName;
  }

  get displayName(): string {
    if (this.isCompany) {
      return this.companyName;
    } else if (this.defaultContact) {
      return this.defaultContact.fullName;
    } else {
      return formatMessage(MSG_unnamedClientTitle);
    }
  }

  get defaultContact(): Contact {
    for (let contact of this.contacts) {
      if (contact.isDefault) {
        return contact;
      }
    }
    return null;
  }

  get defaultBillingContact(): Contact {
    for (let contact of this.contacts) {
      if (contact.isBillingDefault) {
        return contact;
      }
    }
    return null;
  }

  get streetLocation(): ContactLocation {
    if (!this.defaultContact) return null;
    return this.defaultContact.streetLocation;
  }

  get mailingLocation(): ContactLocation {
    if (!this.defaultContact) return null;
    return this.defaultContact.mailingLocation;
  }

  get billingLocation(): ContactLocation {
    if (this.defaultBillingContact) {
      return this.defaultBillingContact.billingLocation;
    } else if (this.defaultContact) {
      return this.defaultContact.billingLocation;
    } else {
      return null;
    }
  }

  get defaultPhone(): ContactPhone {
    if (!this.defaultContact) return null;
    return this.defaultContact.defaultPhone;
  }

  get defaultEmail(): ContactEmail {
    if (!this.defaultContact) return null;
    return this.defaultContact.defaultEmail;
  }
}

const CLIENT_ALL_GQL_QUERY_FIELDS = `
  id companyName
  allContacts {
    nodes { ${CONTACT_ALL_GQL_QUERY_FIELDS} }
  }
  defaultContact {
    ${CONTACT_ALL_GQL_QUERY_FIELDS}
  }
  defaultBillingContact {
    ${CONTACT_ALL_GQL_QUERY_FIELDS}
  }
`;

export { Client, CLIENT_ALL_GQL_QUERY_FIELDS };
