import { makeObservable, observable } from 'mobx';

interface IGeocode {
  lat: number;
  lng: number;
}
class Geocode implements IGeocode {
  lat: number = null;
  lng: number = null;

  constructor(attrs: any) {
    makeObservable(this, {
      lat: observable,
      lng: observable
    });

    this.lat = attrs?.lat;
    this.lng = attrs?.lng;
  }
}

export { Geocode, IGeocode };
