import gql from "graphql-tag";

export const PrivateEstimateChecklistItemFragment = gql`
  fragment PrivateEstimateChecklistItemFragment on PrivateEstimateChecklistItem {
    id name sequenceNumber
    masterServiceItem {
      id name description educationDescription duration amount type externalUrl order isDefault isTuning isTaxable isArchived isAnyUser isSelfSchedulable
      masterServiceGroup { id name }
    }
    estimateChecklistGroup { id }
  }
`;

export const PrivateEstimateChecklistDetailFragment = gql`
  fragment PrivateEstimateChecklistDetailFragment on PrivateEstimateChecklist {
    id name isDefault
    allEstimateChecklistGroups {
      id name sequenceNumber
      allEstimateChecklistItems {
        ...PrivateEstimateChecklistItemFragment
      }
    }
    allUngroupedEstimateChecklistItems {
      ...PrivateEstimateChecklistItemFragment
    }
  }
  ${PrivateEstimateChecklistItemFragment}
`;



// ### QUERIES ############################################################################################

export const PrivateAllEstimateChecklistQuery = gql`
  query {
    allEstimateChecklists {
      ...PrivateEstimateChecklistDetailFragment
    }
  }
  ${PrivateEstimateChecklistDetailFragment}
`;

export const PrivateEstimateChecklistQuery = gql`
  query ($id: String!) {
    estimateChecklist(id: $id) {
      ...PrivateEstimateChecklistDetailFragment
    }
  }
  ${PrivateEstimateChecklistDetailFragment}
`;


// ### MUTATIONS ############################################################################################

export const PrivateCreateEstimateChecklistMutation = gql`
  mutation ($input: PrivateEstimateChecklistInput!) {
    createEstimateChecklist(input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      estimateChecklist { ...PrivateEstimateChecklistDetailFragment }
    }
  }
  ${PrivateEstimateChecklistDetailFragment}
`;

export const PrivateUpdateEstimateChecklistMutation = gql`
  mutation ($id: String!, $input: PrivateEstimateChecklistInput!) {
    updateEstimateChecklist(id: $id, input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      estimateChecklist { ...PrivateEstimateChecklistDetailFragment }
    }
  }
  ${PrivateEstimateChecklistDetailFragment}
`;

export const PrivateDeleteEstimateChecklistMutation = gql`
  mutation ($id: String!) {
    deleteEstimateChecklist(id: $id) {
      mutationErrors { fieldName key messages type errorReferenceId }
      isDeleted
    }
  }
`;
