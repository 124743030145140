// ### FRAGMENTS ###########################################################################################

import gql from "graphql-tag";

export const PrivateSchedulerLocationFragment = gql`
  fragment PrivateSchedulerLocationFragment on PrivateSchedulerLocation {
    id type addressLine coordinates { latitude longitude }
    manualLat manualLng
  }
`;

export const PrivateSchedulerGeocodedLocationFragment = gql`
  fragment PrivateSchedulerGeocodedLocationFragment on PrivateSchedulerLocation {
    id type addressLine coordinates { latitude longitude }
    manualLat manualLng
  }
`;

export const PrivateSchedulerServiceAreaFragment = gql`
  fragment PrivateSchedulerServiceAreaFragment on PrivateSchedulerServiceArea {
    id name algorithm buffer includeTraffic invalidAddressTravelTime travelMode
    maxGoodTravelTimeMinutes openDayWeight outsideServiceAreaMinutes routingPreference isSelfSchedulable
    selfScheduleMaxTravelTimes {
      daysFromToday maxTravelTime
    }
    polygonParameter {
      id shapes {
        id type name inclusionMethod
        circleCenter { latitude longitude } circleRadius circleRadiusUnit
        polygonPoints {
          latitude longitude
        }
      }
    }
    radialParameter {
      id travelTime
      center { type addressLine coordinates { latitude longitude }}
    }
  }
`;

export const PrivateSchedulerAvailabilityFragment = gql`
  fragment PrivateSchedulerAvailabilityFragment on PrivateSchedulerAvailability {
    id name isExclusive
    startDate startTime startOfDayType startOfDayLocation { ...PrivateSchedulerLocationFragment }
    endDate endTime endOfDayType endOfDayLocation { ...PrivateSchedulerLocationFragment }
    floatingDowntimeRules { id startTime endTime duration }
    maxAppointmentsPerDay preferredSlotPolicy preferredSlotTimes adjustPreferredSlots
    recurrenceRule includeDates excludeDates roundingMinutes serviceArea {
      ...PrivateSchedulerServiceAreaFragment
    }
  }
  ${PrivateSchedulerServiceAreaFragment}
  ${PrivateSchedulerLocationFragment}
`;

export const PrivateSchedulerGeocodedAvailabilityFragment = gql`
  fragment PrivateSchedulerGeocodedAvailabilityFragment on PrivateSchedulerAvailability {
    id name isExclusive
    startDate startTime startOfDayType startOfDayLocation { ...PrivateSchedulerGeocodedLocationFragment }
    endDate endTime endOfDayType endOfDayLocation { ...PrivateSchedulerGeocodedLocationFragment }
    floatingDowntimeRules { id startTime endTime duration }
    maxAppointmentsPerDay preferredSlotPolicy preferredSlotTimes
    recurrenceRule includeDates excludeDates roundingMinutes serviceArea { id name }
  }
  ${PrivateSchedulerGeocodedLocationFragment}
`;

// ### QUERIES ###########################################################################################

export const PrivateAllSchedulerAvailabiltiesQuery = gql`
  query ($userId: String) {
    allSchedulerAvailabilities(userId: $userId) {
      ...PrivateSchedulerAvailabilityFragment
    }
  }
  ${PrivateSchedulerAvailabilityFragment}
`;

export const PrivateAllSchedulerServiceAreasQuery = gql`
  query ($userId: String, $allActiveUsers: Boolean) {
    allSchedulerServiceAreas(userId: $userId, allActiveUsers: $allActiveUsers) {
      ...PrivateSchedulerServiceAreaFragment
    }
  }
  ${PrivateSchedulerServiceAreaFragment}
`;

// ### QUERIES ###########################################################################################

export const PrivateCreateSchedulerAvailabilityMutation = gql`
  mutation ($userId: String!, $input: PrivateSchedulerAvailabilityInput!) {
    createSchedulerAvailability(userId: $userId, input: $input) {
      mutationErrors { fieldName key messages type errorReferenceId }
      availability {
        ...PrivateSchedulerAvailabilityFragment
      }
    }
  }
  ${PrivateSchedulerAvailabilityFragment}
`;

export const PrivateUpdateSchedulerAvailabilityMutation = gql`
  mutation ($id: String!, $input: PrivateSchedulerAvailabilityInput!) {
    updateSchedulerAvailability(id: $id, input: $input) {
      mutationErrors { fieldName key messages type errorReferenceId }
      availability {
        ...PrivateSchedulerAvailabilityFragment
      }
    }
  }
  ${PrivateSchedulerAvailabilityFragment}
`;

export const PrivateCreateSchedulerServiceAreaMutation = gql`
  mutation ($userId: String!, $input: PrivateSchedulerServiceAreaInput!) {
    createSchedulerServiceArea(userId: $userId, input: $input) {
      mutationErrors { fieldName key messages type errorReferenceId }
      serviceArea {
        ...PrivateSchedulerServiceAreaFragment
      }
    }
  }
  ${PrivateSchedulerServiceAreaFragment}
`;

export const PrivateUpdateSchedulerServiceAreaMutation = gql`
  mutation ($id: String!, $input: PrivateSchedulerServiceAreaInput!) {
    updateSchedulerServiceArea(id: $id, input: $input) {
      mutationErrors { fieldName key messages type errorReferenceId }
      serviceArea {
        ...PrivateSchedulerServiceAreaFragment
      }
    }
  }
  ${PrivateSchedulerServiceAreaFragment}
`;

export const PrivateSendSchedulerV2Feedback = gql`
  mutation ($searchId: String!, $reservationId: String, $feedback: String!) {
    sendSchedulerV2Feedback(searchId: $searchId, reservationId: $reservationId, feedback: $feedback) {
      mutationErrors { fieldName key messages type errorReferenceId }
      success
    }
  }
`;

export const PrivateSchedulerAvailabilityQuery = gql`
  query ($id: String!, $userId: String) {
    schedulerAvailability(id: $id, userId: $userId) {
      ...PrivateSchedulerAvailabilityFragment
    }
  }
  ${PrivateSchedulerAvailabilityFragment}
`;

// ### MUTATIONS ###########################################################################################

export const PrivateMigrateSchedulerV2SettingsMutation = gql`
  mutation ($destructive: Boolean, $userId: String, $onlyDeleteSettings: Boolean) {
    migrateSchedulerV2Settings(destructive: $destructive, userId: $userId, onlyDeleteSettings: $onlyDeleteSettings) {
      mutationErrors { type fieldName key messages errorReferenceId }
      success
    }
  }
`;
