import gql from "graphql-tag";

export const PrivateSupportedLocaleInfoFragment  = gql`
  fragment PrivateSupportedLocaleInfoFragment on CoreSupportedLocaleInfo {
    locale label defaultNumberFormat defaultCurrencyFormat defaultFirstDayOfWeek isLocaleDefault
    monthYearFormat { moment unicode }
    dateFormat { moment unicode }
    weekdayDateFormat { moment unicode }
    timeFormat { moment unicode }
    timezoneTimeFormat { moment unicode }
    dateTimeSeparator
  }
`;
