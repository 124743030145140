import * as React from 'react';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import { Button, Progress } from 'reactstrap';
import {
  AlertModal,
  LargeErrorMessage,
  LoadingIndicator,
  scrollTop,
  SimpleFormat,
  Slot,
  StepContent,
  StepFooter
} from "@core";

import { rootStoreInstance as rootStore } from "@client_scheduler/modules/root";
import { TargetDateSlots } from './TargetDateSlots';
import { OtherDateSlots } from './OtherDateSlots';
import { faMapMarkerAlt, faStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MSG_changePreferencesAndSearchAgainTitle,
  MSG_chooseAppointmentTimeTitle,
  MSG_goodNews,
  MSG_newExpandingSearch1,
  MSG_newExpandingSearch2a,
  MSG_newExpandingSearch2b,
  MSG_newExpandingSearch3,
  MSG_noAppointmentSlotsFoundMessage,
  MSG_noAppointmentSlotsFoundTitle,
  MSG_noAppointmentsOnDateMessage,
  MSG_otherDateSlots,
  MSG_outsideServiceAreaMessage,
  MSG_outsideServiceAreaTitle,
  MSG_searchingCalendarsForAppointments,
  MSG_searchingForAppointments,
  MSG_shortTermDefaultMessage,
  MSG_weHaveAvailability
} from "@gazelle/shared/strings";
import { formatDate, formatMessage } from "@gazelle/shared/utils";

interface IStep4SearchProps {}

const Step4Search = observer(class Step4Search extends React.Component<IStep4SearchProps> {
  private attemptedSlot: Slot = null;
  showShortTermLimitWarning = false;
  showLongTermLimitWarning = false;

  /**
   * Some of our older user-defined strings have different template variables
   * set.  This is here to support the legacy strings.  At some point we should
   * go through and convert them to something more sane.
   */
  replaceVars = (str: string) => {
    return str
      .replace(/{{\s*company.phone\s*}}/, rootStore.companyPhoneNumber)
      .replace(/{{\s*company.name\s*}}/, rootStore.companyName)
      .replace(/{{\s*company.email\s*}}/, rootStore.companyEmail);
  };

  constructor(props: IStep4SearchProps) {
    super(props);

    makeObservable<Step4Search, "attemptedSlot">(this, {
      attemptedSlot: observable,
      showShortTermLimitWarning: observable,
      showLongTermLimitWarning: observable
    });
  }

  componentDidMount() {
    scrollTop();
  }

  handleSlotClicked = (slot: Slot) => {
    console.log(slot);
    if (slot.restrictions.indexOf('short_term_limit') >= 0) {
      this.attemptedSlot = slot;
      this.showShortTermLimitWarning = true;
    } else if (slot.restrictions.indexOf('long_term_limit') >= 0) {
      this.attemptedSlot = slot;
      this.showLongTermLimitWarning = true;
    } else {
      rootStore.searchStore.selectSlot(slot);
      if (rootStore.isStepComplete[4]) {
        rootStore.unlockStep(5);
        rootStore.setCurrentStep(5);
      }
    }
  };

  render() {
    const { searchStore, searchStore: { progress, progressChunk }} = rootStore;
    let content;

    // The initial mutation to launch the query has not completed, so simply show
    // a spinner saying that we are initializing.
    if (searchStore.initializing) {
      content = <LoadingIndicator initializing/>;
    }

    // We have initialized and are polling the API for progress of the query.
    // Display that progress here as a Progress bar.
    else if (searchStore.searching) {
      content = (
        <div className="progress-container text-center mt-4">
          <p>
            {rootStore.prefStore.selectedUsers.length > 1
              ? formatMessage(MSG_searchingCalendarsForAppointments)
              : formatMessage(MSG_searchingForAppointments)}
          </p>
          <Progress value={progress}/>
          <div className="text-end pt-2 text-muted">
            {progressChunk === 2 && formatMessage(MSG_newExpandingSearch1)}
            {progressChunk > 2 && progressChunk % 2 === 1 && (rootStore.prefStore.computedUserIds.length > 1 ? formatMessage(MSG_newExpandingSearch2b) : formatMessage(MSG_newExpandingSearch2a))}
            {progressChunk > 2 && progressChunk % 2 === 0 && formatMessage(MSG_newExpandingSearch3)}
          </div>
        </div>
      );
    }

    // Whoops!  There was some kind of error while searching!
    else if (searchStore.error) {
      console.log(searchStore.error);
      content = <div className="text-danger text-center">{searchStore.error}</div>;
    }

    // The search is complete and we have results.  Now we need to display them.
    // Depending on the shape of the results, we may render a few different things.
    else {

      // If no results were returned at all, display a special message.
      if ((!searchStore.targetDateSlots && searchStore.otherDateSlots.length === 0)) {

        // If we detected that they are outside our service area, AND all other options
        // have been eliminated (which they have, if we got here because there are no
        // slots left in the array)
        if (searchStore.outsideServiceArea) {
          content = (
            <LargeErrorMessage
              icon={faMapMarkerAlt}
              title={formatMessage(MSG_outsideServiceAreaTitle)}
              message={rootStore.companySettings.outsideServiceAreaMessage
                ? (
                  <SimpleFormat
                    text={this.replaceVars(rootStore.companySettings.outsideServiceAreaMessage)}/>
                ) : (
                  <SimpleFormat
                    message={formatMessage(MSG_outsideServiceAreaMessage, {
                        phone: rootStore.companyPhoneNumber,
                        email: rootStore.companyEmail,
                      })}/>
                )}/>
          );

        // If there are no available slots for any other reason.
        } else {
          content = (
            <div>
              <LargeErrorMessage
                icon={faMapMarkerAlt}
                title={formatMessage(MSG_noAppointmentSlotsFoundTitle)}
                message={formatMessage(MSG_noAppointmentSlotsFoundMessage, {phone: rootStore.companyPhoneNumber})}
              />
              <div className="text-center mt-5">
                <Button onClick={() => { rootStore.setCurrentStep(3); }}
                        color="primary">
                  {formatMessage(MSG_changePreferencesAndSearchAgainTitle)}
                </Button>
              </div>
            </div>
          );
        }
      }

      // If there are no options available on the exact day they requested, display
      // some wording accordingly and then just list all the options.
      else if (!searchStore.targetDateSlots) {
        content = (
          <div>
            <div className="centered-information" style={{maxWidth: '600px'}}>
              <h4>{formatMessage(MSG_chooseAppointmentTimeTitle)}</h4>
              <div className="mt-4 mb-4" style={{maxWidth: '575px', margin: 'auto'}}>
                {/*
                  This is intentionally kept as a <FormattedMessage/> component because we needed
                  to embed the STAR icon, which can't be done with formatMessage().
                */}
                <FormattedMessage
                  {...MSG_noAppointmentsOnDateMessage}
                  values={{
                    date: formatDate(rootStore.prefStore.targetDate),
                    star: <FontAwesomeIcon icon={faStar} className="text-color-alert-yellow" />
                  }}
                />
              </div>
            </div>
            <OtherDateSlots otherDateSlots={searchStore.otherDateSlots}
                            onClick={this.handleSlotClicked} />
          </div>
        );
      }

      // If there ARE options available on their target date, show that first
      // followed by all the other options.
      else {
        content = (
          <div>
            <h4 className="text-center">{formatMessage(MSG_goodNews)}</h4>
            <p className="text-center">{formatMessage(MSG_weHaveAvailability)}</p>
            <TargetDateSlots dateSlots={searchStore.targetDateSlots}
                             onClick={this.handleSlotClicked} />

            {searchStore.otherDateSlots.length > 0 &&
              <div>
                <div className="mt-2 mb-3 centered-information" style={{maxWidth: '420px'}}>
                  {/*
                    This is intentionally kept as a <FormattedMessage/> component because we needed
                    to embed the STAR icon, which can't be done with formatMessage().
                  */}
                  <FormattedMessage
                    {...MSG_otherDateSlots}
                    values={{star: <FontAwesomeIcon icon={faStar} className="text-color-alert-yellow" />}} />
                </div>
                <OtherDateSlots otherDateSlots={searchStore.otherDateSlots}
                                onClick={this.handleSlotClicked} />
              </div>}
          </div>
        );
      }
    }

    let shortTermMessage: string = null;
    if (this.attemptedSlot) {
      if (this.attemptedSlot.user.internationalizedShortTermLimitMessage) {
        shortTermMessage = this.replaceVars(this.attemptedSlot.user.internationalizedShortTermLimitMessage.defaultValue);
      } else if (this.attemptedSlot.user.shortTermLimitMessage) {
        shortTermMessage = this.replaceVars(this.attemptedSlot.user.shortTermLimitMessage);
      }
    }

    return (
      <div>
        <StepContent className="step4-search-container" minHeight={rootStore.minHeight}>
          {content}

          {/* SHORT TERM WARNING */}
          {this.attemptedSlot &&
            <AlertModal size="md"
                        isOpen={this.showShortTermLimitWarning}
                        toggle={() => { this.showShortTermLimitWarning = false; }}>
              {shortTermMessage
                ? (
                  <SimpleFormat
                    text={this.replaceVars(shortTermMessage)}/>
                ) : (
                  <SimpleFormat
                    message={formatMessage(MSG_shortTermDefaultMessage, {
                      phone: rootStore.companyPhoneNumber,
                      email: rootStore.companyEmail
                    })}/>
                )}
            </AlertModal>}

        </StepContent>
        <StepFooter rootStore={rootStore}/>
      </div>
    );
  }
});

export { Step4Search };
