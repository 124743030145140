import * as React from 'react';
import { observer } from 'mobx-react';
import { graphqlRequest } from "../graphql/init";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { IMappingTypeahead, IMappingTypeaheadPrediction } from "@gazelle/shared/mapping";
import { formatMessage } from "@gazelle/shared/utils";
import { MSG_noMatchingAddressesFoundLabel, MSG_searchingLabel, MSG_typeToSearchLabel } from "@gazelle/shared/strings";

interface IProps {
  addressString?: string,
  autoFocus?: boolean,
  onChange: (address: string) => any,
  onSelect?: (address: string) => any,
  onVerify?: (addressComponents: any) => any,
  onError?: (errorMessage: string) => any,
  typeaheadProvider: IMappingTypeahead
}

interface IState {
  options: IMappingTypeaheadPrediction[];
  isLoadingOptions: boolean;
  isGeocoding: boolean;
  hasError: boolean;
}

const AddressTypeahead = observer(
  class AddressTypeahead extends React.Component<IProps, IState> {
    private asyncTypeahead: any;

    constructor(props: IProps) {
      super(props);
      this.state = {options: [], isLoadingOptions: false, isGeocoding: false, hasError: false};
    }

    async componentDidMount() {
      // If we are auto-focusing and a search string is pre-populated here (like when
      // the component first loads), go ahead and trigger a search so that the option
      // list is populated.
      if (this.props.autoFocus && this.props.addressString) {
        void (await this.onSearch(this.props.addressString));
      }
    }

    onSearch = async (queryStr: string) => {
      this.setState({isLoadingOptions: true});

      try {
        let options = await this.props.typeaheadProvider.getPredictions(queryStr);
        this.setState({
          options: options,
          isLoadingOptions: false,
          hasError: false
        });
      } catch (e) {
        this.setState({options: [], isLoadingOptions: false, hasError: true});
      }
    };

    handleChange = (address: string) => {
      this.props.onChange(address);
    };

    onSelect = (options: IMappingTypeaheadPrediction[]) => {
      if (!options.length) return;
      this.props.onSelect(options[0].label);
      this.handleChange(options[0].label);

      void graphqlRequest(`
        mutation ($addressLine: String!) {
          addressComponents(addressLine: $addressLine) {
            mutationErrors {key messages}
            addressComponents { street1 street2 municipality region postalCode countryCode }
          }
          geocode(addressLine: $addressLine) {
            mutationErrors {key messages}
            geocodedAddress { lat lng locationType addressLine }
          }
        }
      `, {
        addressLine: options[0].label
      }).then((data: any) => {
        if (data.addressComponents.mutationErrors && data.addressComponents.mutationErrors.length > 0) {
          throw data.addressComponents.mutationErrors;
        }
        if (data.geocode.mutationErrors && data.geocode.mutationErrors.length > 0) {
          throw data.geocode.mutationErrors;
        }

        this.asyncTypeahead.blur();
        this.props.onVerify(data);
      }).catch((err: any) => {
        this.props.onError(err[0].messages[0]);
      });
    };

    render() {
      return (
        <AsyncTypeahead
          id="address-typeahead"
          ref={(ref: any) => this.asyncTypeahead = ref}
          defaultInputValue={this.props.addressString}
          onSearch={this.onSearch}
          onChange={this.onSelect}
          searchText={formatMessage(MSG_searchingLabel)}
          emptyLabel={formatMessage(MSG_noMatchingAddressesFoundLabel)}
          promptText={formatMessage(MSG_typeToSearchLabel)}
          autoFocus={this.props.autoFocus}
          options={this.state.options}
          disabled={this.state.isGeocoding}
          isLoading={this.state.isLoadingOptions}
          filterBy={() => true}
          renderMenuItemChildren={(option: IMappingTypeaheadPrediction) => {
            let parts: React.ReactElement[] = [];
            let str = option.label;
            let lastIndex = 0, key = 0;
            option.highlights.forEach(obj => {
              parts.push(<span key={key++}>{str.slice(lastIndex, obj.offset)}</span>);
              parts.push(<span key={key++} className="fw-bold">{str.slice(obj.offset, obj.offset + obj.length)}</span>);
              lastIndex = obj.offset + obj.length;
            });
            parts.push(<span key={key++}>{str.slice(lastIndex)}</span>);
            return <div>{parts}</div>;
          }}/>
      );
    }
  }
);

export { AddressTypeahead };
