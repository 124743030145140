import * as React from 'react';
import { Card } from 'reactstrap';

interface LocationCoordinatesMapProps {
  initialCenter: google.maps.LatLngLiteral;
  initialLocation?: google.maps.LatLngLiteral;
  zoom: number;
  onChange: (coordinates: google.maps.LatLngLiteral) => any;
  disabled?: boolean;
}

const CoordinatesSelectorMap: React.FC<LocationCoordinatesMapProps> = ({ initialCenter, initialLocation, zoom, onChange }) => {
  const mapRef = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();
  // BITROT: Convert Marker to AdvancedMarkerElement when we upgrade our @react-google-maps/api dependency
  const [marker, setMarker] = React.useState<google.maps.Marker>();

  React.useEffect(() => {
    if (mapRef.current && !map) {
      // Initialize the Google Map
      const newMap = new google.maps.Map(mapRef.current, {
        center: initialCenter,
        zoom: zoom,
        draggableCursor: 'pointer'  // Set cursor to pointer
      });
      setMap(newMap);
      if (initialLocation) {
        // Add a marker for the initial location
        // BITROT: Convert Marker to AdvancedMarkerElement when we upgrade our @react-google-maps/api dependency
        const newMarker = new google.maps.Marker({
          position: initialLocation,
          map: newMap,
        });
        // Set up marker removal on click
        newMarker.addListener('click', () => {
          newMarker.setMap(null);
          setMarker(undefined);
          onChange(undefined);
        });
        setMarker(newMarker);
      }
    }
  }, [mapRef, map, initialCenter, initialLocation, zoom]);

  const handleMapClick = (event: google.maps.MapMouseEvent) => {
    // Remove the previous marker, if it exists
    if (marker) {
      marker.setMap(null);
    }

    // Add a new marker
    // BITROT: Convert Marker to AdvancedMarkerElement when we upgrade our @react-google-maps/api dependency
    const newMarker = new google.maps.Marker({
      position: event.latLng,
      map: map,
    });

    // Set up marker removal on click
    newMarker.addListener('click', () => {
      newMarker.setMap(null);
      setMarker(undefined);
      onChange(undefined);
    });

    setMarker(newMarker);
    onChange(event.latLng.toJSON());
  };

  React.useEffect(() => {
    if (map) {
      // Add click listener to the map
      google.maps.event.addListener(map, 'click', handleMapClick);
    }
    return () => {
      if (map) {
        google.maps.event.clearListeners(map, 'click');
      }
    };
  }, [map, marker]);

  return <Card body className="p-0"><div ref={mapRef} style={{ width: '100%', height: '300px', cursor: 'pointer' }} /></Card>;
};

export { CoordinatesSelectorMap };
