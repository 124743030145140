import gql from "graphql-tag";
import { PrivateUserCoreInfoFragment } from "./user";

export const PrivateMasterServiceGroupFragment = gql`
  fragment PrivateMasterServiceGroupFragment on PrivateMasterServiceGroup {
    id name order isMultiChoice isArchived
  }
`;

export const PrivateMasterServiceItemFragment = gql`
  fragment PrivateMasterServiceItemFragment on PrivateMasterServiceItem {
    id name description educationDescription duration amount type externalUrl order
    isDefault isTaxable isSelfSchedulable isAnyUser isArchived isTuning
    masterServiceGroup { id name }
  }
`;

// ### QUERIES ############################################################################################

export const PrivateMasterServiceListQuery = gql`
  query {
    allMasterServiceGroups {
      ...PrivateMasterServiceGroupFragment
      allMasterServiceItems {
        ...PrivateMasterServiceItemFragment
        allUsers { ...PrivateUserCoreInfoFragment }
        allEstimateChecklistItems { id }
      }
    }
  }

  ${PrivateMasterServiceGroupFragment}
  ${PrivateMasterServiceItemFragment}
  ${PrivateUserCoreInfoFragment}
`;

// ### MUTATIONS ############################################################################################

export const PrivateDeleteMasterServiceItemMutation = gql`
  mutation ($id: String!) {
    deleteMasterServiceItem(id: $id) {
      isDeleted
      mutationErrors { errorReferenceId key type messages }
    }
  }
`;


export const PrivateUpdateMasterServiceItemMutation = gql`
  mutation ($id: String!, $input: PrivateMasterServiceItemInput!) {
    updateMasterServiceItem(id: $id, input: $input) {
      mutationErrors { errorReferenceId key type messages }
      masterServiceItem { ...PrivateMasterServiceItemFragment }
    }
  }

  ${PrivateMasterServiceItemFragment}
`;

export const PrivateCreateMasterServiceItemMutation = gql`
  mutation ($input: PrivateMasterServiceItemInput!) {
    createMasterServiceItem(input: $input) {
      mutationErrors { errorReferenceId key type messages }
      masterServiceItem { ...PrivateMasterServiceItemFragment }
    }
  }

  ${PrivateMasterServiceItemFragment}
`;

export const PrivateDeleteMasterServiceGroupMutation = gql`
  mutation ($id: String!) {
    deleteMasterServiceGroup(id: $id) {
      isDeleted
      mutationErrors { errorReferenceId key type messages }
    }
  }
`;

export const PrivateUpdateMasterServiceGroupMutation = gql`
  mutation ($id: String!, $input: PrivateMasterServiceGroupInput!) {
    updateMasterServiceGroup(id: $id, input: $input) {
      mutationErrors { errorReferenceId key type messages }
      masterServiceGroup { ...PrivateMasterServiceGroupFragment }
    }
  }

  ${PrivateMasterServiceGroupFragment}
`;

export const PrivateCreateMasterServiceGroupMutation = gql`
  mutation ($input: PrivateMasterServiceGroupInput!) {
    createMasterServiceGroup(input: $input) {
      mutationErrors { errorReferenceId key type messages }
      masterServiceGroup { ...PrivateMasterServiceGroupFragment }
    }
  }

  ${PrivateMasterServiceGroupFragment}
`;
