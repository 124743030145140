import gql from "graphql-tag";
import { PrivateContactEmailFullInfoFragment } from "./contact_email";
import { PrivateContactPhoneFullInfoFragment } from "./contact_phone";
import { PrivateContactLocationFullInfoFragment } from "./contact_location";

export const PrivateContactFullInfoFragment = gql`
  fragment PrivateContactFullInfoFragment on PrivateContact {
    firstName id isBillingDefault isDefault lastName middleName role suffix title
    wantsEmail wantsPhone wantsText createdAt updatedAt

    client { id }
    
    allLocations { nodes { ...PrivateContactLocationFullInfoFragment }}
    allEmails { nodes { ...PrivateContactEmailFullInfoFragment }}
    allPhones { nodes { ...PrivateContactPhoneFullInfoFragment }}

    defaultEmail { ...PrivateContactEmailFullInfoFragment }
    defaultPhone { ...PrivateContactPhoneFullInfoFragment }
    defaultConfirmedMobilePhone { ...PrivateContactPhoneFullInfoFragment }
  }
  ${PrivateContactLocationFullInfoFragment}
  ${PrivateContactEmailFullInfoFragment}
  ${PrivateContactPhoneFullInfoFragment}
`;


// ### QUERIES ###########################################################################################

export const PrivateContactFullDetailsQuery = gql`
  query ($id: String!) {
    contact(id: $id) {
      ...PrivateContactFullInfoFragment
    }
  }
  ${PrivateContactFullInfoFragment}
`;

// ### MUTATIONS ###########################################################################################

export const PrivateDeleteContactMutation = gql`
  mutation ($id: String!) {
    deleteContact(id: $id) {
      mutationErrors { fieldName key messages }
      isDeleted
    }
  }
`;

export const PrivateCreateContactMutation = gql`
  mutation ($clientId: String!, $input: PrivateContactInput!) {
    createContact(clientId: $clientId, input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      contact { ...PrivateContactFullInfoFragment }
    }
  }
  ${PrivateContactFullInfoFragment}
`;

export const PrivateUpdateContactMutation = gql`
  mutation ($id: String!, $input: PrivateContactInputWithoutNestedCollections!) {
    updateContact(id: $id, input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      contact { ...PrivateContactFullInfoFragment }
    }
  }
  ${PrivateContactFullInfoFragment}
`;
