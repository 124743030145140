import gql from "graphql-tag";
import { PrivateContactLocationFullInfoFragment } from "./contact_location";

export const PrivateScheduledMessageCoreInfoFragment = gql`
  fragment PrivateScheduledMessageCoreInfoFragment on PrivateScheduledMessage {
    id
    client { id }
    scheduledMessageTemplate { id }
    language subject template type sendAt
    createdAt
  }
`;

// ### QUERIES ############################################################################################

export const PrivateAllScheduledMessagesQuery = gql`
  query ($clientId: String $first: Int $after: String) {
    allScheduledMessages(clientId: $clientId, first: $first, after: $after) {
      totalCount
      pageInfo {
        hasNextPage hasPreviousPage startCursor endCursor
      }
      nodes {
        ...PrivateScheduledMessageCoreInfoFragment
      }
    }
  }

  ${PrivateScheduledMessageCoreInfoFragment}
`;

export const PrivateScheduledMessageQuery = gql`
  query ($id: String!) {
    scheduledMessage(id: $id) {
      id language subject template type sendAt
      client { id companyName
        defaultContact {
          id firstName lastName
          defaultEmail { id email }
          defaultLocation { ...PrivateContactLocationFullInfoFragment }
          defaultPhone { id phoneNumber extension }
          defaultConfirmedMobilePhone { id phoneNumber }
        }
      }
    }
  }
  ${PrivateContactLocationFullInfoFragment}
`;

// ### MUTATIONS ###########################################################################################

export const PrivateUpdateScheduledMessageMutation = gql`
  mutation ($input: PrivateScheduledMessageInput! $id: String!) {
    updateScheduledMessage(input: $input, id: $id) {
      mutationErrors { errorReferenceId key fieldName messages }
      scheduledMessage {
        ...PrivateScheduledMessageCoreInfoFragment
      }
    }
  }

  ${PrivateScheduledMessageCoreInfoFragment}
`;

export const PrivateCreateScheduledMessageMutation = gql`
  mutation ($input: PrivateScheduledMessageInput!) {
    createScheduledMessage(input: $input) {
      mutationErrors { errorReferenceId key fieldName messages }
      scheduledMessage {
        ...PrivateScheduledMessageCoreInfoFragment
      }
    }
  }

  ${PrivateScheduledMessageCoreInfoFragment}
`;

export const PrivateDeleteScheduledMessageMutation = gql`
  mutation ($id: String!) {
    deleteScheduledMessage(id: $id) {
      mutationErrors { errorReferenceId fieldName key messages }
      isDeleted
    }
  }
`;
