import gql from "graphql-tag";
import { CoreLocalizationDetailedInfoFragment } from "./localization";
import { PrivateContactLocationFullInfoFragment } from "./contact_location";

export const PrivatePianoPhotoFragment = gql`
  fragment PrivatePianoPhotoFragment on PrivatePianoPhoto {
    id createdAt notes isUsedByEstimates takenAt
    original { url size mimeType dimensions { height width }}
    thumbnail { url size mimeType dimensions { height width }}
  }
`;

export const PrivatePianoInfoFragment = gql`
  fragment PrivatePianoInfoFragment on PrivatePiano {
    id status type make model serialNumber year location tags
    calculatedNextService calculatedLastService serviceIntervalMonths lifecycleState damppChaserInstalled
    nextTuningScheduled { id start duration buffer timezone }
    primaryPianoPhoto { ...PrivatePianoPhotoFragment }
  }
  ${PrivatePianoPhotoFragment}
`;

export const PrivatePianoDetailsFragment = gql`
  fragment PrivatePianoDetailsFragment on PrivatePiano {
    id status type make model serialNumber location status serviceIntervalMonths manualLastService
    calculatedLastService calculatedNextService referenceId useType caseColor caseFinish searchString year tags
    playerInstalled playerMake playerModel playerSerialNumber damppChaserInstalled consignment rental rentalContractEndsOn
    size totalLoss needsRepairOrRebuilding lifecycleState nextServiceOverride eventLastService lifecycleContactPausedUntil
    damppChaserHumidistatModel damppChaserMfgDate hasIvory notes createdAt updatedAt potentialPerformanceLevel referenceId
    nextTuningScheduled { id start }
    primaryPianoPhoto { ...PrivatePianoPhotoFragment }
  }
  ${PrivatePianoPhotoFragment}
`;

export const PrivatePianoClientDetailsFragment = gql`
  fragment PrivatePianoClientDetailsFragment on PrivateClient {
    id companyName defaultClientLocalization { ...CoreLocalizationDetailedInfoFragment }
    defaultContact {
      id role title firstName middleName lastName suffix
      defaultLocation { ...PrivateContactLocationFullInfoFragment }
      defaultEmail { id email }
      defaultPhone { id phoneNumber extension type confirmedClass e164: phoneNumber(format: E164) }
    }
  }
  ${CoreLocalizationDetailedInfoFragment}
  ${PrivateContactLocationFullInfoFragment}
`;

// ### QUERIES ############################################################################################

export const PrivatePianosForClientQuery = gql`
  query ($filters: PrivateAllPianosFilter, $sortBy: [PianoSort!] $first: Int $after: String ) {
    allPianos(filters: $filters, sortBy: $sortBy, first: $first, after: $after) {
      totalCount
      pageInfo {
        hasNextPage hasPreviousPage startCursor endCursor
      }
      nodes {
        ...PrivatePianoInfoFragment
      }
    }
  }

  ${PrivatePianoInfoFragment}
`;

export const PrivatePianoSearchQuery = gql`
  query ($filters: PrivateAllPianosFilter, $sortBy: [PianoSort!] $first: Int $after: String ) {
    allPianos(filters: $filters, sortBy: $sortBy, first: $first, after: $after) {
      totalCount
      nodes {
        id status type make model serialNumber location damppChaserInstalled playerInstalled totalLoss year
        calculatedLastService calculatedNextService serviceIntervalMonths lifecycleState tags
        nextTuningScheduled { id start timezone }
        primaryPianoPhoto { ...PrivatePianoPhotoFragment }
        client { id companyName status defaultContact { id firstName lastName } }
      }
      pageInfo {
        hasNextPage hasPreviousPage startCursor endCursor
      }
    }
  }
  ${PrivatePianoPhotoFragment}
`;

export const PrivatePianoWithPhotoSearchQuery = gql`
  query ($filters: PrivateAllPianosFilter, $sortBy: [PianoSort!] $first: Int $after: String ) {
    allPianos(filters: $filters, sortBy: $sortBy, first: $first, after: $after) {
      totalCount
      nodes {
        id status type make model serialNumber location damppChaserInstalled playerInstalled totalLoss year calculatedLastService calculatedNextService lifecycleState
        nextTuningScheduled { id start timezone }
        primaryPianoPhoto { ...PrivatePianoPhotoFragment }
        client { id companyName defaultContact { id firstName lastName } }
      }
      pageInfo {
        hasNextPage hasPreviousPage startCursor endCursor
      }
    }
  }
  ${PrivatePianoPhotoFragment}
`;

export const PrivatePianoDetailsQuery = gql`
  query($id:String) {
    piano(id: $id) {
      ...PrivatePianoDetailsFragment
      allPianoMeasurements {
        id takenOn temperature humidity
        a0Dip a0Pitch a1Dip a1Pitch a2Dip a2Pitch a3Dip a3Pitch a4Dip a4Pitch a5Dip a5Pitch a6Dip a6Pitch a7Dip a7Pitch
        c7SustainPlayed c7SustainPlucked
        d6SustainPlayed d6SustainPlucked
        g6SustainPlayed g6SustainPlucked
      }
      allPianoPhotos(first: 12) {
        totalCount
        nodes { ...PrivatePianoPhotoFragment }
      }
      client {
        ...PrivatePianoClientDetailsFragment
      }
    }
  }

  ${PrivatePianoDetailsFragment}
  ${PrivatePianoClientDetailsFragment}
  ${PrivatePianoDetailsFragment}
`;

export const PrivatePianoPhotosQuery = gql`
  query ($id: String, $search:String, $sortBy: [PhotoSort!], $first:Int, $after:String) {
    piano (id: $id) {
      ...PrivatePianoDetailsFragment
      allPianoPhotos (sortBy: $sortBy, search: $search, first: $first, after: $after) {
        totalCount
        pageInfo {
          hasNextPage hasPreviousPage startCursor endCursor
        }
        nodes { ...PrivatePianoPhotoFragment }
      }
    }
  }

  ${PrivatePianoDetailsFragment}
`;

export const PrivatePianoCountQuery = gql`
  query ($filters: PrivateAllPianosFilter, $sortBy: [PianoSort!] $first: Int $after: String ) {
    allPianos(filters: $filters, sortBy: $sortBy, first: $first, after: $after) {
      totalCount
      pageInfo { hasNextPage }
      nodes { ...PrivatePianoInfoFragment }
    }
  }

  ${PrivatePianoInfoFragment}
`;

// ### MUTATIONS ############################################################################################

export const PrivateCreatePianoMutation = gql`
  mutation ($input: PrivatePianoInput!) {
    createPiano(input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      piano {
        ...PrivatePianoDetailsFragment
        client {
          ...PrivatePianoClientDetailsFragment
        }
      }
    }
  }

  ${PrivatePianoDetailsFragment}
  ${PrivatePianoClientDetailsFragment}
`;

export const PrivateUpdatePianoMutation = gql`
  mutation ($id: String!, $input: PrivatePianoInput!) {
    updatePiano(id: $id, input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      piano {
        ...PrivatePianoDetailsFragment
        client {
          ...PrivatePianoClientDetailsFragment
        }
      }
    }
  }
  ${PrivatePianoDetailsFragment}
  ${PrivatePianoClientDetailsFragment}
`;

export const PrivateDeletePianoMutation = gql`
  mutation ($id: String!) {
    deletePiano(id: $id) {
      mutationErrors { type fieldName key messages errorReferenceId }
      isDeleted
    }
  }
`;

export const PrivateCreatePianoPhotoMutation = gql`
  mutation ($input: PrivatePianoPhotoInput!) {
    createPianoPhoto(input: $input) {
      mutationErrors { type fieldName key messages }
      pianoPhoto { ...PrivatePianoPhotoFragment }
    }
  }
  ${PrivatePianoPhotoFragment}
`;

export const PrivateUpdatePianoPhotoMutation = gql`
  mutation ($id: String!, $input: PrivatePianoPhotoUpdateInput!) {
    updatePianoPhoto(id: $id, input: $input) {
      mutationErrors { type fieldName key messages }
      pianoPhoto { ...PrivatePianoPhotoFragment }
    }
  }
  ${PrivatePianoPhotoFragment}
`;

export const PrivateDeletePianoPhotoMutation = gql`
  mutation ($id: String!) {
    deletePianoPhoto(id: $id) {
      mutationErrors { fieldName key messages }
      isDeleted
    }
  }
`;
