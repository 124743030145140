import gql from "graphql-tag";
import { PrivateUserCoreInfoFragmentWithApiKeyFragment } from "./user";

export const PrivateAuthenticateMutation = gql`
  mutation ($email: String!, $password: String!, $pushToken: String) {
    authenticate(email: $email, password: $password, pushToken: $pushToken) {
      mutationErrors { fieldName key messages }
      user {
        ...PrivateUserCoreInfoFragmentWithApiKeyFragment
      }
    }
  }
  
  ${PrivateUserCoreInfoFragmentWithApiKeyFragment}
`;
