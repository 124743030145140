import gql from "graphql-tag";
import { PrivateEventCoreInfoFragment, PrivateEventPianoInfoFragment } from "./event";
import {
  PrivateClientActivePianosFragment,
  PrivateClientDefaultContactInfoFragment,
  PrivateClientInfoFragment
} from "./client";
import { PrivateInvoiceItemFragment } from "./invoice_item";

export const PrivateUncompletedAppointmentsQuery = gql`
  query PrivateUncompletedAppointmentsQuery ($first: Int, $after: String, $filters: PrivateAllUncompletedAppointmentsFilter) {
    allUncompletedAppointments(first: $first, after: $after, filters: $filters) {
      totalCount
      nodes {
        ...PrivateEventCoreInfoFragment
        ...PrivateEventPianoInfoFragment
        client {
          ...PrivateClientInfoFragment
          ...PrivateClientActivePianosFragment
          ...PrivateClientDefaultContactInfoFragment
          personalNotes preferenceNotes
          lastInvoice {
            dueOn total status notes allInvoiceItems { edges { node {
              ...PrivateInvoiceItemFragment
            }}}
          }
        }
      }
      pageInfo {
        hasNextPage hasPreviousPage startCursor endCursor
      }
    }
  }

  ${PrivateEventCoreInfoFragment}
  ${PrivateEventPianoInfoFragment}
  ${PrivateClientInfoFragment}
  ${PrivateClientActivePianosFragment}
  ${PrivateClientDefaultContactInfoFragment}
  ${PrivateInvoiceItemFragment}
`;
