import gql from "graphql-tag";

export const PrivateInvoiceItemFragment = gql`
  fragment PrivateInvoiceItemFragment on PrivateInvoiceItem {
    id description type billable amount quantity taxable subTotal taxTotal total sequenceNumber
    taxes {id taxId rate name total}
    piano {id make model serialNumber year location type}
    masterServiceItem {id}
  }
`;
