import gql from "graphql-tag";
import { CoreLocalizationDetailedInfoFragment } from "./localization";

export const PrivateUserCoreInfoFragment = gql`
  fragment PrivateUserCoreInfoFragment on PrivateUser {
    id status timezone color firstName lastName isSchedulable isTrafficEnabled sharedCalendarToken
    defaultBuffer
  }
`;

export const PrivateUserCoreInfoFragmentWithApiKeyFragment = gql`
  fragment PrivateUserCoreInfoFragmentWithApiKeyFragment on PrivateUserWithApiKey {
    apiKey
    id status timezone color firstName lastName isSchedulable isTrafficEnabled sharedCalendarToken
    defaultBuffer
  }
`;

export const PrivateUserCalendarSettingsFragment = gql`
  fragment PrivateUserCalendarSettingsFragment on PrivateUser {
    calendarMakeCompletedAndPastEventsDimmer
    calendarDefaultSendAppointmentConfirmation
    calendarDefaultViewType
    calendarDefaultTitleMode
    calendarFontSize
    calendarDefaultShowAvailability
    calendarDefaultShowConfirmationWarnings
    calendarShowNonschedulableUsers
    calendarDefaultUserId
    calendarShowDetailsOnIcsExport
    calendarIcsExportEventTypes
    calendarPromptToScheduleAfterCompletion
    calendarShowCanceledAppointments

  }
`;

export const PrivateUserSchedulerSettingsFragment = gql`
  fragment PrivateUserSchedulerSettingsFragment on PrivateUser {
    schedulerSettings {
      id
      longTermLimitDays defaultTravelMode
      shortTermLimitHours shortTermLimitHoursType shortTermLimitMessage
    }
  }
`;

export const PrivateUserDetailedInfoFragment = gql`
  fragment PrivateUserDetailedInfoFragment on PrivateUser {
    id status email firstName lastName email timezone accessLevel color region createdAt updatedAt intercomUserHash
    isSchedulable isTrafficEnabled hasLimitedAccess sharedCalendarToken defaultBuffer
    uiExpandedTimeline canViewCompanyMetrics
    genericReferralUrl
    localization { ...CoreLocalizationDetailedInfoFragment }
    defaultUserLocalization { ...CoreLocalizationDetailedInfoFragment }
    defaultClientLocalization { ...CoreLocalizationDetailedInfoFragment }
    ...PrivateUserSchedulerSettingsFragment
    ...PrivateUserCalendarSettingsFragment
    flags { makeMePreferredTechForNewClients hideDashboardReferralNotice }
  }
  ${CoreLocalizationDetailedInfoFragment}
  ${PrivateUserSchedulerSettingsFragment}
  ${PrivateUserCalendarSettingsFragment}
`;

export const PrivateUserMobileSettingsFragment = gql`
  fragment PrivateUserMobileSettingsFragment on PrivateUser {
    wantsReservationNotifications reservationNotificationsForAllUsers
    reservationNotificationsForSpecificUsers { nodes {
      id firstName lastName email
    } }
  }
`;

// ### QUERIES #############################################################################################

export const PrivateUserDetailsMobileQuery = gql`
  query {
    user { ...PrivateUserDetailedInfoFragment ...PrivateUserMobileSettingsFragment }
  }
  ${PrivateUserDetailedInfoFragment}
  ${PrivateUserMobileSettingsFragment}
`;

export const PrivateAllUsersDetailsQuery = gql`
  query { allUsers { ...PrivateUserDetailedInfoFragment } }
  ${PrivateUserDetailedInfoFragment}
`;

// ### MUTATIONS ###########################################################################################

export const PrivateUpdateUserMutation = gql`
  mutation ($input: PrivateUserInput! $id: String!) {
    saveUser: updateUser(input: $input id: $id) {
      mutationErrors { type fieldName key messages errorReferenceId }
      user {
        ...PrivateUserCoreInfoFragment
        ...PrivateUserCalendarSettingsFragment
        ...PrivateUserSchedulerSettingsFragment
        ...PrivateUserMobileSettingsFragment
      }
    }
  }
  ${PrivateUserCoreInfoFragment}
  ${PrivateUserCalendarSettingsFragment}
  ${PrivateUserMobileSettingsFragment}
  ${PrivateUserSchedulerSettingsFragment}
`;

export const PrivateUpdateUserSettingsMutation = gql`
  mutation ($input: PrivateUserSettingsInput!, $id: String!) {
    updateUserSettings(input: $input, id: $id) {
      mutationErrors { type fieldName key messages errorReferenceId }
      user { ...PrivateUserDetailedInfoFragment }
    }
  }
  ${PrivateUserDetailedInfoFragment}
`;

export const PrivateUpdateUserFlagsMutation = gql`
  mutation ($input: PrivateUserFlagsInput!) {
    updateUserFlags(input:$input) {
      flags {
        hideDashboardReferralNotice
        makeMePreferredTechForNewClients
      }
    }
  }
`;

export const PrivateToggleSharedCalendarMutation = gql`
  mutation ($userId: String!) {
    toggleSharedCalendar(userId: $userId) {
      mutationErrors { type fieldName key messages errorReferenceId }
      user {
      ...PrivateUserCoreInfoFragment
      }
    }
  }
  ${PrivateUserCoreInfoFragment}
`;
