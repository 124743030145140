import gql from "graphql-tag";
import { PrivateContactLocationFullInfoFragment } from "./contact_location";

export const PrivateSmsMessageDetailsQuery = gql`
  query ($id: String!) {
    smsMessage(id: $id) {
      id message status createdAt phoneNumber
      client { id companyName }
      contact {
        id firstName lastName
        defaultLocation { ...PrivateContactLocationFullInfoFragment }
        defaultPhone { id phoneNumber extension }
      }
    }
  }
  ${PrivateContactLocationFullInfoFragment}
`;
