import gql from "graphql-tag";

export const PrivateRecommendationCoreInfoFragment = gql`
  fragment PrivateRecommendationCoreInfoFragment on PrivateRecommendation {
    id type name explanation dependentEstimateTierCount isArchived
  }
`;

// ### QUERIES ############################################################################################

export const PrivateAllRecommendationsQuery = gql`
  query($includeArchived: Boolean) {
    allRecommendations(includeArchived: $includeArchived) {
      ...PrivateRecommendationCoreInfoFragment
    }
  }
  ${PrivateRecommendationCoreInfoFragment}
`;


// ### MUTATIONS ############################################################################################
export const PrivateCreateRecommendationMutation = gql`
  mutation ($input: PrivateRecommendationInput!) {
    createRecommendation(input: $input) {
      mutationErrors { fieldName key messages type errorReferenceId }
      recommendation {
        ...PrivateRecommendationCoreInfoFragment
      }
    }
  }
  ${PrivateRecommendationCoreInfoFragment}
`;

export const PrivateUpdateRecommendationMutation = gql`
  mutation ($id: String!, $input: PrivateRecommendationInput!) {
    updateRecommendation(id: $id, input: $input) {
      mutationErrors { fieldName key messages type errorReferenceId }
      recommendation {
        ...PrivateRecommendationCoreInfoFragment
      }
    }
  }
  ${PrivateRecommendationCoreInfoFragment}
`;

export const PrivateDeleteRecommendationMutation = gql`
  mutation ($id: String!) {
    deleteRecommendation(id: $id) {
      mutationErrors { fieldName key messages type errorReferenceId }
      isDeleted
    }
  }
`;
