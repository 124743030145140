import { computed, makeObservable, observable } from 'mobx';
import { CONTACT_LOCATION_ALL_GQL_QUERY_FIELDS, ContactLocation } from './contact_location';
import { CONTACT_EMAIL_ALL_GQL_QUERY_FIELDS, ContactEmail } from './contact_email';
import { CONTACT_PHONE_ALL_GQL_QUERY_FIELDS, ContactPhone } from './contact_phone';
import { LOCATION_USAGE_TYPE } from 'shared/enums';

class Contact {
  id: string = null;
  isDefault: boolean = null;
  isBillingDefault: boolean = null;
  wantsEmail: boolean = null;
  wantsPhone: boolean = null;
  wantsText: boolean = null;
  firstName: string = null;
  middleName: string = null;
  lastName: string = null;
  suffix: string = null;
  title: string = null;
  role: string = null;
  contactLocations: ContactLocation[] = null;
  hasMoreLocations: boolean = null;
  contactEmails: ContactEmail[] = null;
  hasMoreEmails: boolean = null;
  contactPhones: ContactPhone[] = null;
  hasMorePhones: boolean = null;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.isDefault = attrs.isDefault;
    this.isBillingDefault = attrs.isBillingDefault;
    this.wantsEmail = attrs.wantsEmail;
    this.wantsPhone = attrs.wantsPhone;
    this.wantsText = attrs.wantsText;
    this.firstName = attrs.firstName;
    this.middleName = attrs.middleName;
    this.lastName = attrs.lastName;
    this.suffix = attrs.suffix;
    this.title = attrs.title;
    this.role = attrs.role;

    if (attrs.allLocations) {
      this.hasMoreLocations = false;
      this.contactLocations = attrs.allLocations.nodes.map(
        (node: any) => new ContactLocation(node)
      );
    }
    if (attrs.allPhones) {
      this.hasMorePhones = false;
      this.contactPhones = attrs.allPhones.nodes.map(
        (node: any) => new ContactPhone(node)
      );
    }
    if (attrs.allEmails) {
      this.hasMoreEmails = false;
      this.contactEmails = attrs.allEmails.nodes.map(
        (node: any) => new ContactEmail(node)
      );
    }

    makeObservable(this, {
      id: observable,
      isDefault: observable,
      isBillingDefault: observable,
      wantsEmail: observable,
      wantsPhone: observable,
      wantsText: observable,
      firstName: observable,
      middleName: observable,
      lastName: observable,
      suffix: observable,
      title: observable,
      role: observable,
      contactLocations: observable,
      hasMoreLocations: observable,
      contactEmails: observable,
      hasMoreEmails: observable,
      contactPhones: observable,
      hasMorePhones: observable,
      formalName: computed,
      fullName: computed,
      streetLocation: computed,
      mailingLocation: computed,
      billingLocation: computed,
      defaultPhone: computed,
      defaultEmail: computed
    });
  }

  get formalName() {
    let parts = [];
    if (this.title) parts.push(this.title);
    if (this.firstName) parts.push(this.firstName);
    if (this.lastName) parts.push(this.lastName);
    if (this.suffix) parts.push(this.suffix);
    return parts.join(' ');
  }

  get fullName() {
    let parts = [];
    if (this.firstName) parts.push(this.firstName);
    if (this.lastName) parts.push(this.lastName);
    return parts.join(' ');
  }

  get streetLocation(): ContactLocation {
    for (let loc of this.contactLocations) {
      if (loc.usageType === LOCATION_USAGE_TYPE.STREET) return loc;
    }
    for (let loc of this.contactLocations) {
      if (loc.usageType === LOCATION_USAGE_TYPE.MAILING) return loc;
    }
    return this.contactLocations[0];
  }

  get mailingLocation(): ContactLocation {
    for (let loc of this.contactLocations) {
      if (loc.usageType === LOCATION_USAGE_TYPE.MAILING) return loc;
    }
    for (let loc of this.contactLocations) {
      if (loc.usageType === LOCATION_USAGE_TYPE.STREET) return loc;
    }
    return this.contactLocations[0];
  }

  get billingLocation(): ContactLocation {
    for (let loc of this.contactLocations) {
      if (loc.usageType === LOCATION_USAGE_TYPE.BILLING) return loc;
    }
    for (let loc of this.contactLocations) {
      if (loc.usageType === LOCATION_USAGE_TYPE.MAILING) return loc;
    }
    return this.contactLocations[0];
  }

  get defaultPhone(): ContactPhone {
    for (let phone of this.contactPhones) {
      if (phone.isDefault) return phone;
    }
    return null;
  }

  get defaultEmail(): ContactEmail {
    for (let email of this.contactEmails) {
      if (email.isDefault) return email;
    }
    return null;
  }
}

const CONTACT_ALL_GQL_QUERY_FIELDS = `
  id isDefault isBillingDefault
  wantsEmail wantsPhone wantsText
  firstName middleName lastName title suffix role
  defaultBillingLocation { ${CONTACT_LOCATION_ALL_GQL_QUERY_FIELDS} }
  defaultLocation { ${CONTACT_LOCATION_ALL_GQL_QUERY_FIELDS} }
  defaultEmail { ${CONTACT_EMAIL_ALL_GQL_QUERY_FIELDS} }
  defaultPhone { ${CONTACT_PHONE_ALL_GQL_QUERY_FIELDS} }
  allLocations { nodes { ${CONTACT_LOCATION_ALL_GQL_QUERY_FIELDS} }}
  allEmails { nodes { ${CONTACT_EMAIL_ALL_GQL_QUERY_FIELDS} }}
  allPhones { nodes { ${CONTACT_PHONE_ALL_GQL_QUERY_FIELDS} }}
`;

export { Contact, CONTACT_ALL_GQL_QUERY_FIELDS };
