import { EVENT_STATUS } from "../enums";
import { formatMessage } from "./intl";
import {
  MSG_eventStatusActive,
  MSG_eventStatusCanceled,
  MSG_eventStatusCompleted,
  MSG_eventStatusNoShow,
} from "../strings";

// Define a sorted array of statuses for consistent iteration
const SortedEventStatusTypes = [
  EVENT_STATUS.ACTIVE,
  EVENT_STATUS.COMPLETE,
  EVENT_STATUS.NO_SHOW,
  EVENT_STATUS.CANCELED,
];


// Define a structure for event status information
const EventStatusTypeInfo: { [key: string]: { type: EVENT_STATUS, getLabel: () => string, color: string, className?: string } } = {
    [EVENT_STATUS.ACTIVE]: {
      type: EVENT_STATUS.ACTIVE,
      getLabel: () => formatMessage(MSG_eventStatusActive),
      color: "success",
      className: "event-active",
    },
    [EVENT_STATUS.CANCELED]: {
      type: EVENT_STATUS.CANCELED,
      getLabel: () => formatMessage(MSG_eventStatusCanceled),
      color: "danger",
      className: "event-cancelled",
    },
    [EVENT_STATUS.NO_SHOW]: {
      type: EVENT_STATUS.NO_SHOW,
      getLabel: () => formatMessage(MSG_eventStatusNoShow),
      color: "danger",
      className: "event-no-show",
    },
    [EVENT_STATUS.COMPLETE]: {
      type: EVENT_STATUS.COMPLETE,
      getLabel: () => formatMessage(MSG_eventStatusCompleted),
      color: "secondary",
      className: "event-completed",
    },
  };
  

export { SortedEventStatusTypes, EventStatusTypeInfo };
