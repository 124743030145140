import gql from "graphql-tag";
import { PrivateUserCoreInfoFragment } from "./user";
import {
  PrivateClientDefaultContactInfoFragment,
  PrivateClientFullInfoFragment,
  PrivateClientInfoFragment
} from "./client";
import { PrivatePianoInfoFragment } from "./piano";
import { PrivateEventCoreInfoFragment } from "./event";

export const PrivateEventReservationsCoreInfoFragment = gql`
  fragment PrivateEventReservationsCoreInfoFragment on PrivateEventReservation {
    id startsAt timezone addressLine duration buffer status notes createdAt
    clientData {
      locationType street1 street2 municipality region postalCode countryCode
      latitude longitude
      firstName lastName email phoneNumber phoneType
      client {
        ...PrivateClientInfoFragment
        ...PrivateClientDefaultContactInfoFragment
      }
      emailSubscription { tags type emailAddress createdAt error }
    }
    user { ...PrivateUserCoreInfoFragment }
    schedulerV2SearchId travelMode
  }
  ${PrivateClientInfoFragment}
  ${PrivateClientDefaultContactInfoFragment}
  ${PrivateUserCoreInfoFragment}
`;

export const PrivateEventReservationDetailedInfoFragment = gql`
  fragment PrivateEventReservationDetailedInfoFragment on PrivateEventReservation {
    ...PrivateEventReservationsCoreInfoFragment
    pianoServices {
      isTuning type make model location pianoId
      services { name amount duration }
      piano { ...PrivatePianoInfoFragment }
    }
  }

  ${PrivatePianoInfoFragment}
  ${PrivateEventReservationsCoreInfoFragment}
`;

// *********** QUERIES ***************

export const PrivateEventReservationsQuery = gql`
  query PrivateEventReservationsQuery($filters: PrivateAllEventReservationsFilter, $first: Int, $after: String, $sortBy: [EventReservationSort!]) {
    allEventReservationsBatched(filters: $filters, first: $first, after: $after, sortBy: $sortBy) {
      totalCount
      nodes {
        ...PrivateEventReservationsCoreInfoFragment
      }
      pageInfo {
        startCursor endCursor hasNextPage hasPreviousPage
      }
    }
  }

  ${PrivateEventReservationsCoreInfoFragment}
`;

export const PrivateFutureEventReservationsForClient = gql`
  query PrivateEventReservationsQuery($filters: PrivateAllEventReservationsFilter, $first: Int, $after: String) {
    allEventReservationsBatched(filters: $filters, first: $first, after: $after) {
      nodes { id startsAt timezone }
    }
  }
`;

// Similar to PrivateEventReservationsCoreInfoFragment, but we want to return ALL the client data so that
// we can do conflict resolution with contacts, addresses, phones, etc.
// Note to future self:
// We don't want to use PrivateClientFullInfoFragment in the allEventReservationsBatched query because it runs a
// number of queries server side that just aren't necessary and since allEventReservationsBatched is pulled as part
// of the Today screen (on mobile), it'll slow down that screen and will be called frequently.
export const PrivateEventReservationQuery = gql`
  query PrivateEventReservationQuery($id: String!) {
    eventReservation(id: $id) {
      id startsAt timezone addressLine duration buffer status notes createdAt
      statusChangedBy { ...PrivateUserCoreInfoFragment }
      statusChangedAt changes
      client { ...PrivateClientFullInfoFragment }
      clientData {
        locationType street1 street2 municipality region postalCode countryCode
        latitude longitude smsOptIn
        firstName lastName email phoneNumberE164 phoneNumber phoneType
        client { ...PrivateClientFullInfoFragment }
        emailSubscription { tags type emailAddress createdAt error }
      }
      user { ...PrivateUserCoreInfoFragment }
      pianoServices {
        isTuning type make model location pianoId
        services { name amount duration }
        piano { ...PrivatePianoInfoFragment }
      }
      estimateTier {id}
      event { ...PrivateEventCoreInfoFragment }
      schedulerV2SearchId
    }
  }
  ${PrivateUserCoreInfoFragment}
  ${PrivateEventCoreInfoFragment}
  ${PrivateClientFullInfoFragment}
  ${PrivatePianoInfoFragment}
`;

// *********** MUTATIONS ***************

export const PrivateApproveReservationMutation = gql`
  mutation ($input: PrivateApproveEventReservationInput!, $id: String!) {
    approveEventReservation(id: $id, input: $input) {
      mutationErrors { fieldName key messages }
      client { id }
      event { id }
      badgeCount { pendingReservations uncompletedAppointments eventCancelNotices }
    }
  }
`;

export const PrivateDeclineReservationMutation = gql`
  mutation ($input: PrivateDeclineEventReservationInput!, $id: String!) {
    declineEventReservation(id: $id, input: $input) {
      mutationErrors { fieldName key messages }
      client { id }
      badgeCount { pendingReservations uncompletedAppointments eventCancelNotices }
    }
  }
`;
