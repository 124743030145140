import gql from "graphql-tag";
import { PrivateInvoiceItemFragment } from "./invoice_item";
import { PrivateClientDefaultContactNameFragment } from "./client";
import { PrivateContactLocationFullInfoFragment } from "./contact_location";

export const PrivateInvoiceCoreInfoFragment = gql`
  fragment PrivateInvoiceCoreInfoFragment on PrivateInvoice {
    id status number dueOn currency {code label symbol divisor decimalDigits}
    total subTotal tipTotal taxTotal paidTotal dueTotal
    netDays summarize archived notesHeader notes topNotesHeader topNotes acceptElectronicPayment clientUrl
    acceptedElectronicPaymentMethods hasPaymentPending tags
    lastQuickbooksSyncAt needsQuickbooksSync hasQuickbooksSyncNotices
  }
`;
export const PrivateInvoiceTotalsFragment = gql`
  fragment PrivateInvoiceTotalsFragment on PrivateInvoice {
    total dueTotal subTotal taxTotal paidTotal
  }
`;
export const PrivateInvoiceClientInfoFragment = gql`
  fragment PrivateInvoiceClientInfoFragment on PrivateInvoice {
    client {
      id status companyName
      defaultContact { id firstName lastName }
    }
    contact {
      id title firstName lastName
      allLocations { nodes { ...PrivateContactLocationFullInfoFragment } }
      defaultPhone {
        id phoneNumber extension
      }
    }
  }
  ${PrivateContactLocationFullInfoFragment}
`;
export const PrivateInvoiceAltBillingFragment = gql`
  fragment PrivateInvoiceAltBillingFragment on PrivateInvoice {
    altBillingCompanyName altBillingFirstName altBillingLastName
    altBillingStreet1 altBillingStreet2 altBillingMunicipality altBillingRegion altBillingPostalCode altBillingCountryCode
    altBillingPhone altBillingEmail
  }
`;

export const PrivateInvoiceFragment = gql`
  fragment PrivateInvoiceFragment on PrivateInvoice {
    ...PrivateInvoiceCoreInfoFragment
    ...PrivateInvoiceAltBillingFragment
    ...PrivateInvoiceTotalsFragment

    quickbooksSyncNotices { type description }
    client { id companyName ...PrivateClientDefaultContactNameFragment }
    contact {
      id title firstName lastName
      allLocations { nodes { ...PrivateContactLocationFullInfoFragment } }
      defaultPhone {
        id phoneNumber extension
      }
    }
    createdBy { id firstName lastName }
    allInvoiceItems { edges { node {
      ...PrivateInvoiceItemFragment
    }}}
  }
  ${PrivateInvoiceCoreInfoFragment}
  ${PrivateInvoiceAltBillingFragment}
  ${PrivateInvoiceTotalsFragment}
  ${PrivateInvoiceItemFragment}
  ${PrivateClientDefaultContactNameFragment}
  ${PrivateContactLocationFullInfoFragment}
`;

export const PrivateInvoicePaymentFragment = gql`
  fragment PrivateInvoicePaymentFragment on PrivateInvoicePayment {
    id type status failureReason amount currency { code symbol label divisor decimalDigits } notes tipTotal paidAt paymentDetails isStripePayment isSyncedToQuickbooksOnline paymentSource
  }
`;

// ### QUERIES ############################################################################################


export const PrivateInvoiceQuery = gql`
  query ($id: String!) {
    invoice(id: $id) {
      ...PrivateInvoiceFragment
      allInvoicePayments {
        nodes {
          ...PrivateInvoicePaymentFragment
        }
      }
    }
  }
  ${PrivateInvoiceFragment}
  ${PrivateInvoicePaymentFragment}
`;

export const PrivateInvoiceSearchQuery = gql`
  query ($filters: PrivateAllInvoicesFilter, $sortBy: [InvoiceSort!], $first: Int, $after: String) {
    allInvoices(filters: $filters, sortBy: $sortBy, first: $first, after: $after) {
      nodes {
        ...PrivateInvoiceCoreInfoFragment
        ...PrivateInvoiceClientInfoFragment
        altBillingFirstName altBillingLastName altBillingCompanyName createdBy { id firstName lastName }
      }
      totalCount
      pageInfo {
        startCursor endCursor hasNextPage hasPreviousPage
      }
    }
  }
  ${PrivateInvoiceCoreInfoFragment}
  ${PrivateInvoiceClientInfoFragment}
`;

export const PrivateInvoicePaymentQuery = gql`
  query ($id: String!) {
    invoicePayment(id: $id) {
      ...PrivateInvoicePaymentFragment
    }
  }
  ${PrivateInvoicePaymentFragment}
`;

// ### MUTATIONS ############################################################################################
export const PrivateCreateInvoiceMutation = gql`
  mutation ($input: PrivateCreateInvoiceInput!, $id: String) {
    createInvoice(input: $input, id: $id) {
      mutationErrors { type fieldName key messages errorReferenceId }
      invoice {
        ...PrivateInvoiceFragment
      }
    }
  }
  ${PrivateInvoiceFragment}
`;

export const PrivateUpdateInvoiceMutation = gql`
  mutation ($id: String!, $input: PrivateUpdateInvoiceInput!) {
    updateInvoice(id: $id, input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      invoice {
        ...PrivateInvoiceFragment
      }
    }
  }
  ${PrivateInvoiceFragment}
`;

export const PrivateSendInvoiceEmailMutation = gql`
  mutation ($invoiceId: String!, $sendToAltBilling: Boolean!, $sendToContactEmailIds: [String!], $attachPdf: Boolean!, $newInvoiceStatus: InvoiceStatus) {
    sendInvoiceEmail(invoiceId: $invoiceId, sendToAltBilling: $sendToAltBilling, sendToContactEmailIds: $sendToContactEmailIds, attachPdf: $attachPdf, newInvoiceStatus: $newInvoiceStatus) {
      mutationErrors { fieldName key messages }
      success message
    }
  }
`;

export const PrivateAddInvoicePayment = gql`
  mutation ($invoiceId: String!, $paymentAmount: Int!, $paymentType: InvoicePaymentType!, $paymentNotes: String, $paymentTipAmount: Int, $paymentPaidAt: CoreDateTime) {
    addInvoicePayment(invoiceId: $invoiceId, paymentAmount: $paymentAmount, paymentType: $paymentType, paymentNotes: $paymentNotes, paymentTipAmount: $paymentTipAmount, paymentPaidAt: $paymentPaidAt) {
      mutationErrors { fieldName key messages }
      invoice {
        ...PrivateInvoiceFragment
      }
    }
  }
  ${PrivateInvoiceFragment}
`;

export const PrivateUpdateInvoicePayment = gql`
  mutation($id: String!, $input: PrivateInvoicePaymentInput!) {
    updateInvoicePayment(id: $id, input: $input) {
      mutationErrors { fieldName key messages }
      invoicePayment { ...PrivateInvoicePaymentFragment }
    }
  }
  ${PrivateInvoicePaymentFragment}
`;

export const PrivateDeleteInvoicePayment = gql`
  mutation($invoiceId: String!, $paymentId: String!) {
    deleteInvoicePayment(invoiceId: $invoiceId, paymentId: $paymentId) {
      mutationErrors { fieldName key messages }
      isDeleted
    }
  }
`;

export const PrivateCreateStripeCheckoutSession = gql`
  mutation ($invoiceId: String!, $totalPaymentAmount: Int!, $tipAmount: Int, $notes: String, $receiptEmail: String, $receiptContactEmailId: String, $receiptAltBilling: Boolean, $stripeRedirectUrl: String) {
    createStripeCheckoutSession(invoiceId: $invoiceId, totalPaymentAmount: $totalPaymentAmount, receiptContactEmailId: $receiptContactEmailId, receiptAltBilling: $receiptAltBilling, receiptEmail: $receiptEmail, tipAmount: $tipAmount, notes: $notes, stripeRedirectUrl: $stripeRedirectUrl) {
      mutationErrors { type fieldName key messages errorReferenceId }
      checkoutId
    }
  }
`;
