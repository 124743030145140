export const MSG_billingTitle = {
  id: "289b44d4-662d-44a7-852b-75efb2183032",
  description: "The settings tab where a user can access their billing information.  This is where they can enter a credit card, change their payment methods, and select their subscription level.",
  defaultMessage: "Billing",
};

export const MSG_goToBillingTitle = {
  id: "ddeda145-aca9-4589-97e2-3188e7790596",
  description: "The title for a button that takes them to the Billing section of their settings.",
  defaultMessage: "Go To Billing"
};

export const MSG_billingNotOnMobileExplanation = {
  id: "28e481c8-5697-49b3-826e-a7fac2b6765a",
  description: "",
  defaultMessage: "Billing changes can be made by logging in to the Gazelle website."
};


export const MSG_approachingBillingLimits = {
  id: "4b721e15-f830-4c72-8540-e3bb083ce868",
  defaultMessage: "You currently have {activePianoCount} active pianos and you are approaching your billing limit of {activePianoLimit}.",
};

export const MSG_allRemindersArePaused = {
  id: "1a5a314b-07ed-45fd-9212-585543fea651",
  defaultMessage: "Your automated reminders have been universally paused at your request.  Please contact us when you're ready to re-enable normal operations.",
};

export const MSG_smartRoutesUpgrade = {
  id: "ebdda919-ae14-4b26-8b86-c29d4a0059ed",
  description: "This is the heading on the blurb where we offer for them to upgrade to include our Smart Routes feature.",
  defaultMessage: "Upgrade for Smart Routes",
};

export const MSG_smartRoutesDisabled = {
  id: "ee1b79dd-0b26-412c-9a44-a66611cd8707",
  description: "This is the title for the screen where we ask them to enable our Smart Routes feature in order to use the calendar search.",
  defaultMessage: "Smart Routes Disabled",
};

export const MSG_smartRoutesUpsellExplanation = {
  id: "720feb13-f7b7-41ef-a1de-e9d4380afeb1",
  defaultMessage: "When you enable Smart Routes on your account, Gazelle will search every opening on your calendar and offer the best time slots for an appointment.",
};

export const MSG_smartRoutesBenefit1 = {
  id: "9909dc33-2b82-4993-9e4b-eb2e845680b7",
  description: "This is one item in a bullet list that describes the benefits of upgrading to our Smart Routes feature.",
  defaultMessage: "Minimize your drive times",
};

export const MSG_smartRoutesBenefit2 = {
  id: "e05f49b8-03f5-471a-839d-41cc6c5d5290",
  description: "This is one item in a bullet list that describes the benefits of upgrading to our Smart Routes feature.",
  defaultMessage: "Quickly find openings while on the phone with a client",
};

export const MSG_smartRoutesBenefit3 = {
  id: "324ba217-c617-43df-bf60-9c8d9ea3587f",
  description: "This is one item in a bullet list that describes the benefits of upgrading to our Smart Routes feature.",
  defaultMessage: "Let clients schedule their own appointments online",
};

export const MSG_smartRoutesBenefit4 = {
  id: "b4462dac-e48d-4145-ac9a-678dbafa80e6",
  description: "This is one item in a bullet list that describes the benefits of upgrading to our Smart Routes feature.",
  defaultMessage: "Accounts for local traffic",
};

export const MSG_smartRoutesBenefit5 = {
  id: "8b5dcb36-1850-40a2-9c7f-f5631c30ae8f",
  description: "This is one item in a bullet list that describes the benefits of upgrading to our Smart Routes feature.",
  defaultMessage: "Prevents you from bouncing around your service area",
};

export const MSG_smartRoutesOptimizeYourDay = {
  id: "3ae307ff-a774-4ab2-888b-0f442d56bac0",
  defaultMessage: "Optimize your day!",
};

export const MSG_smartRoutesAdd = {
  id: "70d75abe-15b1-4df3-a126-461f39db1d53",
  defaultMessage: "Add Smart Routes to your Gazelle subscription to enable this feature.",
};

export const MSG_smartRoutesWhy = {
  id: "5fac5f80-dbdc-4fe8-9131-8dbc0a54fe82",
  defaultMessage: "Why is Smart Routes an optional upgrade?  We have enterprise license agreements with map providers around the world.  These license fees are necessary to ensure the most accurate maps and best traffic predictions in your area."
};

export const MSG_remindersPaused = {
  id: "727baea1-3f15-40ad-afa9-aed958ab00a1",
  defaultMessage: "Your automated reminders have been temporarily paused.",
};

export const MSG_loadBillingSettingsTitle = {
  id: "77912e5c-b249-4b6c-bb47-b04b3a191155",
  defaultMessage: "Load Billing Settings",
};

export const MSG_parkedManually = {
  id: "a3b75a9a-8260-4d62-bc45-69bf91675ec3",
  defaultMessage: "Your account has been temporarily disabled.  Please contact support@gazelleapp.io to re-enable your account.",
};

export const MSG_parkedManuallyExplanation1 = {
  id: "08887587-445b-4fd1-bc5a-d248f980bef8",
  defaultMessage: "This is likely due to you requesting that we temporarily disable your account and pause all your reminders, but keep all your data.",
};

export const MSG_parkedManuallyExplanation2 = {
  id: "1aaa103d-2c66-4a27-9824-e3ad1e640e79",
  defaultMessage: "Contact us when you're ready to re-activate your account.",
};

export const MSG_parkedCardExpired = {
  id: "d97dfc22-fecb-4f23-9f5e-d3054944970a",
  defaultMessage: "Your card has expired and your last invoice remains unpaid.",
};

export const MSG_parkedNoCard = {
  id: "18a16887-7557-489b-8c41-7f090fb43e03",
  defaultMessage: "We have payment source on file for you and your last invoice remains unpaid.",
};

export const MSG_noSubscription = {
  id: "57745ae1-f199-499a-a483-364750ab9464",
  description: "",
  defaultMessage: "You do not have an active subscription."
};

export const MSG_parkedUnableToCharge = {
  id: "e2c9a4a4-64e6-4c35-ab24-13ca72b596dc",
  defaultMessage: "We were unable to charge your payment method to pay your last invoice.",
};

export const MSG_parkedUnpaid = {
  id: "dd21f79c-6d26-4ed1-895b-cb829f73153d",
  defaultMessage: "Your last invoice remains unpaid.",
};

export const MSG_parkedOverPianoLimit = {
  id: "ea803678-c518-4bd6-9310-e9a82f6bc75e",
  defaultMessage: "You have exceeded your subscription plan's limit of active pianos.",
};

export const MSG_parkedUnknown = {
  id: "fb52162c-9d68-4b06-8f90-b21e6f073844",
  defaultMessage: "Your account has been temporarily disabled.",
};

export const MSG_visitBillingSettings = {
  id: "eef496a7-4a04-4dcd-92c1-0ef43f2e7d91",
  defaultMessage: "Visit Billing Settings",
};

export const MSG_currentSubscriptionPlanTitle = {
  id: "f5c37dab-c667-487c-b72a-1ba18eb470da",
  description: "",
  defaultMessage: "Current Subscription Plan"
};

export const MSG_newSubscriptionPlanTitle = {
  id: "110ddd5c-3880-471a-b2b1-48531faf8819",
  description: "",
  defaultMessage: "Your New Subscription Plan"
};

export const MSG_selectASubscriptionPlan = {
  id: "fec4822d-7dc0-4bb5-a59f-a0a630ffa24e",
  description: "",
  defaultMessage: "Select A Subscription Plan"
};

export const MSG_pleaseSelectASubscriptionPlan = {
  id: "a4963577-4da7-4f0c-b2b8-73ce1d84aa03",
  description: "",
  defaultMessage: "Please select one of our subscription plans to get started using Gazelle."
};

export const MSG_noPaymentMethodForBillingTitle = {
  id: "943eae1c-acec-4ba5-bbbc-4ecf3289a1fa",
  description: "This is the message that is displayed if the customer does not have a payment method on file (e.g. a credit card or a bank account)",
  defaultMessage: "No payment method is on file."
};

export const MSG_noPaymentMethodForBillingExplanation = {
  id: "51a8dece-c92f-4196-8099-eed954d58742",
  description: "",
  defaultMessage: "Please enter a payment method so your next invoice can be billed without interruption."
};

export const MSG_enterPaymentMethodBeforeChangingPlan = {
  id: "f2541565-e2fb-4304-a4d2-0861c86b23df",
  description: "",
  defaultMessage: "We do not have a payment method on file for your company.  Please enter a payment method before selecting a plan."
};

export const MSG_unpaidOverdueSubscriptionMessage = {
  id: "801f8e83-7632-42c9-9954-91852c96d48d",
  description: "",
  defaultMessage: "We were not able to collect payment for your subscription.  Please update your payment method, or contact us at support@gazelleapp.io if you are having problems."
};

export const MSG_perMonthLabel = {
  id: "2bbc8ef6-ce77-46f5-891b-69aa9b2bfbf8",
  description: "",
  defaultMessage: "per month"
};

export const MSG_perYearLabel = {
  id: "e649d0f4-857e-4157-858c-1997ab792a7c",
  description: "",
  defaultMessage: "per year"
};

export const MSG_pianoUsageOfTotal = {
  id: "74aedf07-e080-46f1-8f65-634280f11de6",
  description: "This gives them a text description of their billing limits based on the number of active pianos they have.  It will read like:  115 of 150 active pianos",
  defaultMessage: "{activePianos} of {activePianoLimit} active pianos"
};

export const MSG_featuresOfThisPlan = {
  id: "e16f69ba-12da-44e1-bfe1-bd1eec84b735",
  description: "A heading above a list of features that are enabled for their current subscription plan.",
  defaultMessage: "Features of this plan"
};

export const MSG_couponLabel = {
  id: "edff3a88-659e-4914-b3e4-93431b078384",
  description: "This is a label for a coupon (or discount) that will be applied to their subscription",
  defaultMessage: "Coupon:"
};

export const MSG_couponDiscountDescription = {
  id: "0636f830-3850-49c0-ae6e-b5b25f8bb9f6",
  description: "This will be read like '50% off' or '$100 off' as a description of their coupon.",
  defaultMessage: "You have a {amount} off coupon."
};

export const MSG_couponExpiresLabel = {
  id: "f1064f7c-9aa9-49e5-a58d-51bfd61b3e7f",
  description: "The date that a coupon (or discount) expires",
  defaultMessage: "Expires: {date}"
};

export const MSG_changePlanTitle = {
  id: "2a745caf-ae89-49d9-96fe-0cd7819fc5dd",
  description: "The button that lets them change their current subscription plan",
  defaultMessage: "Change Plan"
};

export const MSG_viewNewPlansTitle = {
  id: "80eb94ef-6a78-4497-a9f8-25bcc7cc0ee6",
  description: "The button to let them view all of our subscription plans they can choose from",
  defaultMessage: "View New Plans"
};


export const MSG_cancelSubscriptionTitle = {
  id: "3f317c4b-63d4-43ff-9970-75f9f135b860",
  description: "The button that lets them cancel their subscription.",
  defaultMessage: "Cancel Subscription"
};

export const MSG_undoCancellationTitle = {
  id: "3c03e5ff-9e8e-4666-aea9-b0f3a3e32355",
  description: "This is the button that lets them undo cancelling their subscription",
  defaultMessage: "Undo Cancellation"
};


export const MSG_managePaymentsMethodsTitle = {
  id: "d968cd46-a8b3-44b2-bc6e-cd1e53a722b6",
  description: "The button that lets them manage their payments.  This is where they can add or change a credit card, etc.",
  defaultMessage: "Manage Payment Methods"
};

export const MSG_viewPaymentHistoryLabel = {
  id: "9ac0de20-fa67-4b17-8e7c-cc63ce08cd10",
  description: "This is the label for a link that takes them to the place where they can get a list of their past invoices.",
  defaultMessage: "View payment history"
};

export const MSG_viewDraftInvoiceLabel = {
  id: "6383789f-0171-41b6-b549-751ef7a4b26a",
  description: "This is the label for a link that lets them download a draft (not finalized) version of their upcoming invoice for next month.",
  defaultMessage: "Preview upcoming invoice"
};

export const MSG_accountCreditLabel = {
  id: "4b2f5621-c9af-4bdf-ae3b-f0a6a8dd092e",
  description: "",
  defaultMessage: "Account credit: {amount}"
};

export const MSG_nextPaymentLabel = {
  id: "91a44e81-9573-4945-97fa-7b616334cd44",
  description: "This is the label for the section of the billing page where they can preview their payment due next month",
  defaultMessage: "Next payment"
};

export const MSG_paymentDueOnDateLabel = {
  id: "0c8cfe31-aba2-4a9a-9259-9b089df489b9",
  description: "This is a shorthand notation to show the date that a payment is due.",
  defaultMessage: "on {date}"
};

export const MSG_numActivePianosTitle = {
  id: "3209c9ed-058f-4afb-9c52-cbb684feaec4",
  description: "This is an indicator that shows how many pianos they have selected for their subscription plan.",
  defaultMessage: "{numPianos} Active Pianos"
};

export const MSG_numActivePianosLabel = {
  id: "9686a1ae-8166-4211-afec-61e8b0f191ea",
  description: "An indicator of how many active pianos they are allowed to have for this subscription plan",
  defaultMessage: "{numPianos} active pianos"
};

export const MSG_planAmountPerMonth = {
  id: "8b3bc278-9fbe-49d8-8e95-79436507b61b",
  description: "This shows how much they will pay each month",
  defaultMessage: "{amount} per month"
};

export const MSG_planAmountPerYear = {
  id: "944c3866-1756-441b-9290-a3da6dca4233",
  description: "This shows how much they will pay each year",
  defaultMessage: "{amount} per year"
};

export const MSG_monthlyPricingLabel = {
  id: "c52d39ed-0414-4751-86d6-d94de275af24",
  description: "",
  defaultMessage: "Monthly"
};

export const MSG_annualPricingLabel = {
  id: "045c9fc1-710b-419c-b989-94f755d9f012",
  description: "",
  defaultMessage: "Annual"
};

export const MSG_annualSavingsLabel = {
  id: "134a7d31-a828-4cda-91d1-1bc64ffaf826",
  description: "This is the amount of savings by switching to an annual (yearly) plan.  It will read like: 'Save $159' (or whatever currency they use)",
  defaultMessage: "Save {amount}"
};

export const MSG_thatsJustLabel = {
  id: "7ddfa8e3-8b62-4023-af6d-d40e5ad3d9ce",
  description: "",
  defaultMessage: "That's {amount} per month"
};

export const MSG_changeToPlanOnDate = {
  id: "beb63ffb-9e54-4fb1-b679-7e0e09cb9ec0",
  description: "This is a description of a scheduled change to their billing plan.",
  defaultMessage: "Your plan will change to support {pianoCount} pianos with {features} on {planDate}."
};

export const MSG_billingTermsFaqTitle = {
  id: "912e842d-93f5-4996-a875-036d092afb69",
  description: "",
  defaultMessage: "Billing Frequently Asked Questions"
};

export const MSG_manageBillingSettingsTitle = {
  id: "79a879a2-8db1-4494-975f-711831c457e9",
  description: "",
  defaultMessage: "Manage Billing Settings"
};

export const MSG_paymentTroubleExplanation = {
  id: "d4c56d43-8266-438c-a8bb-54a50641c113",
  description: "",
  defaultMessage: "You can update your billing settings to update your payment methods, change your subscription plan, or cancel your subscription entirely."
};

export const MSG_deactivateClientsOrPianosTitle = {
  id: "68c8f0e0-0962-4cff-b515-9cafb98ec1d5",
  description: "",
  defaultMessage: "Deactivate Clients or Pianos"
};

export const MSG_deactivateClientsOrPianosExplanation = {
  id: "3f6831f5-c70a-47f9-b608-f3752503421d",
  description: "",
  defaultMessage: "You'll need to mark {overCount} pianos as inactive to stay within your current plan limits."
};

export const MSG_billingContactEmailLabel = {
  id: "ad77c114-5124-4188-82db-f90631d001cc",
  description: "This is a label for the text box where they can enter the email address for who should receive receipts for payment for Gazelle.",
  defaultMessage: "Billing contact email"
};

export const MSG_editBillingContactEmailTitle = {
  id: "697ca653-ac73-4248-a559-fbacf723c896",
  description: "",
  defaultMessage: "Edit Billing Contact Email"
};

export const MSG_billingContactEmailExplanation = {
  id: "0aca00a6-90c4-4442-b046-eff1317ab362",
  description: "",
  defaultMessage: "This is the email address we will use to send payment receipts and billing notices for your Gazelle subscription."
};

export const MSG_showLargerPlansTitle = {
  id: "a1daee93-c38d-4fee-a021-83359f64941d",
  description: "This is a button that shows some of our larger (more expensive) subscription plans intended for larger companies.",
  defaultMessage: "Show Larger Plans"
};

export const MSG_showSmallerPlansTitle = {
  id: "c5c577bc-a90c-4e69-8a24-cf5e4208cbbd",
  description: "This is a button that shows some of our smaller (less expensive) subscription plans intended for smaller companies",
  defaultMessage: "Show Smaller Plans"
};

export const MSG_moreThanPianosQuestion = {
  id: "a090d9d7-e9f1-4e00-b98f-0fef54134e2d",
  description: "A short question that asks if they have more than a certain number of pianos.",
  defaultMessage: "More than {numPianos} pianos?"
};

export const MSG_contactUsForPricing = {
  id: "57c819d3-c542-4c00-9a71-a45b6c241ce9",
  description: "A short instruction to contact us if they want pricing information.",
  defaultMessage: "Contact us for pricing."
};

export const MSG_start30DayFreeTrial = {
  id: "8adc52ff-f0e5-40b5-a4db-3bf26e369036",
  description: "A button that starts their 30-day free trial",
  defaultMessage: "Start Your 30-Day Free Trial"
};

export const MSG_chooseActivePianosInstructions = {
  id: "77880172-3f1b-4d07-a25e-541f25ae32e2",
  description: "",
  defaultMessage: "Choose the number of active pianos you manage and the features you need."
};


export const MSG_after30DayFreeTrial = {
  id: "89bdaf92-85c7-494e-ad31-a4d4379462e0",
  description: "This is shown under the subscription price.  It indicates that they will be charged $X/month, but not until after their free trial ends.",
  defaultMessage: "After your 30-day free trial"
};

export const MSG_pricingExplanation0 = {
  id: "3c88e9db-6546-4991-8224-5717a389942a",
  description: "",
  defaultMessage: "On average, {numPianos} active pianos support one field technician working as a side hustle or small business with a focused clientele."
};

export const MSG_pricingExplanation1 = {
  id: "31887419-4ff9-434a-b4c2-ab201d6dfa4a",
  description: "",
  defaultMessage: "On average, {numPianos} active pianos support one field technician working a part-time schedule with a growing business in a new area."
};

export const MSG_pricingExplanation2 = {
  id: "b75b89f5-ec66-4c4f-b014-99e17113be0f",
  description: "",
  defaultMessage: "On average, {numPianos} active pianos support one field technician approaching a consistently full-time schedule for an established clientele."
};

export const MSG_pricingExplanation3 = {
  id: "93212b9b-2438-488b-aea5-3807613fa8fc",
  description: "",
  defaultMessage: "On average, {numPianos} active pianos support one field technician working a full-time schedule for an established clientele in a growing business."
};

export const MSG_pricingExplanation4 = {
  id: "56658cd0-4409-48a8-94a3-e7fc144b1bad",
  description: "",
  defaultMessage: "On average, {numPianos} active pianos support one field technician working a very full schedule in a growing business that is on its way to additional technicians."
};

export const MSG_pricingExplanation5 = {
  id: "d0b2dd02-3e6c-4ef8-8cff-7e079fa6fd55",
  description: "The {numTechnicians} variable is a block of text that will be translated elsewhere.  It is separate because it may end up being in a red badge with styling around it, so it needs to be translated separately.  It will read like '3 technicians' or '1 technician' in English.",
  defaultMessage: "On average, {numPianos} active pianos support {numTechnicians} working a full-time schedule for an established clientele and growing business."
};

export const MSG_pricingNumTechnicians = {
  id: "d7b62172-4ac8-455f-a704-661155b1fe8e",
  description: "",
  defaultMessage: "{numTechnicians, plural, =1 {1 technician} other {# technicians}}"
};


export const MSG_pricingExplanationPrefixDeadPianos = {
  id: "4948e77e-33ac-454f-ba07-56aa08270171",
  description: "",
  defaultMessage: "{num} of your {total} active pianos have been tuned in the last 24 months."
};


export const MSG_pricingActivePianoInfo1 = {
  id: "94475283-49c9-4f4a-ae93-70438b3c86f9",
  description: "",
  defaultMessage: "You decide which pianos are active. Inactive pianos remain in your system but do NOT count towards your plan limits."
};

export const MSG_pricingActivePianoInfo2 = {
  id: "349b34e7-3d0d-4ff3-a8fe-0dfb29756979",
  description: "",
  defaultMessage: "You will have 30 days to import clients and decide which pianos are active. Inactive pianos remain in your system but do NOT count towards your plan limits."
};

export const MSG_pricingActivePianoInfo3 = {
  id: "060b4701-629d-4c30-98ce-e1d2d080a99e",
  description: "",
  defaultMessage: "You have until {date} to decide which pianos are active. Inactive pianos remain in your system but do NOT count towards your plan limits."
};

export const MSG_addFeatureToYourPlanToday = {
  id: "969862e8-8cd5-4465-a279-8aeaf2c3e541",
  description: "This is a message displayed when we prompt them to add a feature to their subscription plan",
  defaultMessage: "Add {featureName} to your plan today!"
};

export const MSG_addTheseFeaturesToYourPlanToday = {
  id: "b71166e2-2822-43ab-a580-35e1f2c86ac0",
  description: "This is a message displayed when we prompt them to add a set of features to their subscription plan",
  defaultMessage: "Add these features to your plan today!"
};

export const MSG_addFeatureTitle = {
  id: "f7e20704-db7e-4084-8067-4b3bee70a66c",
  description: "The label for a button where they can add a feature to their subscription",
  defaultMessage: "Add {featureName}"
};

export const MSG_addTheseFeaturesTitle = {
  id: "2153ee97-656b-429f-8cea-72d6dd1d690a",
  description: "The label for a button where they can add multiple features to their subscription.",
  defaultMessage: "Add These Features"
};

export const MSG_addFeaturesMobileExplanation = {
  id: "bf96b31c-31ea-4cf2-9122-186b8d924be0",
  description: "",
  defaultMessage: "This feature can be added to your plan through the Gazelle website under the Billing section in Settings."
};

export const MSG_adminNeedsToAddFeature = {
  id: "3752600b-c6bc-417a-9161-18969378b6df",
  description: "",
  defaultMessage: "Ask your account administrator to add {featureName} to your Gazelle subscription today."
};

export const MSG_addFeatureBillingExplanation = {
  id: "f1e7d2ae-1e4c-4d5d-9019-5ad887551956",
  description: "",
  defaultMessage: "You will be charged a prorated amount today for your current billing cycle which ends on {date}."
};

export const MSG_monthlyToAnnualIntervalDetailExplanation = {
  id: "71e149f0-8e6b-46d1-9f91-eccb70b8380f",
  description: "",
  defaultMessage: "Congratulations! You will save {saveAmount} on your Gazelle plan over the next year, when compared with the monthly rate!<newline></newline><newline></newline>You will be credited for any unused portion of your current billing cycle.<newline></newline><newline></newline><bold>Your charge today will be {chargeToday}</bold> and your new billing cycle will auto-renew on {annualRenewalDate}, and each following year.<newline></newline><newline></newline><bold>Annual plans are non-refundable</bold>, however, while on an annual plan you can add features and active pianos at any time for a prorated rate. Clicking 'OK' will charge you today and give you access to this new level of features and pianos."
};

export const MSG_annualToMonthlyIntervalDetailExplanation = {
  id: "b6c97674-afda-40c9-8d4a-1f9b63db76da",
  description: "",
  defaultMessage: "You will be credited for any unused portion of your annual plan's billing cycle.<newline></newline><newline></newline><bold>Your charge today will be {chargeToday} and your new billing cycle will auto renew on {monthlyRenewalDate}, and each following month.</bold><newline></newline><newline></newline>Clicking 'OK' will charge you today and give you access to this new level of features and pianos."
};

export const MSG_annualPlanChangeToHigherAmountExplanation = {
  id: "05e6914a-efc4-4814-a876-d196c818ee6a",
  description: "",
  defaultMessage: "Congratulations! You will save {saveAmount} on your Gazelle plan over the next year, when compared with the monthly rate!<newline></newline><newline></newline><bold>To make these changes today you will be charged a prorated amount of {chargeToday} for your current billing cycle which ends on {annualRenewalDate}.</bold><newline></newline><newline></newline><bold>Annual plans are non-refundable.</bold> Clicking “OK” will charge you today and give you access to this new level of features and pianos.<newline></newline><newline></newline>If you are needing to explore a new feature before adding it to your plan, scheduling a demo with our team is easy! <link>Click here</link>."
};

export const MSG_monthlyPlanChangeToHigherAmountExplanation = {
  id: "64098d8d-653c-4359-97cd-564e70eeb83e",
  description: "",
  defaultMessage: "<bold>To make these changes today you will be charged a prorated amount of {chargeToday} for your current billing cycle which ends on {monthlyRenewalDate}.</bold><newline></newline><newline></newline>Clicking “OK” will charge you today and give you access to this new level of features and pianos.<newline></newline><newline></newline>If you are needing to explore a new feature before adding it to your plan, scheduling a demo with our team is easy! <link>Click here</link>."
};

export const MSG_selfSchedulingNotEnabled = {
  id: "2c9de42f-4d12-438d-8895-36e469dc37b6",
  description: "",
  defaultMessage: "Client self-scheduling is part of Gazelle's Scheduling feature which is not enabled on your account."
};

export const MSG_stripeMayAlsoChargeFees = {
  id: "9306349a-2eab-4c14-b139-817d903d9ab5",
  description: "A notice that Stripe (our credit card processing company) may also charge processing fees.",
  defaultMessage: "If payment processing is enabled, Stripe may also charge processing fees."
};

export const MSG_upcomingPlanChangesOnDate = {
  id: "ce924e2d-30f6-460c-af63-6cd2ad11cf81",
  description: "",
  defaultMessage: "On {date} the following changes will take effect on your plan:"
};

export const MSG_decreasingPianoCount = {
  id: "93489cac-4032-4f2c-a96e-9a0d9917fe26",
  description: "",
  defaultMessage: "Decreasing your piano limit from {currentPianoCount} to {upcomingPianoCount}"
};

export const MSG_removingOneFeature = {
  id: "ab63fd56-552e-497d-97a2-1d616f1b019b",
  description: "",
  defaultMessage: "Removing {feature}"
};

export const MSG_removingMultipleFeatures = {
  id: "6f702af3-d444-41ff-882f-2cae5bf26ad5",
  description: "",
  defaultMessage: "Removing features: {features}"
};

export const MSG_nextPaymentAttemptLabel = {
  id: "b792b3ea-3621-4556-9fb7-68ad5f15914a",
  description: "",
  defaultMessage: "Next payment attempt"
};

export const MSG_appliedCredit = {
  id: "327e3c8c-1383-4159-9bae-b8b3e8dbda7a",
  description: "This is a label for displaying how much of their outstanding balance was applied to this invoice.",
  defaultMessage: "Applied credit"
};

export const MSG_cancelSubscriptionConfirmation = {
  id: "ff2fa363-ac40-4164-8094-fbe41cecc8a1",
  description: "",
  defaultMessage: "If you confirm and end your subscription now, you can still access it until {date}.  Are you sure you want to cancel auto-renewing of your subscription?"
};

export const MSG_yourSubscriptionWillExpire = {
  id: "c8f92959-cc78-4fc8-bb40-3ad5b457154a",
  description: "",
  defaultMessage: "Your subscription to Gazelle has been cancelled and will not be renewed. However, you can still access your account through the end of your current billing cycle (until {date})."
};

export const MSG_signupPublicInfoNotice = {
  id: "051dc41f-4efb-4225-94b2-71a88e183ffc",
  description: "",
  defaultMessage: "All the information you enter in this step may be shown to your clients on outbound emails, texts, or your public pages."
};

export const MSG_findACountryLabel = {
  id: '8a4d9683-5aaa-43cc-b321-c15602b73d3c',
  description: '',
  defaultMessage: 'Find a country'
};

export const MSG_countryLabel = {
  id: "1254e062-c7d1-4902-8cb5-98673c9ea842",
  description: "The field where they tell us what country they live in",
  defaultMessage: "Country"
};

export const MSG_companyAddressLabel = {
  id: "34193bb6-a114-41a1-8c83-b2eeed9cb6c7",
  description: "The field where they tell us the address of their company",
  defaultMessage: "Company address"
};

export const MSG_companyPhoneNumberLabel = {
  id: "d317b678-83ef-4c3c-9a85-23d0816740c9",
  description: "The field where they tell us the phone number of their company",
  defaultMessage: "Company phone number"
};

export const MSG_companyWebsiteLabel = {
  id: "a1b2c3d4-5678-9abc-def0-1234567890ab",
  description: "The field is where they tell us the website of their company",
  defaultMessage: "Company Website"
};


export const MSG_companyEmailLabel = {
  id: "f3e0508f-2d54-490b-9da2-2465c49cd58d",
  description: "The field where they tell us the email address of their company",
  defaultMessage: "Company email"
};

export const MSG_yourFirstNameLabel = {
  id: "ab71139d-60b0-4cdd-9777-bb563f8d62ec",
  description: "The field where they tell us their first name",
  defaultMessage: "Your first name"
};

export const MSG_yourLastNameLabel = {
  id: "07cd711e-9011-4c51-acc0-e7e78522b3c3",
  description: "The field where they tell us their first name",
  defaultMessage: "Your last name"
};

export const MSG_yourTimezoneLabel = {
  id: "89c73cae-33e4-4ee5-b797-407ae33b8c2d",
  description: "The field where they tell us their timezone",
  defaultMessage: "Your timezone"
};

export const MSG_yourEmailLabel = {
  id: "2cd3df67-e4aa-4b38-a39e-992b9f75b4d9",
  description: "The field where they tell us their personal email address",
  defaultMessage: "Your email"
};

export const MSG_yourEmailUsageExplanation = {
  id: "0e56c75b-9adf-421e-9f15-1547b1b8955b",
  description: "",
  defaultMessage: "This is what you will use to login, and how we will contact you with notices and alerts about your account (like pending appointment reservations and billing information)."
};

export const MSG_chooseAPasswordLabel = {
  id: "c1fc04da-8ec8-4989-aa14-9d8c3e3e0f14",
  description: "",
  defaultMessage: "Choose a password"
};

export const MSG_confirmYourPassword = {
  id: "7494af29-eb35-46dc-acd2-a9906aff6b68",
  description: "",
  defaultMessage: "Confirm your password"
};

export const MSG_paymentMethodAddedSecurely = {
  id: "5e82073e-8bbb-42b8-9a28-9e13488b2ff3",
  description: "",
  defaultMessage: "A payment method has been securely added."
};

export const MSG_shortBillingTerms = {
  id: "38f42fa4-58a8-4520-9192-4aa5daef9768",
  description: "",
  defaultMessage: "A payment source is required to start your free trial and you may change your plan, add or remove features, or cancel your subscription at any time.  Your card will be charged at the end of your free trial on {trialEndDate}."
};

export const MSG_consentTerms = {
  id: "45f5cb5e-c982-44f9-b87d-6ecb75ebb0f9",
  description: "This contains two variables {termsLink} and {privacyPolicyLink}.  They will contain the words \"Terms of Service\" and \"Privacy Policy\" (translated) so this will read like a sentence, but those two variables will be links.",
  defaultMessage: "By clicking on \"Grow With Gazelle\" you signify that you agree to our {termsLink} and {privacyPolicyLink}."
};

export const MSG_gdprTermsLine1 = {
  id: "fdf58ff7-b50c-4dca-9b9b-1541be61180a",
  description: "",
  defaultMessage: "I consent to allow Gazelle to store and use my personal data for the purpose of providing business management services for my piano service company."
};

export const MSG_gdprTermsLine2 = {
  id: "d3fa9245-ee13-42c7-a254-356e2d6f0822",
  description: "",
  defaultMessage: "I understand that I may withdraw consent at any time by calling +1 (704) 271-9877 or emailing {emailAddress}. Upon receiving verbal or written consent, Gazelle will remove all my data and client records."
};

export const MSG_gdprTermsLine3 = {
  id: "df19c95d-20d6-410b-a602-37562f7c7e54",
  description: "",
  defaultMessage: "I understand that Gazelle may contact me periodically to help fulfill support requests, answer questions, get my account configured properly, product announcements, and other Gazelle-related matters."
};

export const MSG_gdprTermsLine4 = {
  id: "df6034d6-7942-4216-9355-080f320ea185",
  description: "",
  defaultMessage: "I consent to use Gazelle's services under the {termsLink} and {privacyPolicyLink}."
};

export const MSG_yourPlanTitle = {
  id: "a0f7a328-27f8-4557-85de-3df09d74be78",
  description: "A short label for their subscription plan.",
  defaultMessage: "Your Plan"
};

export const MSG_backTitle = {
  id: "817113ab-e906-4ab0-b29e-9e2a907560ec",
  description: "A button that lets them go back to the previous step",
  defaultMessage: "Back"
};

export const MSG_growWithGazelleTitle = {
  id: "c9f555d3-4c9f-402e-b914-73df8ef4613f",
  description: "This is the title of a button that signs them up for a new account.  It should be a verb that indicates action.",
  defaultMessage: "Grow With Gazelle"
};

export const MSG_signupActionMessage1 = {
  id: "9f59f695-19e4-4055-8061-ab18e8fa0f32",
  description: "",
  defaultMessage: "Creating your user account..."
};

export const MSG_signupActionMessage2 = {
  id: "db6caf3a-2684-44aa-bc84-14fdbc283312",
  description: "",
  defaultMessage: "Initializing your calendar..."
};

export const MSG_signupActionMessage3 = {
  id: "0ad259c7-3afe-4fe2-a9c1-ed0aad2d33cf",
  description: "",
  defaultMessage: "Setting up default reminder settings..."
};

export const MSG_signupActionMessage4 = {
  id: "0f4b8784-0cbe-4f77-9a03-d508cf169bd0",
  description: "",
  defaultMessage: "Creating your subscription plan..."
};

export const MSG_signupActionMessage5 = {
  id: "2de48844-6114-4140-b329-693c6aef7c06",
  description: "",
  defaultMessage: "Building condition report templates..."
};

export const MSG_signupActionMessage6 = {
  id: "940a785b-3413-4300-9e64-3829406dd4d2",
  description: "",
  defaultMessage: "Creating your master service list..."
};

export const MSG_signupActionMessage7 = {
  id: "da628961-2a6a-42e5-bbe8-5431e059e9fb",
  description: "",
  defaultMessage: "Setting up your scheduling system..."
};

export const MSG_signupActionMessage8 = {
  id: "b132b142-2db0-4dcd-b574-5bea4cd06ffb",
  description: "",
  defaultMessage: "Initializing your client database..."
};

export const MSG_signupActionMessage9 = {
  id: "e115fdb6-16c3-48f1-a53d-947eaed5b5b9",
  description: "",
  defaultMessage: "Finalizing your settings..."
};

export const MSG_accountCreated = {
  id: "4d1e3c36-ec1f-4855-b50b-0c8ba0db2b67",
  description: "",
  defaultMessage: "Your account has been created!"
};

export const MSG_useEmail = {
  id: "272f4169-a131-484f-8b6d-1089ca8c7d3b",
  description: "A button to let them use a specific email address.",
  defaultMessage: "Use {email}"
};

export const MSG_onYourFreeTrial = {
  id: "d7c8cc06-995d-4136-9bc2-cbb5064e6102",
  description: "",
  defaultMessage: "Free trial ends on {date}."
};

export const MSG_youHaveDaysLeft = {
  id: "d4d23ce5-5e7d-48be-b6da-df83eef8135b",
  description: "",
  defaultMessage: "{numDays, plural, =1 {You have 1 day remaining.} other {You have # days remaining.}}"
};

export const MSG_currentUsageTitle = {
  id: "c6b7e712-75c1-43c6-8be5-3bc791b43f9d",
  description: "The title for the section that shows how many active pianos they have used out of their total allowed active pianos on their current subscription plan.",
  defaultMessage: "Current Usage"
};

export const MSG_importForFree = {
  id: "ccab929c-ee32-478f-9a10-800f7e8438eb",
  description: "",
  defaultMessage: "Did we mention we'll clean up and import your data for free?"
};

export const MSG_importInfo = {
  id: "57252e5c-3c40-4b98-850a-4f19e500c16f",
  description: "",
  defaultMessage: "Importing from digital formats is free. If your client data is on paper, we may charge a nominal fee for a data transcription service."
};

export const MSG_importFreeTrial = {
  id: "3a6aa792-cf26-4471-81a5-fb59a5c1c5bf",
  description: "",
  defaultMessage: "Yeah, even on the free trial. Import instructions will be waiting on your dashboard when you sign up."
};

export const MSG_specialOfferNotice = {
  id: "a25f8ca2-a50a-4b0f-b462-20263da10ce8",
  description: "This is a notice about a special offer when they sign up, and then telling them what the price will be after the special offer expires.",
  defaultMessage: "Special offer for {duration} then {amount}"
};

export const MSG_proratedChargeImmediatelyNotice = {
  id: "99d356c6-0889-48bd-b317-d07a358c2ee7",
  description: "",
  defaultMessage: "This change will cause a prorated charge to be invoiced today for the remainder of your current billing cycle.  Your next billing cycle will be charged the full amount."
};

export const MSG_smsIncludedButNoPhoneNumberReserved = {
  id: "5aad8321-96c6-48f6-afa6-e9c0353ac908",
  description: "",
  defaultMessage: "You have enabled SMS on your subscription, but have not yet configured SMS on your company profile.  No SMS messages will be sent until you complete this configuration."
};

export const MSG_quickBooksIncludedButNotUSCompany = {
  id: "d668f78d-967e-4fb5-9c56-69c04d498564",
  description: "",
  defaultMessage: "You have enabled QuickBooks Integration on your subscription, but this feature is currently only available in the US."
};

export const MSG_cancelReasonHeading1 = {
  id: "ef5d924f-ddc8-43f3-b6df-075be319dbac",
  description: "",
  defaultMessage: "We're sorry to see you go!"
};

export const MSG_cancelReasonHeading2 = {
  id: "9529346a-45de-4a46-b3dc-64ddd7071dcc",
  description: "",
  defaultMessage: "Please tell us why you're cancelling and how we can improve."
};

export const MSG_cancelReason1 = {
  id: "4ddc63f8-0727-4c1d-a4a8-24ec5aaa2196",
  description: "",
  defaultMessage: "I don't see the features I need."
};

export const MSG_cancelReason2 = {
  id: "b7c3f3e4-0983-448f-9a28-92d18c11a81c",
  description: "",
  defaultMessage: "I am too busy to learn something new right now."
};

export const MSG_cancelReason3 = {
  id: "c3c30f8e-c0b7-4aee-a839-410e7a08f345",
  description: "",
  defaultMessage: "I am in the process of transitioning from my old system, and it is just taking time."
};

export const MSG_cancelReason4 = {
  id: "9fef3e2d-8496-4934-b5af-16082a3d9d78",
  description: "",
  defaultMessage: "I don't see as much value as I expected when I signed up."
};

export const MSG_cancelReason5 = {
  id: "a80988e3-19f7-4324-87f3-c104cfa06366",
  description: "",
  defaultMessage: "I am temporarily downgrading my account."
};

export const MSG_cancelReason6 = {
  id: "973c1bd0-4aff-4c59-8b86-48160d2aef21",
  description: "",
  defaultMessage: "I need to talk to my superior."
};

export const MSG_cancelReason7 = {
  id: "6ab3275f-1183-4682-83ee-40de755b00f5",
  description: "",
  defaultMessage: "Other reasons, comments, or suggestions for improvement:"
};

export const MSG_saveAndChangePlanTitle = {
  id: "1a9a3e81-e33f-4746-bf0b-86094e517840",
  description: "The save button on a window that lets them change their subscription plan.  This explicitly tells them what will happen instead of just a simple 'Save' label.",
  defaultMessage: "Save & Change Plan"
};

export const MSG_addPianosTitle = {
  id: "0da019d6-e798-427e-97c6-7d357ad216ce",
  description: "The title for a button that will launch a dialog that lets the user add pianos to their subscription plan.",
  defaultMessage: "Add Pianos"
};

export const MSG_scheduleADemo = {
  id: "46cf51bc-9342-4edc-94a7-bd1361ba7b47",
  description: "This is a button that lets them schedule a demo with us.  It is a sales call to help them get started with the product.",
  defaultMessage: "Schedule a Demo"
};
