import * as React from 'react';
import { Alert, Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { observer } from 'mobx-react';

import {
  AddressTypeahead,
  LoadingIndicator,
  Privacy,
  scrollTop,
  SimpleFormat,
  StepContent,
  StepFooter,
  StickyNote,
  StreetAddressWithMap
} from '@core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { formatMessage, LocationCoordinatesType } from "@gazelle/shared/utils";
import {
  MSG_callUsAtPhone,
  MSG_dropAPinOnAMapLabel,
  MSG_findYourStreetAddressLabel,
  MSG_latitudeTitle,
  MSG_longitudeTitle,
  MSG_nextTitle,
  MSG_or,
  MSG_unableToFindAddressQuestion,
  MSG_verifyYourStreetAddressTitle
} from "@gazelle/shared/strings";
import { rootStoreInstance } from "@client_scheduler/modules/root";
import { LOCATION_TYPE } from 'shared/enums';
import { CoordinatesSelectorMap } from './CoordinateSelectorMap';

interface IStep1AddressProps {}

const Step1Address = (observer(class Step1Address extends React.Component<IStep1AddressProps> {
  componentDidMount() {
    scrollTop();
  }

  handleAddressSelected = (addressLine: any) => {
    const store = rootStoreInstance.locationStore;
    store.addressLine = addressLine;
    store.isVerifying = true;
    store.errorMessage = null;
  };

  handleAddressVerified = (response: any) => {
    const store = rootStoreInstance.locationStore;
    store.isVerifying = false;
    store.location = {
      street1: response.addressComponents?.addressComponents?.street1,
      street2: response.addressComponents?.addressComponents?.street2,
      municipality: response.addressComponents?.addressComponents?.municipality,
      region: response.addressComponents?.addressComponents?.region,
      postalCode: response.addressComponents?.addressComponents?.postalCode,
      countryCode: response.addressComponents?.addressComponents?.countryCode,
    };
    store.geocode = response.geocode.geocodedAddress;
  };

  handleError = (errorMessage: string) => {
    const store = rootStoreInstance.locationStore;
    store.isVerifying = false;
    store.location = {
      street1: null,
      street2: null,
      municipality: null,
      region: null,
      postalCode: null,
      countryCode: null,
    };
    store.errorMessage = errorMessage;
  };

  handleNextButtonClicked = () => {
    if (rootStoreInstance.isStepComplete[1]) {
      rootStoreInstance.unlockStep(2);
      rootStoreInstance.setCurrentStep(2);
    }
  };

  render() {
    const rootStore = rootStoreInstance;
    const { companyPhoneNumber, locationStore,
              locationStore: { verifiedAddress, isVerifying,
                location, geocode, errorMessage }} = rootStoreInstance;

    return (
      <div>
        <StepContent className="step1-address-container" minHeight={rootStoreInstance.minHeight}>
          {locationStore.locationType === LOCATION_TYPE.ADDRESS && (
            <div>
              <Row>
                <Col xs={12} xl={6}>
                  <h4 className="first-header">{formatMessage(MSG_findYourStreetAddressLabel)}</h4>
                </Col>
                <Col xs={12} xl={6} className="text-xl-end text-center">
                  {rootStore.companySettings.allowCoordinateLocationType && (
                    <div className="mb-2">
                      <span className="me-1">{formatMessage(MSG_or)}{' '}</span>
                      <a href="#" className="text-muted" onClick={() => locationStore.locationType = LOCATION_TYPE.COORDINATES}>{formatMessage(MSG_dropAPinOnAMapLabel)}</a>
                    </div>)}
                </Col>
              </Row>
              <Form>
                <Row>
                  <Col>
                    <FormGroup>
                      <AddressTypeahead
                        addressString={locationStore.addressLine}
                        typeaheadProvider={rootStore.typeaheadProvider}
                        onChange={addressLine => locationStore.addressLine = addressLine}
                        onSelect={this.handleAddressSelected}
                        onVerify={this.handleAddressVerified}
                        onError={this.handleError}
                        autoFocus={!locationStore.addressLine}
                      />
                    </FormGroup>

                    {errorMessage && <Alert color="danger">{errorMessage}</Alert>}

                    <div className="d-flex flex-row align-items-center justify-content-center text-muted">
                      <div className="me-2">
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                      </div>
                      <div>
                        {formatMessage(MSG_unableToFindAddressQuestion)}
                        {' '}
                        {rootStore.companySettings.allowCoordinateLocationType && (
                          <>
                            <a href="#" className="text-muted" onClick={() => locationStore.locationType = LOCATION_TYPE.COORDINATES}>{formatMessage(MSG_dropAPinOnAMapLabel)}</a>
                            {' '}{formatMessage(MSG_or)}{' '}
                          </>
                        )}
                        {formatMessage(MSG_callUsAtPhone, { phone: companyPhoneNumber })}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          )}

          {locationStore.locationType === LOCATION_TYPE.COORDINATES && (
            <div>
              <Row>
                <Col xs={12} md={6}>
                  <h4 className="first-header">{formatMessage(MSG_dropAPinOnAMapLabel)}</h4>
                </Col>
                <Col xs={12} md={6} className="text-md-end text-center">
                  <div className="mb-2">
                    <span className="me-1">{formatMessage(MSG_or)}{' '}</span>
                    <a href="#" className="text-muted" onClick={() => locationStore.locationType = LOCATION_TYPE.ADDRESS}>
                      {formatMessage(MSG_findYourStreetAddressLabel)}
                   </a>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <CoordinatesSelectorMap
                    initialCenter={{
                      lat: parseFloat((location as LocationCoordinatesType).latitude) || parseFloat(rootStore.companyLatitude) || 0,
                      lng: parseFloat((location as LocationCoordinatesType).longitude) || parseFloat(rootStore.companyLongitude) || 0
                    }}
                    initialLocation={location ? {
                      lat: parseFloat((location as LocationCoordinatesType).latitude),
                      lng: parseFloat((location as LocationCoordinatesType).longitude)
                    } : undefined}
                    zoom={
                      (location as LocationCoordinatesType).latitude &&
                        (location as LocationCoordinatesType).longitude ? 12 :
                        (rootStore.companyLatitude && rootStore.companyLongitude ? 12 : 2)
                    }
                    onChange={(loc) => {
                      locationStore.locationType = LOCATION_TYPE.COORDINATES;
                      locationStore.addressLine = '';
                      if (loc) {
                        locationStore.location = {
                          latitude: `${loc.lat.toFixed(6)}`,
                          longitude: `${loc.lng.toFixed(6)}`
                        };
                      } else {
                        locationStore.location = {
                          latitude: '',
                          longitude: ''
                        };
                      }
                    }}
                  />

                  {(location as LocationCoordinatesType).latitude && (location as LocationCoordinatesType).longitude && (
                    <div className="text-center mt-3">
                      <div>{formatMessage(MSG_latitudeTitle)}: <b>{(location as LocationCoordinatesType).latitude}</b></div>
                      <div>{formatMessage(MSG_longitudeTitle)}: <b>{(location as LocationCoordinatesType).longitude}</b></div>
                    </div>)}
                </Col>
              </Row>
            </div>
          )}


          {rootStore.isMobile && rootStore.isGdprRequired && <Privacy gdpr={rootStore.isGdprRequired}/>}

          {!isVerifying && !verifiedAddress && rootStore.companySettings.welcomeMessage &&
            <StickyNote className="mt-4">
              <SimpleFormat text={rootStore.companySettings.welcomeMessage}/>
            </StickyNote>
          }
          {isVerifying && !verifiedAddress && (
            <div className="verifying-container">
              <LoadingIndicator verifying />
            </div>
          )}

          {verifiedAddress && locationStore.locationType === LOCATION_TYPE.ADDRESS && (
            <div className="overflowing-footer mt-4">
              <h4>{formatMessage(MSG_verifyYourStreetAddressTitle)}{' '}</h4>
              <StreetAddressWithMap location={location} geocode={geocode}
                                    interactiveMapProvider={rootStoreInstance.interactiveMapProvider}/>
            </div>
          )}
        </StepContent>
        <StepFooter
          rootStore={rootStore}
          rightSide={
            <div>
              <Button size="lg"
                      id="next-button"
                      className="d-none d-md-inline-block"
                      color="primary"
                      disabled={!rootStore.isStepComplete[1]}
                      onClick={this.handleNextButtonClicked}>
                {formatMessage(MSG_nextTitle)}
              </Button>
              <Button className="d-md-none"
                      id="next-button"
                      color="primary"
                      disabled={!rootStore.isStepComplete[1]}
                      onClick={this.handleNextButtonClicked}>
                {formatMessage(MSG_nextTitle)}
              </Button>
            </div>
          }/>
      </div>
    );
  }
}));

export { Step1Address };
