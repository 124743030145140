import { action, computed, makeObservable, observable } from 'mobx';
import * as moment from 'moment';
import { find as _find, values as _values } from 'lodash';
import { DateSlots, graphqlRequest, Slot, User } from '@core';

import { IStoreOptions } from '../root/root_store';
import { RootStore } from "../root";
import { locationToSingleLine } from 'shared/utils';

class SearchStore {
  private rootStore: RootStore = null;
  private resultsTokens: string[] = [];
  private techSearchSignatures: string[] = [];
  private usersMap: {[key: string]: User} = null;

  error: string = null;
  initializing = true;
  searching = true;
  progress = 0;
  progressChunk = 1;
  targetDateSlots: DateSlots = null;
  otherDateSlots: DateSlots[] = null;
  outsideServiceArea: boolean = false;
  selectedSlot: Slot = null;
  searchId: string = null;

  constructor() {
    makeObservable<SearchStore, "schedulerSearch" | "schedulerProgress" | "schedulerResults">(this, {
      error: observable,
      initializing: observable,
      searching: observable,
      progress: observable,
      progressChunk: observable,
      targetDateSlots: observable,
      otherDateSlots: observable,
      outsideServiceArea: observable,
      selectedSlot: observable,
      searchId: observable,
      isComplete: computed,
      initializeData: action,
      performSearch: action,
      selectSlot: action,
      schedulerSearch: action,
      schedulerProgress: action,
      schedulerResults: action
    });
  }

  get isComplete(): boolean {
    return this.selectedSlot !== null;
  }

  initializeData(rootStore: RootStore, options: IStoreOptions) {
    this.rootStore = rootStore;
    this.targetDateSlots = null;
    this.otherDateSlots = [];
    this.searchId = null;

    if (options.useTestData) {
      this.selectedSlot = new Slot({
        startsAt: moment.tz('2018-02-06T16:35:00Z', 'US/Eastern'),
        timezone: 'US/Eastern',
        order: 1,
        weight: 51,
        openDay: false,
        outsideAreaDrive: null,
        restrictions: [],
      }, this.rootStore.users[0]);
    }
  }

  async performSearch() {
    try {
      await this.schedulerSearch();
      await this.schedulerProgress();
    } catch (err) {
      this.initializing = false;
      this.searching = false;
      this.error = err;
    }
  }

  async performV2Search() {
    try {
      await this.schedulerV2Search();
      await this.schedulerV2Progress();
    } catch (err) {
      this.initializing = false;
      this.searching = false;
      this.error = err;
    }
  }

  selectSlot(slot: Slot) {
    this.selectedSlot = slot;
  }

  /**
   * Launches the scheduler search and sets the resultsTokens that will be used
   * later to check on progress and fetch the actual results.
   *
   * @returns {Promise<void>}
   */
  private async schedulerSearch() {
    this.progress = 0;
    this.progressChunk = 1;
    this.resultsTokens.length = 0;
    this.usersMap = {};
    this.initializing = true;
    this.searching = false;
    this.error = null;
    this.outsideServiceArea = false;
    this.selectedSlot = null;
    this.searchId = null;

    let data: any = await graphqlRequest(`
        mutation ($searchParams: PublicSchedulerSearchInput) {
          schedulerSearch(searchParams: $searchParams) {
            mutationErrors {key messages}
            resultsTokens
          }
        }
      `, {
        searchParams: {
          addressLine: locationToSingleLine(this.rootStore.locationStore.location),
          targetDate: this.rootStore.prefStore.targetDate.format('YYYY-MM-DD'),
          duration: this.rootStore.pianoStore.totalDuration,
          userId: this.rootStore.prefStore.computedUserIds,
        }
      });

    this.initializing = false;
    this.resultsTokens = data.schedulerSearch.resultsTokens;
    if (data.schedulerSearch.mutationErrors && data.schedulerSearch.mutationErrors[0]) {
      const err = data.schedulerSearch.mutationErrors[0];
      if (err.messages[0]) {
        throw err.messages[0];
      }
    }

    for (let i = 0; i < this.rootStore.prefStore.computedUserIds.length; i++) {
      let userId = this.rootStore.prefStore.computedUserIds[i];
      this.usersMap[this.resultsTokens[i]] = _find(this.rootStore.users, u => u.id === userId);
    }
  }

  /**
   * Launches the scheduler search and sets the resultsTokens that will be used
   * later to check on progress and fetch the actual results.
   *
   * @returns {Promise<void>}
   */
  private async schedulerV2Search() {
    this.progress = 0;
    this.progressChunk = 1;
    this.resultsTokens.length = 0;
    this.usersMap = {};
    this.initializing = true;
    this.searching = false;
    this.error = null;
    this.outsideServiceArea = false;
    this.selectedSlot = null;
    this.searchId = null;

    let params: any = {
      addressLine: locationToSingleLine(this.rootStore.locationStore.location),
      targetDate: this.rootStore.prefStore.targetDate.format('YYYY-MM-DD'),
      duration: this.rootStore.pianoStore.totalDuration,
      userIds: this.rootStore.prefStore.computedUserIds,
    };
    if (this.rootStore.clientId) {
      params.clientId = this.rootStore.clientId;
    }

    let data: any = await graphqlRequest(`
        mutation ($searchParams: PublicSchedulerV2SearchInput!) {
          schedulerV2Search(searchParams: $searchParams) {
            mutationErrors {key messages}
            technicianSearchSignatures
            searchId
          }
        }
      `, {
      searchParams: params
    });

    this.initializing = false;
    this.techSearchSignatures = data.schedulerV2Search.technicianSearchSignatures;
    this.searchId = data.schedulerV2Search.searchId;
    console.log("Search ID:", this.searchId);
    if (data.schedulerV2Search.mutationErrors && data.schedulerV2Search.mutationErrors[0]) {
      const err = data.schedulerV2Search.mutationErrors[0];
      if (err.messages[0]) {
        throw err.messages[0];
      }
    }

    for (let i = 0; i < this.rootStore.prefStore.computedUserIds.length; i++) {
      let userId = this.rootStore.prefStore.computedUserIds[i];
      this.usersMap[this.techSearchSignatures[i]] = _find(this.rootStore.users, u => u.id === userId);
    }
  }

  /**
   * Checks the progress of a search.  Call this once, and it uses promises to
   * keep polling until the progress is 100%.  The promise will resolve when
   * progress is complete.
   *
   * @returns {Promise<void>}
   */
  private async schedulerProgress() {
    this.searching = true;
    let data: any = await graphqlRequest(`
        query ($resultsTokens: [String!]!) {
          schedulerSearchProgress(resultsTokens: $resultsTokens)
        }
      `, {
        resultsTokens: this.resultsTokens || []
      });

    // If the progress bar goes backwards, it is an indication that we have expanded
    // the search.  Track that so we can show an indicator in the UI.
    if (data.schedulerSearchProgress < this.progress) {
      this.progressChunk += 1;
    }
    this.progress = data.schedulerSearchProgress;
    if (this.progress < 100) {
      await new Promise(resolve => setTimeout(resolve, 250));
      await this.schedulerProgress();
    } else {
      await this.schedulerResults();
    }
  }

  /**
   * Checks the progress of a search.  Call this once, and it uses promises to
   * keep polling until the progress is 100%.  The promise will resolve when
   * progress is complete.
   *
   * @returns {Promise<void>}
   */
  private async schedulerV2Progress() {
    this.searching = true;
    let data: any = await graphqlRequest(`
        query ($sigs: [String!]!) {
          schedulerV2SearchProgress(technicianSearchSignatures: $sigs)
        }
      `, {
      sigs: this.techSearchSignatures || []
    });

    // If the progress bar goes backwards, it is an indication that we have expanded
    // the search.  Track that so we can show an indicator in the UI.
    if (data.schedulerV2SearchProgress < this.progress) {
      this.progressChunk += 1;
    }
    this.progress = data.schedulerV2SearchProgress;
    if (this.progress < 100) {
      await new Promise(resolve => setTimeout(resolve, 250));
      await this.schedulerV2Progress();
    } else {
      await this.schedulerV2Results();
    }
  }

  /**
   * Once progress is complete, this will fetch the completed results and parse
   * them into something usable.
   *
   * @returns {Promise<void>}
   */
  private async schedulerResults() {
    if (this.progress < 100) {
      await new Promise(resolve => setTimeout(resolve, 250));
      return;
    }

    let data: any = await graphqlRequest(`
        query ($resultsTokens: [String!]!) {
          schedulerSearchResults(resultsTokens: $resultsTokens) {
            error
            results {
              resultsToken
              slots {
                startsAt timezone order weight openDay
                outsideAreaDrive restrictions audit
              }
            }
          }
        }
      `, {
        resultsTokens: this.resultsTokens || []
      });

    let resultsWrapper = data.schedulerSearchResults.results;
    if (!resultsWrapper || resultsWrapper.length === 0) {
      console.log('empty results, retrying...');
      await new Promise(resolve => setTimeout(resolve, 250));
      await this.schedulerProgress();
      return;
    }

    // Now lets take the lists of slots and compile them into something usable.
    // This aggregates them by date using the DateSlots class to list all slots
    // for a given date.
    let dates: {[key: string]: DateSlots} = {};
    let allSlots: Slot[] = [];
    let allWeights: number[] = [];
    let outsideServiceAreaByResultToken: {[token: string]: boolean} = {};
    resultsWrapper.forEach((results: any) => {
      const resultsToken = results.resultsToken;

      results.slots.forEach((slotObj: any) => {
        const slot = new Slot(slotObj, this.usersMap[resultsToken]);
        const date = slot.startsAt.format('YYYY-MM-DD');

        // If the user does not want to show short-term limit options, remove
        // them from the results.
        if (slot.restrictions &&
            slot.restrictions.indexOf('short_term_limit') >= 0 &&
            slot.user &&
            slot.user.shortTermLimitPolicy === 'NONE') {
          return;
        }


        // If the slot is outside their service area, and it is not near an existing
        // appointment (as defined by their settings and our scheduling algorithm),
        // then remove this slot from consideration.
        if (slot.restrictions &&
            slot.restrictions.indexOf('outside_area') >= 0 &&
            slot.restrictions.indexOf('near_existing_appointment') < 0) {
          outsideServiceAreaByResultToken[resultsToken] = true;
          return;
        }

        if (!dates[date]) dates[date] = new DateSlots(date);
        dates[date].addSlot(slot);
        allWeights.push(slot.weight);
        allSlots.push(slot);
      });
    });
    if (Object.keys(outsideServiceAreaByResultToken).length === this.resultsTokens.length) {
      this.outsideServiceArea = true;
    }

    // If there are options available on their target date, pull those out
    // separately so we can highlight it in the search results.
    const targetDate = this.rootStore.prefStore.targetDate.format('YYYY-MM-DD');
    if (dates[targetDate]) {
      this.targetDateSlots = dates[targetDate];
      delete dates[targetDate];
    } else {
      this.targetDateSlots = null;
    }
    this.otherDateSlots = _values(dates);
    this.otherDateSlots = this.otherDateSlots.sort((a, b) => {
      if (a.bestSlot.startsAt.isBefore(b.bestSlot.startsAt)) return -1;
      if (a.bestSlot.startsAt.isAfter(b.bestSlot.startsAt)) return 1;
      return 0;
    });

    allWeights.sort();

    // BITROT: this is only used before scheduler v2.
    if (!this.rootStore.schedulerV2Enabled) {
      // Now iterate over all ths slots to figure out which slots are preferred.
      // Technically any slot offered in the UI is schedulable.  However, we want
      // to nudge them toward picking the best option.  To do that, we mark some
      // slots as "preferred".  We consider it preferred if the weight is lower
      // than the other 3/4 of slots.
      let preferredWeight = allWeights[Math.floor(allWeights.length / 4)];
      allSlots.forEach(slot => slot.calculateComparisonWith(preferredWeight));
    }

    this.searching = false;
  }

  /**
   * Once progress is complete, this will fetch the completed results and parse
   * them into something usable.
   *
   * @returns {Promise<void>}
   */
  private async schedulerV2Results() {
    if (this.progress < 100) {
      await new Promise(resolve => setTimeout(resolve, 250));
      return;
    }

    let data: any = await graphqlRequest(`
        query ($sigs: [String!]!) {
          schedulerV2SearchResults(technicianSearchSignatures: $sigs) {
            errors
            isOutsideServiceArea
            results {
              technicianSearchSignature
              slots {
                startsAt timezone duration buffer technicianId weight flags travelMode availabilityId
              }
            }
          }
        }
      `, {
      sigs: this.techSearchSignatures || []
    });

    let resultsWrapper = data.schedulerV2SearchResults.results;
    if (!resultsWrapper || resultsWrapper.length === 0) {
      console.log('empty results, retrying...');
      await new Promise(resolve => setTimeout(resolve, 250));
      await this.schedulerV2Progress();
      return;
    }

    // If the user is outside the service area, then short-circuit the rest of
    // the processing.
    if (data.schedulerV2SearchResults.isOutsideServiceArea) {
      this.targetDateSlots = null;
      this.otherDateSlots = [];
      this.outsideServiceArea = true;
      this.searching = false;
      return;
    }

    // Now lets take the lists of slots and compile them into something usable.
    // This aggregates them by date using the DateSlots class to list all slots
    // for a given date.
    let dates: {[key: string]: DateSlots} = {};
    let allSlots: Slot[] = [];
    let allWeights: number[] = [];
    resultsWrapper.forEach((results: any) => {
      results.slots.forEach((slotObj: any) => {
        const slot = new Slot(slotObj, _find(this.rootStore.users, u => u.id === slotObj.technicianId));
        const date = slot.startsAt.format('YYYY-MM-DD');

        if (!dates[date]) dates[date] = new DateSlots(date);
        dates[date].addSlot(slot);
        allWeights.push(slot.weight);
        allSlots.push(slot);
      });
    });

    // If there are options available on their target date, pull those out
    // separately so we can highlight it in the search results.
    const targetDate = this.rootStore.prefStore.targetDate.format('YYYY-MM-DD');
    if (dates[targetDate]) {
      this.targetDateSlots = dates[targetDate];
      delete dates[targetDate];
    } else {
      this.targetDateSlots = null;
    }
    this.otherDateSlots = _values(dates);
    this.otherDateSlots = this.otherDateSlots.sort((a, b) => {
      if (a.bestSlot.startsAt.isBefore(b.bestSlot.startsAt)) return -1;
      if (a.bestSlot.startsAt.isAfter(b.bestSlot.startsAt)) return 1;
      return 0;
    });

    allWeights.sort();

    if (!this.rootStore.schedulerV2Enabled) {
      // Now figure out which slots are preferred.  Technically any slot offered
      // in the UI is schedulable.  However, we want to nudge them toward picking
      // the best option.  To do that, we mark some slots as "preferred".  We
      // consider it preferred if the weight is lower than a certain threshold.
      // Ideally that would be 0.13, but if there aren't many great options, then
      // we'll use a higher threshold.
      let preferredWeight = 0;
      if (allWeights.length > 10) {
        if (allWeights[9] <= 0.13) {
          preferredWeight = 0.2;
        } else {
          preferredWeight = Math.ceil(allWeights[9] * 100) / 100;
        }
      } else {
        preferredWeight = allWeights[Math.floor(allWeights.length / 2)];
      }
      allSlots.forEach(slot => slot.calculateComparisonWith(preferredWeight));
    }

    this.searching = false;
  }

}

export { SearchStore };
