// import * as tinycolor from 'tinycolor2';

export const GRAY_COLOR = '#666666';
export const LIGHT_GRAY_COLOR = '#BBBBBB';
export const LIGHT_BG_COLOR = '#EFEFEF';
export const GREEN_COLOR = '#7abc78';
export const DARK_GREEN_COLOR = '#4a7846';
export const TEAL_COLOR = '#5da2a3';
export const LIGHT_TEAL_COLOR = '#cfdcdd';
export const DARK_TEAL_COLOR = '#4a8182';
export const BLUE_COLOR = '#4b6f85';
export const BRIGHT_BLUE_COLOR = '#4d8fb8';
export const ORANGE_COLOR = '#ce9941';
export const RED_COLOR = '#ba5f56';
export const LIGHT_RED_COLOR = '#d8a49e';
export const ALERT_RED_COLOR = '#B03A3A';
export const DARK_ALERT_RED_COLOR = '#B03A3A';
export const ALERT_YELLOW_COLOR = '#E9AD48';
export const DARK_ALERT_YELLOW_COLOR = '#8A682B';
export const ALERT_GREEN_COLOR = '#63B036';
export const TEXT_MUTED = '#868686';
export const TEXT_EXTRA_MUTED = '#afafaf';

export const DEFAULT_BRAND_COLOR = '#659933';
