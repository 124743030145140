import gql from "graphql-tag";

export const PrivateLifecycleInfoFragment  = gql`
  fragment PrivateLifecycleInfoFragment on PrivateLifecycle {
    id name
  }
`;

// ### QUERIES ############################################################################################

export const PrivateLifecycleSearchSortedQuery = gql`
  query ($searchStr: String!, $sortBy: [LifecycleSort!], $first: Int, $after: String) {
    allLifecycles(first: $first, search: $searchStr, sortBy: $sortBy, after: $after) {
      totalCount
      nodes {
        ...PrivateLifecycleInfoFragment
      }
      pageInfo {
        startCursor endCursor hasNextPage hasPreviousPage
      }
    }
  }
  ${PrivateLifecycleInfoFragment}
`;
