import gql from "graphql-tag";
import { CoreLocalizationDetailedInfoFragment } from "./localization";
import { CoreCurrencyFragment } from "../core/currency";
import { PrivateJakklopsBillingInvoiceFragment, PrivateJakklopsPricingPlanFragment } from "./billing";

export const PrivateCompanyDetailedInfoFragment = gql`
  fragment PrivateCompanyDetailedInfoFragment on PrivateCompany {
    id name phoneNumber email website urlToken createdAt
    logo { exists url }
    street1 street2 municipality region postalCode latitude longitude
    countryCode countryName isSmartRoutesEnabled areAllRemindersPaused supportedStripePaymentMethods
    isParked parkedReason billablePianoLimit billablePianoCount stripeTrialEndsAt
    billablePianoCount
    upcomingMaintenanceNotice { message startsAt }
    stripeCoupon { couponId amountOff percentOff endsAt }
    gazelleReferral {
      email id name referralToken referredOn status hasBeenPaidOut user { id firstName lastName email }
    }
    billing {
      currency { ...CoreCurrencyFragment }
      pricingModel
      jakklops {
        currentPlan { ...PrivateJakklopsPricingPlanFragment }
        freeTrialEndsOn billingCycleEndDate
      }
    }
    settings {
      client { defaultWantsText }
      locationBias { lat lng radius }
      mapping {
        typeaheadProvider
        geocodeProvider
        routeMapProvider
        interactiveMapProvider
        staticMapProvider
        routePolylineProvider
      }
      invoices {
        defaultInvoiceNotesHeader defaultInvoiceNotes defaultInvoiceTopNotesHeader defaultInvoiceTopNotes defaultInvoiceNetDays defaultInvoicePaymentType nextInvoiceNumber tipsEnabled tipsPublicGuiAutoselect
        defaultUserPaymentOption
      }
      estimates { nextEstimateNumber defaultMonthsExpiresOn defaultNotes sendQuestionsToAllActiveAdmins sendQuestionsToCreator sendQuestionsToEmails }
      general {
        headerLayout hourlyRate clientCustom1Label clientCustom2Label clientCustom3Label clientCustom4Label clientCustom5Label clientCustom6Label clientCustom7Label clientCustom8Label clientCustom9Label clientCustom10Label receiptEmail
      }
      localization {
        defaultCurrency { ...CoreCurrencyFragment }
        defaultUserLocalization { ...CoreLocalizationDetailedInfoFragment }
        defaultClientLocalization { ...CoreLocalizationDetailedInfoFragment }
      }
      sms { isEnabled }
      stripePayments { accountId acceptedPaymentMethods stripePublishableKey availableForCountry defaultAcceptElectronicPayments }
      scheduler { invalidAddressDefaultDriveTime }
      quickbooksOnline { syncGazelleUsersAsQboClasses syncStripePayouts pullPayments syncStartDate allowOnlineCreditCardPayment allowOnlineAchPayment }
      featureToggles {
        schedulerV2Allowed schedulerV2Enabled schedulerV2CanDowngrade
        publicV2Allowed publicV2Enabled
      }
      branding {
        headerLayout maxLogoPx primaryColor showCompanyAddress showCompanyEmail showCompanyPhone privacyPolicy termsOfService
      }
      selfScheduler {
        welcomeMessage noAvailabilityMessage outsideServiceAreaMessage reservationCompleteBehavior
        reservationCompleteMessage selfSchedulerEnabled showCosts technicianSelectionBehavior 
        allowCoordinateLocationType
      }
      legacySelfScheduler {
        outsideServiceAreaMessage selfScheduleSpecialInstructions
        selfScheduleCompletionMessage selfScheduleCompletionRedirect
      }
    }
    logo { url }
    gazelleStripeTransactionFee
  }
  ${CoreCurrencyFragment}
  ${CoreLocalizationDetailedInfoFragment}
  ${PrivateJakklopsPricingPlanFragment}
`;

// ### QUERIES ###########################################################################################

export const PrivateCompanyQuery = gql`
  query {
    company {
      ...PrivateCompanyDetailedInfoFragment
    }
  }
  ${PrivateCompanyDetailedInfoFragment}
`;

export const PrivateCompanyBillingQuery = gql`
  query {
    company {
      billing {
        pricingModel defaultPaymentMethodSummary subscriptionStatus
        currentBalance { amount currency { ...CoreCurrencyFragment } }
        jakklops {
          currentPlan { ...PrivateJakklopsPricingPlanFragment }
          upcomingPlan { ...PrivateJakklopsPricingPlanFragment }
          upcomingInvoice { ...PrivateJakklopsBillingInvoiceFragment }
          discounts { id description endsOn }
          billingCycleEndDate currentPlanWillCancel freeTrialEndsOn
        }
      }
    }
  }
  ${PrivateJakklopsBillingInvoiceFragment}
  ${PrivateJakklopsPricingPlanFragment}
  ${CoreCurrencyFragment}
`;

export const PrivateCompanyBillingProjectedSubscriptionChangeQuery = gql`
  query($activePianoLimit: Int!, $features: [PrivateJakklopsPricingFeatureType!]!, $interval: PricingModelInterval!) {
    company {
      billing {
        jakklops {
          projectedSubscriptionChange(activePianoLimit: $activePianoLimit, features: $features, interval: $interval) {
            amountDueToday
            balanceUsedToday
            newSubscriptionRate
            nextBillingCycleEndAt
          }
        }
      }
    }
  }
`;

// ### MUTATIONS ###########################################################################################

export const PrivateUpdateCompanySettingsMutation = gql`
  mutation ($input: PrivateCompanySettingsInput!) {
    updateCompanySettings(input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      company { ...PrivateCompanyDetailedInfoFragment }
    }
  }
  ${PrivateCompanyDetailedInfoFragment}
`;

export const PrivateUpdateCompanyFeatureTogglesMutation = gql`
  mutation ($input: PrivateCompanyFeatureTogglesInput!) {
    updateCompanyFeatureToggles(input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      company { ...PrivateCompanyDetailedInfoFragment }
    }
  }
  ${PrivateCompanyDetailedInfoFragment}
`;
