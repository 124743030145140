import gql from "graphql-tag";

export const PrivateContactEmailFullInfoFragment = gql`
  fragment PrivateContactEmailFullInfoFragment on PrivateContactEmail {
    __typename id email isDefault emailMd5
  }
`;

// ### MUTATIONS ###########################################################################################

export const PrivateCreateContactEmailMutation = gql`
  mutation ($contactId: String!, $input: PrivateContactEmailInput!) {
    createContactEmail(contactId: $contactId, input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      email { ...PrivateContactEmailFullInfoFragment }
    }
  }
  
  ${PrivateContactEmailFullInfoFragment}
`;

export const PrivateUpdateContactEmailMutation = gql`
  mutation ($id: String!, $input: PrivateContactEmailInput!) {
    updateContactEmail(id: $id, input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      email { ...PrivateContactEmailFullInfoFragment }
    }
  }
  
  ${PrivateContactEmailFullInfoFragment}
`;

export const PrivateDeleteContactEmailMutation = gql`
  mutation ($id: String!) {
    deleteContactEmail(id: $id) {
      mutationErrors { type fieldName key messages errorReferenceId }
      isDeleted
    }
  }
`;
