import gql from "graphql-tag";

export const PrivateEmailHtmlDetailsQuery = gql`
  query ($emailId: String!) {
    email(emailId: $emailId) {
      id status sentAt to recipientName from subject htmlBody
      client { id companyName
        defaultContact {id firstName lastName}
        allContacts {nodes {id firstName lastName allEmails {nodes {email}}}}
      }
    }
  }
`;
