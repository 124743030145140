import { JAKKLOPS_FEATURE, PRICING_INTERVAL } from "shared/enums";
import { PrivateCompanyBillingProjectedSubscriptionChangeQuery } from "../graphql";
import { IProjectedSubscriptionChange } from "shared/interfaces";
import { RootStore } from "shared/stores";

const moment = require('moment-timezone');

export async function getProjectedSubscriptionChange(rootStore: RootStore, pianoLimit: number, features: JAKKLOPS_FEATURE[], interval: PRICING_INTERVAL): Promise<IProjectedSubscriptionChange> {
  console.log(pianoLimit, features, interval);
  const result = await rootStore.apolloClient.query({
    query: PrivateCompanyBillingProjectedSubscriptionChangeQuery,
    variables: {
      activePianoLimit: pianoLimit,
      features,
      interval
    }
  });

  const projectedSubscriptionChange = result.data.company.billing.jakklops.projectedSubscriptionChange;
  return {
    amountDueToday: projectedSubscriptionChange.amountDueToday,
    balanceUsedToday: projectedSubscriptionChange.balanceUsedToday,
    newSubscriptionRate: projectedSubscriptionChange.newSubscriptionRate, // This is in the billing currency
    nextBillingCycleEndAt: moment(projectedSubscriptionChange.nextBillingCycleEndAt)
  };
}
