import { findPolylineCache, IMappingPolyline, pushPolylineCache } from "../polyline";
import axios, { AxiosStatic } from "axios";
import * as Polyline from '@mapbox/polyline';
import { SCHEDULER_TRAVEL_MODE } from "../../enums";
import { ICoordinates } from "shared/interfaces";

interface IAzureMappingPolylineConfig {
  azk: string;
  retryAttempts: number;
  axios?: AxiosStatic;
}

class AzureMappingPolyline implements IMappingPolyline {
  config: IAzureMappingPolylineConfig;

  constructor(config: IAzureMappingPolylineConfig) {
    this.config = config;
  }

  async getPolyline(waypoints: ICoordinates[], travelMode?: SCHEDULER_TRAVEL_MODE): Promise<string> {
    let cache = findPolylineCache(waypoints, travelMode || SCHEDULER_TRAVEL_MODE.DRIVING);
    if (cache) return cache;

    if (waypoints.length < 2) return '';
    if (waypoints.find(point => !point.latitude || !point.longitude)) return "";
    const baseUrl = 'https://atlas.microsoft.com/route/directions/json';

    let mode: string = 'car';
    if (travelMode) {
      switch(travelMode) {
        case SCHEDULER_TRAVEL_MODE.DRIVING:
          mode = 'car';
          break;
        case SCHEDULER_TRAVEL_MODE.WALKING:
          mode = 'pedestrian';
          break;
        case SCHEDULER_TRAVEL_MODE.BICYCLING:
          mode = 'bicycle';
          break;
        // NOTE: Azure doesn't support transit mode
        default:
          mode = 'car';
          break;
      }
    }

    let query = waypoints.map((latlng: ICoordinates) => latlng.latitude.toString() + "," + latlng.longitude.toString()).join(":");
    let params: any = {
      'api-version': '1.0',
      'subscription-key': this.config.azk,
      travelMode: mode,
      routeRepresentation: 'polyline',
      query: query
    };
    let a = this.config.axios ? this.config.axios : axios;
    let response = (await a.get(baseUrl, {params: params}));
    if (response.status === 200) {
      let points: [number, number][] = [];
      // the coordinates
      let route = response.data.routes[0];
      if (route) {
        route.legs.forEach((leg: any) => {
          leg.points.forEach((point: any) => {
            points.push([point.latitude, point.longitude]);
          });
        });
      }
      let polyline = Polyline.encode(points);
      if (points.length > 0 && polyline) pushPolylineCache(waypoints, polyline, travelMode);
      return polyline;
    } else if (response.status === 403) {
      if (this.config.retryAttempts < 3) {
        let amp = new AzureMappingPolyline({azk: this.config.azk, retryAttempts: this.config.retryAttempts + 1});
        return new Promise<string>((resolve) => {
          setTimeout(() => resolve(amp.getPolyline(waypoints)), 2000);
        });
      }
      return null;
    } else {
      return null;
    }
  }
}

export { AzureMappingPolyline };
