import { makeObservable, observable } from 'mobx';
import { LOCATION_TYPE, LOCATION_USAGE_TYPE } from 'shared/enums';
import { LocationAddressPartsType, LocationCoordinatesType } from 'shared/utils';

class ContactLocation implements LocationAddressPartsType, LocationCoordinatesType {
  id: string = null;
  locationType: LOCATION_TYPE = null;
  usageType: LOCATION_USAGE_TYPE = null;
  street1: string = null;
  street2: string = null;
  municipality: string = null;
  region: string = null;
  postalCode: string = null;
  countryCode: string = null;
  latitude: string = null;
  longitude: string = null;

  constructor(attrs: any) {
    makeObservable(this, {
      id: observable,
      locationType: observable,
      usageType: observable,
      street1: observable,
      street2: observable,
      municipality: observable,
      region: observable,
      postalCode: observable,
      countryCode: observable,
      latitude: observable,
      longitude: observable
    });

    this.id = attrs.id;
    this.locationType = attrs.locationType;
    this.usageType = attrs.usageType;
    this.street1 = attrs.street1;
    this.street2 = attrs.street2;
    this.municipality = attrs.municipality;
    this.region = attrs.region;
    this.postalCode = attrs.postalCode;
    this.countryCode = attrs.countryCode;
    this.latitude = attrs.latitude;
    this.longitude = attrs.longitude;
  }
}

const CONTACT_LOCATION_ALL_GQL_QUERY_FIELDS = `
  id locationType usageType
  street1 street2 municipality region postalCode countryCode
  latitude longitude
`;

export { ContactLocation, CONTACT_LOCATION_ALL_GQL_QUERY_FIELDS };
