import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerSlash } from "@fortawesome/pro-solid-svg-icons";
import { IMappingInteractiveMapByAddressProps } from "../interactive_map";
import { MAPPING_LOCATION_TYPE } from "@gazelle/shared/enums";
import { MSG_exactLocationNotFoundShortError, MSG_noMapAvailable } from "@gazelle/shared/strings";
import { formatMessage } from "@gazelle/shared/utils";
import { ICoordinates } from '@gazelle/shared/interfaces';

interface IGoogleInteractiveMapContainerByAddress {
  InteractiveMapContainer: any;
  LoadingIndicator: any;
  geocode: (addressLine: string) => Promise<ICoordinates>;
}

function getGoogleInteractiveMapContainerByAddress(config: IGoogleInteractiveMapContainerByAddress) {
  interface IState {
    isLoading: boolean;
    latitude: string;
    longitude: string;
    mappingLocationType: MAPPING_LOCATION_TYPE;
  }

  class GoogleInteractiveMapContainerByAddress extends React.Component<IMappingInteractiveMapByAddressProps, IState> {
    constructor(props: IMappingInteractiveMapByAddressProps) {
      super(props);
      this.state = {
        isLoading: false,
        latitude: null,
        longitude: null,
        mappingLocationType: null
      };
    }

    async componentDidMount() {
      if (!this.props.addressLine) return;

      this.setState({isLoading: true});

      const coords = await config.geocode(this.props.addressLine);
      if (coords) {
        this.setState({
          isLoading: false,
          latitude: coords.latitude,
          longitude: coords.longitude,
          mappingLocationType: coords.mappingLocationType
        });
      } else {
        this.setState({
          isLoading: false,
          latitude: null,
          longitude: null,
          mappingLocationType: null
        });
      }
    }

    render() {
      const InteractiveMapContainer = config.InteractiveMapContainer;
      const LoadingIndicator = config.LoadingIndicator;

      if (!this.props.addressLine) {
        return (
          <div className="interactive-maps-container d-flex flex-column align-items-center justify-content-center"
               style={{height: this.props.height, width: this.props.width, backgroundColor: "#f4f4f4"}}>
            <h2><FontAwesomeIcon icon={faMapMarkerSlash}/></h2>
            <div className="text-small text-muted">
              {formatMessage(MSG_noMapAvailable)}
            </div>
          </div>
        );
      } else if (this.state.isLoading) {
        return (
          <div className="interactive-maps-container d-flex flex-column align-items-center justify-content-center"
               style={{height: this.props.height, width: this.props.width, backgroundColor: "#f4f4f4"}}>
            <LoadingIndicator/>
          </div>
        );
      } else if (!this.state.latitude || !this.state.longitude) {
        return (
          <div className="interactive-maps-container d-flex flex-column align-items-center justify-content-center"
               style={{height: this.props.height, width: this.props.width, backgroundColor: "#f4f4f4"}}>
            <h2><FontAwesomeIcon icon={faMapMarkerSlash}/></h2>
            <div className="text-small text-muted">
              {formatMessage(MSG_exactLocationNotFoundShortError)}
            </div>
          </div>
        );
      } else {
        return (
          <InteractiveMapContainer
            height={this.props.height}
            width={this.props.width}
            latitude={this.state.latitude}
            longitude={this.state.longitude}
            locationType={this.state.mappingLocationType}/>
        );
      }
    }
  }

  return GoogleInteractiveMapContainerByAddress;
}

export { getGoogleInteractiveMapContainerByAddress };
