import gql from "graphql-tag";
import { PrivateUserCoreInfoFragment } from "./user";

export const PrivateAvailabilityCoreInfoFragment  = gql`
  fragment PrivateAvailabilityCoreInfoFragment on PrivateAvailability {
    user { ...PrivateUserCoreInfoFragment }
    startOn startTime startLocation startLocationLat startLocationLng
    endOn endTime endLocation endLocationLat endLocationLng
  }

  ${PrivateUserCoreInfoFragment}
`;

// *********** QUERIES ***************

export const PrivateAvailabilityInRangeQuery = gql`
  query ($userId: [String!]!, $startOn: CoreDate!, $endOn: CoreDate!) {
    allAvailability(userId: $userId, startOn: $startOn, endOn: $endOn) {
      ...PrivateAvailabilityCoreInfoFragment
    }
  }

  ${PrivateAvailabilityCoreInfoFragment}
`;
