import { formatMessage } from "./intl";
import { LOCATION_USAGE_TYPE } from "../enums";
import { MSG_addressTypeBilling, MSG_addressTypeMailing, MSG_addressTypeStreet } from "../strings";

const SortedLocationUsageTypes = [
  LOCATION_USAGE_TYPE.STREET,
  LOCATION_USAGE_TYPE.MAILING,
  LOCATION_USAGE_TYPE.BILLING,
];

const LocationUsageTypeInfo: {[key: string]: {type: LOCATION_USAGE_TYPE, getLabel: () => string, getLowerLabel: () => string}} = {
  [LOCATION_USAGE_TYPE.STREET]: {
    type: LOCATION_USAGE_TYPE.STREET,
    getLabel: () => formatMessage(MSG_addressTypeStreet),
    getLowerLabel: () => formatMessage(MSG_addressTypeStreet).toLowerCase()
  },
  [LOCATION_USAGE_TYPE.MAILING]: {
    type: LOCATION_USAGE_TYPE.MAILING,
    getLabel: () => formatMessage(MSG_addressTypeMailing),
    getLowerLabel: () => formatMessage(MSG_addressTypeMailing).toLowerCase()
  },
  [LOCATION_USAGE_TYPE.BILLING]: {
    type: LOCATION_USAGE_TYPE.BILLING,
    getLabel: () => formatMessage(MSG_addressTypeBilling),
    getLowerLabel: () => formatMessage(MSG_addressTypeBilling).toLowerCase()
  },
};


export { SortedLocationUsageTypes, LocationUsageTypeInfo };
