import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { IGeocode } from '@core/models';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldCheck } from "@fortawesome/pro-solid-svg-icons";
import {
  MSG_coordinatesTitle,
  MSG_gpsCoordinatesLabel
} from "@gazelle/shared/strings";
import { formatMessage, getLocationTypeName, locationToMultipleLines, LocationType } from "@gazelle/shared/utils";
import { IMappingInteractiveMap } from 'shared-web/mapping';
import { LOCATION_TYPE_NAME } from 'shared/enums';

interface IStreetAddressWithMapProps {
  location: LocationType,
  geocode: IGeocode,
  wide?: boolean,
  interactiveMapProvider: IMappingInteractiveMap
}

class StreetAddressWithMap extends React.Component<IStreetAddressWithMapProps> {
  render() {
    const { location, geocode } = this.props;
    const MapContainer = this.props.interactiveMapProvider.ContainerClass;

    return (
      <Row className="street-address-with-map">
        <Col xs={12} md={this.props.wide ? 6 : 12} lg={6}>
          <div className="icon-container">
            <FontAwesomeIcon icon={faShieldCheck} />
          </div>
          <div className="address-details">
            {getLocationTypeName(location) === LOCATION_TYPE_NAME.COORDINATES ? (
              <div>
                {formatMessage(MSG_coordinatesTitle)}
                :<br/>
                {Math.round(geocode.lat * 100000000) / 100000000}, {Math.round(geocode.lng * 100000000) / 100000000}
              </div>
            ) : (
              <React.Fragment>
                {locationToMultipleLines(location).map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
                <br/>
                {geocode && (
                  <div className="text-small text-muted">
                    {formatMessage(MSG_gpsCoordinatesLabel)}
                    :<br/>
                    {Math.round(geocode.lat * 100000000) / 100000000}, {Math.round(geocode.lng * 100000000) / 100000000}
                  </div>
                )}
              </React.Fragment>
            )}
          </div>
        </Col>
        <Col xs={12} md={this.props.wide ? 6 : 12} lg={6} className={'mt-4 mt-lg-0' + (this.props.wide ? ' mt-md-0' : '')}>
          <div style={{border: '3px solid #fff', borderRadius: '3px'}}>
            <MapContainer latitude={geocode.lat} longitude={geocode.lng} height={200} width="100%" />
          </div>
        </Col>
      </Row>
    );
  }
}

export { StreetAddressWithMap };
