// master_service_group.ts
import { makeObservable, observable } from 'mobx';
import { I18nString, MasterServiceItem } from '@core';

interface IMasterServiceGroup {
  id: string;
  name: I18nString;
  order: number;
  isMultiChoice: boolean;
  allMasterServiceItems: MasterServiceItem[];
}

class MasterServiceGroup implements IMasterServiceGroup {
  id: string = null;
  name: I18nString = null;
  isMultiChoice: boolean = null;
  order: number = null;
  allMasterServiceItems: MasterServiceItem[] = null;

  constructor(attrs: any = {}) {
    makeObservable(this, {
      id: observable,
      name: observable,
      isMultiChoice: observable,
      order: observable,
      allMasterServiceItems: observable,
    });

    this.id = attrs.id;
    this.name = new I18nString(attrs.name);
    this.isMultiChoice = attrs.isMultiChoice;
    this.order = attrs.order;

    if (attrs.allMasterServiceItems) {
      this.allMasterServiceItems = attrs.allMasterServiceItems.map((i: any) => new MasterServiceItem(i));
    } else {
      this.allMasterServiceItems = [];
    }
  }
}

export { MasterServiceGroup, IMasterServiceGroup };
