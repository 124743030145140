import gql from "graphql-tag";
import { PrivatePianoInfoFragment } from "./piano";
import { PrivateContactFullInfoFragment } from "./contact";
import { PrivateInvoiceItemFragment } from "./invoice_item";
import { PrivateContactEmailFullInfoFragment } from "./contact_email";
import { CoreLocalizationDetailedInfoFragment } from "./localization";
import { PrivateContactLocationFullInfoFragment } from "./contact_location";

export const PrivateClientInfoFragment  = gql`
  fragment PrivateClientInfoFragment on PrivateClient {
    id status companyName
    reasonInactiveCode reasonInactiveDetails noContactUntil noContactReason
    preferredTechnicianId createdAt updatedAt tags
  }
`;

export const PrivateClientActivePianosFragment  = gql`
  fragment PrivateClientActivePianosFragment on PrivateClient {
    allPianos(first: 5, status: ACTIVE) {
      totalCount
      pageInfo {
        hasNextPage
      }
      nodes {
        ...PrivatePianoInfoFragment
      }
    }
  }
  ${PrivatePianoInfoFragment}
`;

export const PrivateClientDefaultContactNameFragment  = gql`
  fragment PrivateClientDefaultContactNameFragment on PrivateClient {
    defaultContact {
      id role title firstName middleName lastName suffix isDefault
    }
  }
`;

export const PrivateClientLocalizationInfoFragment = gql`
  fragment PrivateClientLocalizationInfoFragment on PrivateClient {
    localization { ...CoreLocalizationDetailedInfoFragment }
    defaultClientLocalization { ...CoreLocalizationDetailedInfoFragment }
  }
  ${CoreLocalizationDetailedInfoFragment}
`;

export const PrivateClientDefaultLocationFragment  = gql`
  fragment PrivateClientDefaultLocationFragment on PrivateClient {
    defaultContact {
      defaultLocation { ...PrivateContactLocationFullInfoFragment }
    }
  }
  ${PrivateContactLocationFullInfoFragment}
`;

export const PrivateClientDefaultContactInfoFragment  = gql`
  fragment PrivateClientDefaultContactInfoFragment on PrivateClient {
    id
    ...PrivateClientDefaultContactNameFragment
    companyName
    defaultContact {
      id role title firstName middleName lastName suffix isDefault wantsText wantsEmail wantsPhone
      defaultLocation { ...PrivateContactLocationFullInfoFragment }
      defaultEmail {
        id email
      }
      defaultPhone {
        id phoneNumber extension type confirmedClass
        e164: phoneNumber(format: E164)
      }
    }
  }

  ${PrivateClientDefaultContactNameFragment}
  ${PrivateContactLocationFullInfoFragment}
`;

export const PrivateClientDefaultBillingContactInfoFragment  = gql`
  fragment PrivateClientDefaultBillingContactInfoFragment on PrivateClient {
    id status
    ...PrivateClientDefaultContactNameFragment
    companyName
    defaultBillingContact {
      id role title firstName middleName lastName suffix isDefault isBillingDefault
      defaultLocation { ...PrivateContactLocationFullInfoFragment }
      defaultEmail {
        id email
      }
      defaultPhone {
        id phoneNumber extension type confirmedClass
        e164: phoneNumber(format: E164)
      }
    }
  }
  ${PrivateClientDefaultContactNameFragment}
  ${PrivateContactLocationFullInfoFragment}
`;

// Every single field on the PrivateClient type + every single field on the
// PrivateContact types
export const PrivateClientFullInfoFragment = gql`
  fragment PrivateClientFullInfoFragment on PrivateClient {
    id clientType companyName createdAt ignoreSafetyChecks lastResultNotes
    custom1 custom2 custom3 custom4 custom5 custom6 custom7 custom8 custom9 custom10
    lifecycleState noContactReason noContactUntil personalNotes preferenceNotes preferredTechnicianId reasonInactiveCode
    reasonInactiveDetails referenceId referredBy referredByNotes region searchString status updatedAt tags

    lifecycle { id name confWindowCode }
    preferredTechnician { id firstName lastName color }
    defaultBillingContact { ...PrivateContactFullInfoFragment }
    defaultContact { ...PrivateContactFullInfoFragment }
    referralClient { id companyName defaultContact { id firstName middleName lastName defaultLocation { ...PrivateContactLocationFullInfoFragment }} }
    localization { ...CoreLocalizationDetailedInfoFragment }
    defaultClientLocalization { ...CoreLocalizationDetailedInfoFragment }

    allContacts { nodes { ...PrivateContactFullInfoFragment } totalCount }
    allPianos(status: ACTIVE, first: 5) { nodes { ...PrivatePianoInfoFragment } totalCount pageInfo { hasNextPage } }
    allInactivePianos: allPianos(status: [INACTIVE, TEMPORARY_STORAGE, UNDER_RESTORATION], first: 5) { nodes { ...PrivatePianoInfoFragment } totalCount pageInfo { hasNextPage } }
    allClientsReferred { id companyName defaultContact { id firstName middleName lastName defaultLocation { ...PrivateContactLocationFullInfoFragment }} }
  }
  ${CoreLocalizationDetailedInfoFragment}
  ${PrivateContactFullInfoFragment}
  ${PrivatePianoInfoFragment}
  ${PrivateContactLocationFullInfoFragment}
`;

export const PrivateAllContactsAndEmailsFragment = gql`
  fragment PrivateAllContactsAndEmailsFragment on PrivateClient {
    id
    allContacts { nodes {
      id firstName isBillingDefault isDefault lastName middleName role suffix title wantsEmail
      allEmails { nodes {
        ...PrivateContactEmailFullInfoFragment
      }}
    }}
  }
  ${PrivateContactEmailFullInfoFragment}
`;


// ### QUERIES ############################################################################################

export const PrivateClientDetailsQuery = gql`
  query ($id: String) {
    client(id: $id) {
      ...PrivateClientInfoFragment
      ...PrivateClientLocalizationInfoFragment
      ...PrivateClientDefaultContactInfoFragment
      ...PrivateClientActivePianosFragment
    }
  }
  ${PrivateClientInfoFragment}
  ${PrivateClientDefaultContactInfoFragment}
  ${PrivateClientLocalizationInfoFragment}
  ${PrivateClientActivePianosFragment}
`;

export const PrivateClientFullDetailsQuery = gql`
  query ($id: String) {
    client(id: $id) {
      ...PrivateClientFullInfoFragment
      lastInvoice {
        dueOn total status notes allInvoiceItems { edges { node {
          ...PrivateInvoiceItemFragment
        }}}
      }
    }
  }
  ${PrivateClientFullInfoFragment}
  ${PrivateInvoiceItemFragment}
`;

export const PrivateClientDefaultContactInfoQuery = gql`
  query ($id: String) {
    client(id: $id) {
      ...PrivateClientInfoFragment
      ...PrivateClientDefaultContactInfoFragment
    }
  }
  ${PrivateClientInfoFragment}
  ${PrivateClientDefaultContactInfoFragment}
`;

export const PrivateClientSearchQuery = gql`
  query ($filters: PrivateAllClientsFilter) {
    allClients(first: 10, filters: $filters) {
      nodes {
        ...PrivateClientInfoFragment
        ...PrivateClientDefaultContactInfoFragment
        ...PrivateClientDefaultBillingContactInfoFragment
      }
    }
  }
  ${PrivateClientInfoFragment}
  ${PrivateClientDefaultContactInfoFragment}
  ${PrivateClientDefaultBillingContactInfoFragment}
`;

export const PrivateClientSearchSortedQuery = gql`
  query ($filters: PrivateAllClientsFilter, $first: Int, $after: String, $sortBy: [ClientSort!]) {
    allClients(first: $first, filters: $filters, sortBy: $sortBy, after: $after) {
      nodes {
        ...PrivateClientInfoFragment
        ...PrivateClientDefaultContactInfoFragment
        allContacts { nodes { id isDefault isBillingDefault firstName lastName middleName role suffix title } totalCount }
      }
      totalCount
      pageInfo {
        startCursor endCursor hasNextPage hasPreviousPage
      }
    }
  }
  ${PrivateClientInfoFragment}
  ${PrivateClientDefaultContactInfoFragment}
`;

// NOTE: PrivateClientSearchSortedReducedQuery and PrivateClientSearchSortedReducedWithoutLatLngQuery are
//       identical, except that the latter does not query for lat/lng.  This is because querying lat/lng is
//       expensive, so we don't want to do it unless we need it.  The web app client list page does not need
//       lat/lng, so it uses PrivateClientSearchSortedReducedWithoutLatLngQuery.  The mobile app client list
//       screen does need lat/lng, so it uses PrivateClientSearchSortedReducedQuery.
export const PrivateClientSearchSortedReducedQuery = gql`
  query ($filters: PrivateAllClientsFilter, $first: Int, $after: String, $sortBy: [ClientSort!]) {
    allClients(first: $first, filters: $filters, sortBy: $sortBy, after: $after) {
      nodes {
        id status companyName noContactUntil tags
        defaultContact {
          id role title firstName middleName lastName suffix isDefault
          defaultLocation { ...PrivateContactLocationFullInfoFragment }
          defaultEmail { id email }
          defaultPhone { id phoneNumber extension e164: phoneNumber(format: E164) }
        }
        allContacts { nodes { id isDefault isBillingDefault firstName lastName middleName role suffix title } }
      }
      totalCount
      pageInfo { startCursor endCursor hasNextPage hasPreviousPage }
    }
  }
  ${PrivateContactLocationFullInfoFragment}
`;

// Querying Lat/Lng is expensive, so this query is used when we don't need it (web app client list page).
export const PrivateClientSearchSortedReducedWithoutLatLngQuery = gql`
  query ($filters: PrivateAllClientsFilter, $first: Int, $after: String, $sortBy: [ClientSort!]) {
    allClients(first: $first, filters: $filters, sortBy: $sortBy, after: $after) {
      nodes {
        id status companyName noContactUntil tags
        defaultContact {
          id role title firstName middleName lastName suffix isDefault
          defaultLocation { ...PrivateContactLocationFullInfoFragment }
          defaultEmail { id email }
          defaultPhone { id phoneNumber extension e164: phoneNumber(format: E164) }
        }
        allContacts { nodes { id isDefault isBillingDefault firstName lastName middleName role suffix title } }
      }
      totalCount
      pageInfo { startCursor endCursor hasNextPage hasPreviousPage }
    }
  }
  ${PrivateContactLocationFullInfoFragment}
`;

export const PrivateClientWithBillingContactSearchSortedQuery = gql`
  query ($filters: PrivateAllClientsFilter, $sortBy: [ClientSort!], $first: Int, $after: String) {
    allClients(filters: $filters, sortBy: $sortBy, first: $first, after: $after) {
      nodes {
        ...PrivateClientInfoFragment
        ...PrivateClientDefaultContactInfoFragment
        defaultBillingContact { ...PrivateContactFullInfoFragment }
      }
      totalCount
      pageInfo {
        startCursor endCursor hasNextPage hasPreviousPage
      }
    }
  }
  ${PrivateClientInfoFragment}
  ${PrivateClientDefaultContactInfoFragment}
  ${PrivateContactFullInfoFragment}
`;

export const PrivateClientSearchSortedFullDetailsQuery = gql`
  query ($filters: PrivateAllClientsFilter, $sortBy: [ClientSort!], $first: Int, $after: String) {
    allClients(filters: $filters, sortBy: $sortBy, first: $first, after: $after) {
      nodes {
        ...PrivateClientFullInfoFragment
      }
      totalCount
      pageInfo {
        startCursor endCursor hasNextPage hasPreviousPage
      }
    }
  }
  ${PrivateClientFullInfoFragment}
`;

export const PrivateClientWithLastInvoiceSearchSortedQuery = gql`
  query ($filters: PrivateAllClientsFilter, $sortBy: [ClientSort!], $first: Int, $after: String) {
    allClients(filters: $filters, sortBy: $sortBy, first: $first, after: $after) {
      nodes {
        ...PrivateClientInfoFragment
        ...PrivateClientDefaultContactInfoFragment
        defaultBillingContact { ...PrivateContactFullInfoFragment }
        lastInvoice {
          dueOn total status notes allInvoiceItems { edges { node {
            ...PrivateInvoiceItemFragment
          }}}
        }
      }
      totalCount
      pageInfo {
        startCursor endCursor hasNextPage hasPreviousPage
      }
    }
  }
  ${PrivateClientInfoFragment}
  ${PrivateClientDefaultContactInfoFragment}
  ${PrivateContactFullInfoFragment}
  ${PrivateInvoiceItemFragment}
`;

export const PrivateClientPianosQuery = gql`
  query ($id: String) {
    client(id: $id) {
      ...PrivateClientInfoFragment
      ...PrivateClientActivePianosFragment
    }
  }
  ${PrivateClientInfoFragment}
  ${PrivateClientActivePianosFragment}
`;

export const PrivateClientContactEmailsQuery = gql`
  query ($id: String) {
    client(id: $id) {
      ...PrivateAllContactsAndEmailsFragment
    }
  }
  ${PrivateAllContactsAndEmailsFragment}
`;

// ### MUTATIONS ###########################################################################################

export const PrivateCreateClientMutation = gql`
  mutation ($input: PrivateClientInput!, $id: String) {
    createClient(input: $input, id: $id) {
      mutationErrors { type fieldName key messages errorReferenceId }
      client {
        ...PrivateClientInfoFragment
        ...PrivateClientDefaultContactInfoFragment
      }
    }
  }
  ${PrivateClientInfoFragment}
  ${PrivateClientDefaultContactInfoFragment}
`;

// Similar to PrivateCreateClientMutation, but it returns PrivateClientFullInfoFragment instead of PrivateClientInfoFragment
export const PrivateCreateClientFullDetailsMutation = gql`
  mutation ($input: PrivateClientInput!) {
    createClient(input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      client {
        ...PrivateClientFullInfoFragment
      }
    }
  }
  ${PrivateClientFullInfoFragment}
`;

export const PrivateUpdateClientMutation = gql`
  mutation ($id: String!, $input: PrivateClientInput!) {
    updateClient(id: $id, input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      client {
        ...PrivateClientFullInfoFragment
      }
    }
  }
  ${PrivateClientFullInfoFragment}
`;

export const PrivateDeleteClientMutation = gql`
  mutation ($id: String!) {
    deleteClient(id: $id) {
      mutationErrors { fieldName key messages }
      isDeleted
    }
  }
`;
