import { CLIENT_ALL_GQL_QUERY_FIELDS } from './client';
import { INVOICE_ITEM_ALL_GQL_QUERY_FIELDS } from './invoice_item';
import { INVOICE_PAYMENT_ALL_GQL_QUERY_FIELDS } from './invoice_payment';
import { CONTACT_ALL_GQL_QUERY_FIELDS } from "@core/models/contact";

const INVOICE_ALL_GQL_QUERY_FIELDS = `
  id status number dueOn netDays notesHeader notes topNotesHeader topNotes
  total subTotal tipTotal taxTotal paidTotal dueTotal summarize acceptElectronicPayment hasPaymentPending
  altBilling {
    companyName firstName lastName email phoneNumber
    street1 street2 municipality region postalCode countryCode
  }
  client { ${CLIENT_ALL_GQL_QUERY_FIELDS} }
  contact { ${CONTACT_ALL_GQL_QUERY_FIELDS} }
  allInvoiceItems { ${INVOICE_ITEM_ALL_GQL_QUERY_FIELDS} }
  allInvoicePayments {${INVOICE_PAYMENT_ALL_GQL_QUERY_FIELDS}}
`;

export { INVOICE_ALL_GQL_QUERY_FIELDS, INVOICE_ITEM_ALL_GQL_QUERY_FIELDS };
