import gql from "graphql-tag";
import { PrivateUserCoreInfoFragment } from "./user";
import { PrivateInvoiceClientInfoFragment, PrivateInvoiceCoreInfoFragment } from "./invoice";

export const PrivateRemoteAccountingTaxMappingFragment = gql`
  fragment PrivateRemoteAccountingTaxMappingFragment on PrivateRemoteAccountingTaxMapping {
    id type externalTaxId
    taxes { id name rate default }
  }
`;

export const PrivateQuickbooksAccountMappingFragment = gql`
  fragment PrivateQuickbooksAccountMappingFragment on PrivateQuickbooksAccountMapping {
    id type quickbooksAccountId
  }
`;

export const PrivateQuickbooksSyncBatchCoreInfoFragment = gql`
  fragment PrivateQuickbooksSyncBatchCoreInfoFragment on PrivateQuickbooksSyncBatch {
    id
    initiatedBy { ...PrivateUserCoreInfoFragment }
    initiatedAt completedAt status
    batchLevelErrors { title description url type }
    batchLevelNotices { description type }
    syncInvoices {
      totalCount
      nodes {
        invoice {
          ...PrivateInvoiceCoreInfoFragment
          ...PrivateInvoiceClientInfoFragment
          altBillingFirstName altBillingLastName altBillingCompanyName createdBy { id firstName lastName }
          lastQuickbooksSyncAt needsQuickbooksSync
        }
        errors { title description url type }
        status
      }
    }
    syncStripePayouts {
      totalCount
      nodes {
        id status stripePayoutDate refundNotices
        stripePayoutAmount stripePayoutCurrency { code }
        errors { title description url type }
        refundNotices
      }
    }
    syncQuickbooksPayments {
      totalCount
      nodes {
        id status
        errors { title description url type }
      }
    }
  }
  ${PrivateInvoiceCoreInfoFragment}
  ${PrivateInvoiceClientInfoFragment}
  ${PrivateUserCoreInfoFragment}
`;

// ### QUERIES ##############################################################################################

export const PrivateQuickbooksSyncBatchQuery = gql`
  query ($id: String!) {
    quickbooksSyncBatch(id: $id) {
      ...PrivateQuickbooksSyncBatchCoreInfoFragment
    }
  }
  ${PrivateQuickbooksSyncBatchCoreInfoFragment}
`;

export const PrivateQuickbooksCurrentlyRunningBatchQuery = gql`
  query {
    quickbooksSyncCurrentlyRunningBatch {
      ...PrivateQuickbooksSyncBatchCoreInfoFragment
    }
  }
  ${PrivateQuickbooksSyncBatchCoreInfoFragment}
`;

export const PrivateAccountingTaxMappingsQuery = gql`
  query ($type: RemoteAccountingType!) {
    allAccountingTaxMappings(type: $type) {
      ...PrivateRemoteAccountingTaxMappingFragment
    }
  }
  ${PrivateRemoteAccountingTaxMappingFragment}
`;

export const PrivateRemoteAccountingTaxesQuery = gql`
  query ($type: RemoteAccountingType!) {
    allRemoteAccountingTaxes(type: $type) {
      id rate name description
    }
  }
`;

export const PrivateAllRemoteQuickbooksAccountsQuery = gql`
  query {
    allQuickbooksAccounts {
      id name description accountNum accountType accountSubType
    }
  }
`;

export const PrivateQuickbooksAccountMappingsQuery = gql`
  query {
    allQuickbooksAccountMappings {
      ...PrivateQuickbooksAccountMappingFragment
    }
  }
  ${PrivateQuickbooksAccountMappingFragment}
`;

// ### MUTATIONS ############################################################################################

export const PrivateCreateAndRunQuickbooksSyncBatchMutation = gql`
  mutation ($invoiceInput: [PrivateQuickbooksSyncInvoiceInput!]!, $pullPayments: Boolean, $syncStripePayouts: Boolean) {
    createAndRunQuickbooksSyncBatch(invoiceInput: $invoiceInput, pullPayments: $pullPayments, syncStripePayouts: $syncStripePayouts) {
      mutationErrors { type fieldName key messages errorReferenceId }
      batchId
    }
  }
`;

export const PrivateCreateRemoteAccountingTaxMappingMutation = gql`
  mutation ($input: PrivateRemoteAccountingTaxMappingInput!) {
    createRemoteAccountingTaxMapping(input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      remoteAccountingTaxMapping { ...PrivateRemoteAccountingTaxMappingFragment }
    }
  }
  ${PrivateRemoteAccountingTaxMappingFragment}
`;

export const PrivateUpdateRemoteAccountingTaxMappingMutation = gql`
  mutation ($id: String!, $input: PrivateRemoteAccountingTaxMappingInput!) {
    updateRemoteAccountingTaxMapping(id: $id, input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      remoteAccountingTaxMapping { ...PrivateRemoteAccountingTaxMappingFragment }
    }
  }
  ${PrivateRemoteAccountingTaxMappingFragment}
`;

export const PrivateCreateQuickbooksAccountMappingMutation = gql`
  mutation ($input: PrivateQuickbooksAccountMappingInput!) {
    createQuickbooksAccountMapping(input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      quickbooksAccountMapping { ...PrivateQuickbooksAccountMappingFragment }
    }
  }
  ${PrivateQuickbooksAccountMappingFragment}
`;

export const PrivateUpdateQuickbooksAccountMappingMutation = gql`
  mutation ($id: String!, $input: PrivateQuickbooksAccountMappingInput!) {
    updateQuickbooksAccountMapping(id: $id, input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      quickbooksAccountMapping { ...PrivateQuickbooksAccountMappingFragment }
    }
  }
  ${PrivateQuickbooksAccountMappingFragment}
`;

