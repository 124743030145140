import gql from "graphql-tag";
import { CoreCurrencyFragment } from "../core/currency";

export const PrivateJakklopsPricingPlanFragment  = gql`
  fragment PrivateJakklopsPricingPlanFragment on PrivateJakklopsPricingPlan {
    id activePianoLimit amount interval
    currency { ...CoreCurrencyFragment }
    features { id type }
  }
`;

export const PrivateJakklopsBillingInvoiceFragment  = gql`
  fragment PrivateJakklopsBillingInvoiceFragment on PrivateJakklopsBillingInvoice {
    id currency { ...CoreCurrencyFragment }
    discountAmounts { id description amount }
    lines { id description amount }
    nextPaymentAttempt subtotal total amountDue appliedBalance
  }

  ${CoreCurrencyFragment}
`;

export const PrivateJakklopsPricingQuery = gql`
  query($countryCode: String!) {
    pricing {
      jakklops {
        allFeatures { id description name requires type }
        allPlansUrl(countryCode: $countryCode)
        allTiers
      }
    }
  }
`;

export const PrivateChangePlanMutation = gql`
  mutation($id: String!, $paymentMethodId: String) {
    changePlan(id: $id, paymentMethodId: $paymentMethodId) {
      mutationErrors { key fieldName messages type }
      currentPlan { id }
      upcomingPlan { id }
    }
  }
`;

// bitrot: can be removed after September 19, 2022
export const PrivateChooseFutureJakklopsPlanMutation = gql`
  mutation($id: String, $paymentMethodId: String) {
    chooseFutureJakklopsPlan(id: $id, paymentMethodId: $paymentMethodId) {
      mutationErrors { key fieldName messages type }
      success
    }
  }
`;

export const PrivateCancelPlanMutation = gql`
  mutation($input: PrivateCancelBillingPlanInput) {
    cancelPlan(input: $input) {
      mutationErrors { key fieldName messages type }
      success
    }
  }
`;

export const PrivateCancelPendingCancellationMutation = gql`
  mutation {
    cancelPendingCancellation {
      mutationErrors { key fieldName messages type }
      success
    }
  }
`;
