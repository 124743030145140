import gql from "graphql-tag";

// ### MUTATIONS ############################################################################################

export const PrivateGeocodeWithAddressComponentsMutation = gql`
  mutation ($addressLine: String!, $referer: String) {
    addressComponents(addressLine: $addressLine, referer: $referer) {
      mutationErrors {key messages}
      addressComponents { address1 address2 city state zip countryCode }
    }
    geocode(addressLine: $addressLine) {
      mutationErrors {key messages}
      geocodedAddress { lat lng locationType }
    }
  }
`;

export const PrivateGeocodeMutation = gql`
  mutation ($addressLine: String!) {
    geocode(addressLine: $addressLine) {
      geocodedAddress { lat lng locationType }
    }
  }
`;
