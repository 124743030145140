import gql from "graphql-tag";

export const PrivateContactPhoneFullInfoFragment = gql`
  fragment PrivateContactPhoneFullInfoFragment on PrivateContactPhone {
    id confirmedAt confirmedCarrier confirmedClass createdAt extension 
    isDefault type updatedAt phoneNumber 
    e164: phoneNumber(format: E164)
  }
`;

// ### MUTATIONS ###########################################################################################

export const PrivateValidatePhoneNumberMutation = gql`
  mutation ($phoneNumber: String!) {
    validatePhoneNumber(phoneNumber: $phoneNumber) {
      mutationErrors { type fieldName key messages errorReferenceId }
      response { phoneNumber }
    }
  }
`;

export const PrivateCreateContactPhoneMutation = gql`
  mutation ($contactId: String!, $input: PrivateContactPhoneInput!) {
    createContactPhone(contactId: $contactId, input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      phone { ...PrivateContactPhoneFullInfoFragment }
    }
  }
  
  ${PrivateContactPhoneFullInfoFragment}
`;

export const PrivateUpdateContactPhoneMutation = gql`
  mutation ($id: String!, $input: PrivateContactPhoneInput!) {
    updateContactPhone(id: $id, input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      phone { ...PrivateContactPhoneFullInfoFragment }
    }
  }
  
  ${PrivateContactPhoneFullInfoFragment}
`;

export const PrivateDeleteContactPhoneMutation = gql`
  mutation ($id: String!) {
    deleteContactPhone(id: $id) {
      mutationErrors { type fieldName key messages errorReferenceId }
      isDeleted
    }
  }
`;
