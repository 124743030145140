import gql from "graphql-tag";
import { PrivateClientDefaultBillingContactInfoFragment, PrivateClientFullInfoFragment } from "./client";
import { PrivatePianoInfoFragment, PrivatePianoPhotoFragment } from "./piano";
import { PrivateContactFullInfoFragment } from "./contact";
import { PrivateRecommendationCoreInfoFragment } from "./recommendation";

export const PrivateEstimateCoreInfoFragment = gql`
  fragment PrivateEstimateCoreInfoFragment on PrivateEstimate {
    id number notes estimatedOn expiresOn currentPerformanceLevel clientUrl isArchived locale tags
    allEstimateTiers {
      id
    }
    pianoPhoto { ...PrivatePianoPhotoFragment }
    createdBy { id firstName lastName }
    recommendedTierTotal
  }
  ${PrivatePianoPhotoFragment}
`;

export const PrivateEstimateClientInfoFragment = gql`
  fragment PrivateEstimateClientInfoFragment on PrivateEstimate {
    client {
      ...PrivateClientDefaultBillingContactInfoFragment
    }
    piano {
      ...PrivatePianoInfoFragment
      potentialPerformanceLevel
      primaryPianoPhoto { ...PrivatePianoPhotoFragment }
    }
    contact {
      ...PrivateContactFullInfoFragment
    }
  }
  ${PrivateClientDefaultBillingContactInfoFragment}
  ${PrivateContactFullInfoFragment}
  ${PrivatePianoInfoFragment}
  ${PrivatePianoPhotoFragment}
`;

export const PrivateEstimateClientDetailFragment = gql`
  fragment PrivateEstimateClientDetailFragment on PrivateEstimate {
    client {
      ...PrivateClientFullInfoFragment
    }
    piano {
      ...PrivatePianoInfoFragment
      potentialPerformanceLevel
      primaryPianoPhoto { ...PrivatePianoPhotoFragment }
    }
    contact {
      ...PrivateContactFullInfoFragment
    }
  }
  ${PrivateClientFullInfoFragment}
  ${PrivateContactFullInfoFragment}
  ${PrivatePianoInfoFragment}
  ${PrivatePianoPhotoFragment}
`;

export const PrivateEstimateTierItemFragment = gql`
  fragment PrivateEstimateTierItemFragment on PrivateEstimateTierItem {
    id name sequenceNumber description educationDescription quantity amount duration type externalUrl isTaxable isTuning subtotal taxTotal total
    masterServiceItem {id name description }
    taxes {id taxId name rate total}
    allEstimateTierItemPhotos {id sequenceNumber pianoPhoto { ...PrivatePianoPhotoFragment }}
    estimateTierGroup {id}
  }
  ${PrivatePianoPhotoFragment}
`;

export const PrivateEstimateDetailsFragment = gql`
  fragment PrivateEstimateDetailsFragment on PrivateEstimate {
    ...PrivateEstimateCoreInfoFragment
    ...PrivateEstimateClientDetailFragment

    allEstimateTiers {
      id sequenceNumber isPrimary allowSelfSchedule notes targetPerformanceLevel
      recommendation {
        ...PrivateRecommendationCoreInfoFragment
      }
      allEstimateTierGroups {
        id name sequenceNumber
        allEstimateTierItems {
          ...PrivateEstimateTierItemFragment
        }
      }
      allUngroupedEstimateTierItems {
        ...PrivateEstimateTierItemFragment
      }
    }
  }
  ${PrivateEstimateCoreInfoFragment}
  ${PrivateEstimateClientDetailFragment}
  ${PrivateEstimateTierItemFragment}
  ${PrivateRecommendationCoreInfoFragment}
`;


// ### QUERIES ############################################################################################

export const PrivateEstimateQuery = gql`
  query ($id: String!) {
    estimate(id: $id) {
      ...PrivateEstimateDetailsFragment
    }
  }
  ${PrivateEstimateDetailsFragment}
`;

export const PrivateEstimatesForClientQuery = gql`
  query ($filters: PrivateAllEstimatesFilter, $sortBy: [EstimateSort!], $first: Int, $after: String) {
    allEstimates(filters: $filters, sortBy: $sortBy, first: $first, after: $after) {
      nodes {
        ...PrivateEstimateDetailsFragment
      }
      pageInfo {
        startCursor endCursor hasNextPage hasPreviousPage
      }
    }
  }
  ${PrivateEstimateDetailsFragment}
`;

export const PrivateEstimateSearchQuery = gql`
  query ($filters: PrivateAllEstimatesFilter, $sortBy: [EstimateSort!], $first: Int, $after: String) {
    allEstimates(filters: $filters, sortBy: $sortBy, first: $first, after: $after) {
      nodes {
        ...PrivateEstimateCoreInfoFragment
        ...PrivateEstimateClientInfoFragment

        allEstimateTiers {
          id isPrimary
          recommendation { id name type }

          allEstimateTierGroups {
            id
            allEstimateTierItems { id subtotal taxTotal total}
          }

          allUngroupedEstimateTierItems { id subtotal taxTotal total}
        }
      }
      totalCount
      pageInfo {
        startCursor endCursor hasNextPage hasPreviousPage
      }
    }
  }
  ${PrivateEstimateClientInfoFragment}
  ${PrivateEstimateCoreInfoFragment}
`;


// ### MUTATIONS ############################################################################################
export const PrivateCreateEstimateMutation = gql`
  mutation ($input: PrivateCreateEstimateInput!) {
    createEstimate(input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      estimate { ...PrivateEstimateDetailsFragment }
    }
  }
  ${PrivateEstimateDetailsFragment}
`;

export const PrivateUpdateEstimateMutation = gql`
  mutation ($id: String!, $input: PrivateUpdateEstimateInput!) {
    updateEstimate(id: $id, input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      estimate { ...PrivateEstimateDetailsFragment }
    }
  }
  ${PrivateEstimateDetailsFragment}
`;

export const PrivateDeleteEstimateMutation = gql`
  mutation ($id: String!) {
    deleteEstimate(id: $id) {
      mutationErrors { fieldName key messages type errorReferenceId }
      isDeleted
    }
  }
`;

export const PrivateSendEstimateEmailMutation = gql`
  mutation ($estimateId: String!, $sendToContactEmailIds: [String!], $attachPdf: Boolean!) {
    sendEstimateEmail(estimateId: $estimateId, sendToContactEmailIds: $sendToContactEmailIds, attachPdf: $attachPdf) {
      mutationErrors { fieldName key messages }
      success message
    }
  }
`;
