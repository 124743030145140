import { ContactLocation, EventLocation, NewClientInfo, SchedulerV2Coordinates, SchedulerV2Location } from "../models";
import { ADDRESS_DISPLAY_TEMPLATE_TYPE, EVENT_LOCATION_TYPE, LOCATION_TYPE, LOCATION_TYPE_NAME, SCHEDULER_LOCATION_TYPE } from "../enums";
import { flatten, pick } from "lodash";
import { MSG_unknownAddressLabel } from "../strings";
import { formatMessage } from "./intl";

export type LocationAddressSingleLineType = {
  addressLine: string | null;
};

export type LocationAddressPartsType = {
  street1: string | null;
  street2: string | null;
  municipality: string | null;
  region: string | null;
  postalCode: string | null;
  countryCode: string | null;
};

export type LocationCoordinatesType = {
  latitude: string | null;
  longitude: string | null;
};

export type LocationType = LocationAddressSingleLineType | LocationAddressPartsType | LocationCoordinatesType;

export function getLocationTypeName(location: LocationType): LOCATION_TYPE_NAME | null {
  const loc: any = location;
  if (!location) return null;
  if (loc.addressLine) {
    return LOCATION_TYPE_NAME.ADDRESS_SINGLE_LINE;
  } else if (loc.street1 || loc.street2 || loc.municipality || loc.region || loc.postalCode) {
    return LOCATION_TYPE_NAME.ADDRESS_PARTS;
  } else if (loc.latitude || loc.longitude) {
    return LOCATION_TYPE_NAME.COORDINATES;
  } else {
    return null;
  }
}

export function convertToLocationType(src: ContactLocation | EventLocation | SchedulerV2Location | NewClientInfo | LocationType): LocationType {
  if (src instanceof SchedulerV2Location) {
    if (src.type === SCHEDULER_LOCATION_TYPE.ADDRESS) {
      return {
        addressLine: src.addressLine
      };
    } else if (src.type === SCHEDULER_LOCATION_TYPE.COORDINATES) {
      return {
        latitude: src.coordinates.latitude?.toString(),
        longitude: src.coordinates.longitude?.toString()
      };
    } else {
      return {
        addressLine: null
      };
    }
  } else if (src instanceof NewClientInfo) {
    return convertToLocationType(src.primaryLocation);
  } else if (src instanceof ContactLocation) {
    if (src.locationType === LOCATION_TYPE.ADDRESS) {
      return {
        street1: src.street1,
        street2: src.street2,
        municipality: src.municipality,
        region: src.region,
        postalCode: src.postalCode,
        countryCode: src.countryCode
      };
    } else if (src.locationType === LOCATION_TYPE.COORDINATES) {
      return {
        latitude: src.latitude,
        longitude: src.longitude
      };
    } else {
      return {
        street1: null,
        street2: null,
        municipality: null,
        region: null,
        postalCode: null,
        countryCode: null
      };
    }
  } else {
    return src as LocationType;
  }
}

export function convertToContactLocation(src: LocationType): ContactLocation {
  if (getLocationTypeName(src) === LOCATION_TYPE_NAME.ADDRESS_SINGLE_LINE) {
    return new ContactLocation({
      locationType: LOCATION_TYPE.ADDRESS,
      ...pick(src, ['addressLine'])
    });
  } else if (getLocationTypeName(src) === LOCATION_TYPE_NAME.ADDRESS_PARTS) {
    return new ContactLocation({
      locationType: LOCATION_TYPE.ADDRESS,
      ...pick(src, ['street1', 'street2', 'municipality', 'region', 'postalCode'])
    });
  } else if (getLocationTypeName(src) === LOCATION_TYPE_NAME.COORDINATES) {
    return new ContactLocation({
      locationType: LOCATION_TYPE.COORDINATES,
      ...pick(src, ['latitude', 'longitude'])
    });
  } else {
    return new ContactLocation();
  }
}

export function convertToEventLocation(src: LocationType): EventLocation {
  if (getLocationTypeName(src) === LOCATION_TYPE_NAME.ADDRESS_SINGLE_LINE) {
    return new EventLocation({
      locationType: EVENT_LOCATION_TYPE.SINGLE_LINE_ADDRESS,
      ...pick(src, ['addressLine'])
    });
  } else if (getLocationTypeName(src) === LOCATION_TYPE_NAME.ADDRESS_PARTS) {
    return new EventLocation({
      locationType: EVENT_LOCATION_TYPE.ADDRESS,
      ...pick(src, ['street1', 'street2', 'municipality', 'region', 'postalCode'])
    });
  } else if (getLocationTypeName(src) === LOCATION_TYPE_NAME.COORDINATES) {
    return new EventLocation({
      locationType: EVENT_LOCATION_TYPE.COORDINATES,
      ...pick(src, ['latitude', 'longitude'])
    });
  } else {
    return new EventLocation();
  }
}

export function convertToSchedulerV2Location(src: LocationType): SchedulerV2Location {
  if (getLocationTypeName(src) === LOCATION_TYPE_NAME.ADDRESS_SINGLE_LINE) {
    return new SchedulerV2Location({
      type: SCHEDULER_LOCATION_TYPE.ADDRESS,
      ...pick(src, ['addressLine'])
    });
  } else if (getLocationTypeName(src) === LOCATION_TYPE_NAME.ADDRESS_PARTS) {
    return new SchedulerV2Location({
      type: SCHEDULER_LOCATION_TYPE.ADDRESS,
      addressLine: locationToSingleLine(src),
    });
  } else if (getLocationTypeName(src) === LOCATION_TYPE_NAME.COORDINATES) {
    return new SchedulerV2Location({
      type: SCHEDULER_LOCATION_TYPE.COORDINATES,
      coordinates: new SchedulerV2Coordinates({
        ...pick(src, ['latitude', 'longitude'])
      })
    });
  }
}

export function locationToSingleLine(location: LocationType, options?: {unknownFallback?: boolean, only?: LOCATION_TYPE | LOCATION_TYPE[], customFallback?: () => any}): string {
  let line: string = '';
  let only: LOCATION_TYPE[] = options?.only ? flatten([options.only]) : null;
  if (location) {
    if (getLocationTypeName(location) === LOCATION_TYPE_NAME.ADDRESS_SINGLE_LINE) {
      line = (location as LocationAddressSingleLineType).addressLine || '';
      if (only && !only.includes(LOCATION_TYPE.ADDRESS)) line = '';
    } else if (getLocationTypeName(location) === LOCATION_TYPE_NAME.ADDRESS_PARTS) {
      const parts = location as LocationAddressPartsType;
      line = renderDisplayTemplate(parts, ADDRESS_DISPLAY_TEMPLATE_TYPE.SINGLE_LINE);
      if (only && !only.includes(LOCATION_TYPE.ADDRESS)) line = '';
    } else if (getLocationTypeName(location) === LOCATION_TYPE_NAME.COORDINATES) {
      line = `${(location as LocationCoordinatesType).latitude}, ${(location as LocationCoordinatesType).longitude}`;
      if (only && !only.includes(LOCATION_TYPE.COORDINATES)) line = '';
    } else {
      line = '';
    }
  }

  if (!line) {
    if (options?.unknownFallback) {
      line = formatMessage(MSG_unknownAddressLabel);
    } else if (options?.customFallback) {
      line = options.customFallback();
    }
  }

  return line;
}

export function locationToMultipleLines(location: LocationType, options?: {unknownFallback?: boolean, only?: LOCATION_TYPE | LOCATION_TYPE[], customFallback?: () => any[]}): string[] {
  let lines: string[] = [];
  let only: LOCATION_TYPE[] = options?.only ? flatten([options.only]) : null;
  if (location) {
    if (getLocationTypeName(location) === LOCATION_TYPE_NAME.ADDRESS_SINGLE_LINE) {
      lines = [(location as LocationAddressSingleLineType).addressLine];
      if (only && !only.includes(LOCATION_TYPE.ADDRESS)) lines = [];
    } else if (getLocationTypeName(location) === LOCATION_TYPE_NAME.ADDRESS_PARTS) {
      const parts = location as LocationAddressPartsType;
      lines = renderDisplayTemplate(parts, ADDRESS_DISPLAY_TEMPLATE_TYPE.MULTI_LINE).split("\n");
      if (only && !only.includes(LOCATION_TYPE.ADDRESS)) lines = [];
    } else if (getLocationTypeName(location) === LOCATION_TYPE_NAME.COORDINATES) {
      lines = [`${(location as LocationCoordinatesType).latitude}, ${(location as LocationCoordinatesType).longitude}`];
      if (only && !only.includes(LOCATION_TYPE.COORDINATES)) lines = [];
    } else {
      lines = [];
    }
  }

  if (lines.length === 0) {
    if (options?.unknownFallback) {
      lines = [formatMessage(MSG_unknownAddressLabel)];
    } else if (options?.customFallback) {
      lines = options.customFallback();
    }
  }

  return lines;
}

export function locationToSummaryLine(location: LocationType, options?: {unknownFallback?: boolean, only?: LOCATION_TYPE | LOCATION_TYPE[], customFallback?: () => any}): string {
  let line: string = '';
  let only: LOCATION_TYPE[] = options?.only ? flatten([options.only]) : null;
  if (location) {
    if (getLocationTypeName(location) === LOCATION_TYPE_NAME.ADDRESS_SINGLE_LINE) {
      line = (location as LocationAddressSingleLineType).addressLine || '';
      if (only && !only.includes(LOCATION_TYPE.ADDRESS)) line = '';
    } else if (getLocationTypeName(location) === LOCATION_TYPE_NAME.ADDRESS_PARTS) {
      const parts = location as LocationAddressPartsType;
      line = renderDisplayTemplate(parts, ADDRESS_DISPLAY_TEMPLATE_TYPE.SUMMARY_LINE);
      if (only && !only.includes(LOCATION_TYPE.ADDRESS)) line = '';
    } else if (getLocationTypeName(location) === LOCATION_TYPE_NAME.COORDINATES) {
      line = `${(location as LocationCoordinatesType).latitude}, ${(location as LocationCoordinatesType).longitude}`;
      if (only && !only.includes(LOCATION_TYPE.COORDINATES)) line = '';
    } else {
      line = '';
    }
  }

  if (!line) {
    if (options?.unknownFallback && !line) {
      line = formatMessage(MSG_unknownAddressLabel);
    } else if (options?.customFallback) {
      line = options.customFallback();
    }
  }

  return line;
}

// An "address" type can either be single or multi line.  We need to know if the type has changed, but for
// this purpose, single or multi line are essentially the same.  So we'll convert single line to multi line
// type name for the comparison.
export function convertLocationTypeNameToLocationType(locationTypeName: LOCATION_TYPE_NAME): LOCATION_TYPE {
  if (locationTypeName === LOCATION_TYPE_NAME.COORDINATES) return LOCATION_TYPE.COORDINATES;
  if (locationTypeName === LOCATION_TYPE_NAME.WHAT3WORDS) return LOCATION_TYPE.WHAT3WORDS;
  return LOCATION_TYPE.ADDRESS;
}

export function isBlankLocation(location: LocationType): boolean {
  if (!location) return true;
  return isBlankAddressLocation(location) && isBlankCoordinatesLocation(location);
}

export function isBlankAddressLocation(location: LocationType): boolean {
  if (!location) return true;
  const parts = location as LocationAddressPartsType;
  return !(location as LocationAddressSingleLineType).addressLine
    && !parts.street1 && !parts.street2 && !parts.municipality && !parts.region && !parts.postalCode;
}

export function isBlankCoordinatesLocation(location: LocationType): boolean {
  if (!location) return true;
  const coords = location as LocationCoordinatesType;
  return !coords.latitude && !coords.longitude;
}

let companyAddressDisplayTemplates: {[countryCode: string]: { singleLine: string, multiLine: string, summaryLine: string }} = {};
let companyDefaultCountryCode: string = 'US'; // Fallback to US if we don't have any templates.

// This method should be called by the rootStore when it refreshes the bootstrap data and gets the address display
// templates and company model.
export function setAddressDisplayTemplates(countryTemplates: [{countryCode: string, singleLine: string, multiLine: string, summaryLine: string }], defaultCountryCode: string) {
  countryTemplates.forEach(countryTemplate => {
    companyAddressDisplayTemplates[countryTemplate.countryCode] = {
      singleLine: countryTemplate.singleLine,
      multiLine: countryTemplate.multiLine,
      summaryLine: countryTemplate.summaryLine
    };
  });
  companyDefaultCountryCode = defaultCountryCode;
}

function renderDisplayTemplate(location: LocationType, templateType: ADDRESS_DISPLAY_TEMPLATE_TYPE): string {
  const outputLines: string[] = [];

  let countryCode = (location as LocationAddressPartsType)?.countryCode || companyDefaultCountryCode;
  if (!countryCode) countryCode = 'US';

  let displayTemplates = companyAddressDisplayTemplates[countryCode];
  if (!displayTemplates) displayTemplates = companyAddressDisplayTemplates[countryCode];

  let templateStr: string | null;
  switch (templateType) {
    case ADDRESS_DISPLAY_TEMPLATE_TYPE.SINGLE_LINE:
      templateStr = displayTemplates?.singleLine;
      break;
    case ADDRESS_DISPLAY_TEMPLATE_TYPE.MULTI_LINE:
      templateStr = displayTemplates?.multiLine;
      break;
    case ADDRESS_DISPLAY_TEMPLATE_TYPE.SUMMARY_LINE:
      templateStr = displayTemplates?.summaryLine;
      break;
    default:
      templateStr = null;
  }
  if (!templateStr) return '';

  templateStr.split("\n").forEach(templateLineStr => {
    const templateLine = JSON.parse(templateLineStr);
    const outputLine: string[] = [];

    templateLine.forEach((element: { before?: string, component: string }) => {
      // In the template string that the server returns, the component will be snake cased.
      // But here in Typescript when referencing the location object, it will be camel cased.
      const camelCasedComponent = element.component.replace(/_([a-z])/g, (g) => g[1].toUpperCase());

      if (element.before && element.before.length > 0 && outputLine.length > 0 && (location as any)[camelCasedComponent]) {
        outputLine.push(element.before);
      }
      const renderedElement = (location as any)[camelCasedComponent];
      if (renderedElement) {
        outputLine.push(renderedElement);
      }
    });

    if (outputLine.length > 0) {
      outputLines.push(outputLine.join(''));
    }
  });

  return outputLines.filter(line => line.trim().length > 0).join("\n").trim();
}

// This takes an address display template and returns a list of lists of the components in the order
// that they should be displayed in a form for inputting addresses.
type AddressDisplayTemplateFormPartsType = 'street1' | 'street2' | 'municipality' | 'region' | 'postalCode';
export function getAddressDisplayTemplateFormLayout(countryCode?: string): AddressDisplayTemplateFormPartsType[][] {
  let displayTemplates = companyAddressDisplayTemplates[countryCode];
  if (!displayTemplates) displayTemplates = companyAddressDisplayTemplates[companyDefaultCountryCode];
  if (!displayTemplates) return [];

  return displayTemplates.multiLine
    .split("\n")
    .map(line => JSON.parse(line)
                     .map((part: any) => part.component ? part.component?.replace(/_([a-z])/g, (g: string) => g[1].toUpperCase()) : null)
                     .filter((str: string) => str !== null));
}
