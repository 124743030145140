import {
  IMappingStaticMap,
  IMappingStaticMapAddress,
  IMappingStaticMapCircle,
  IMappingStaticMapOptions,
  IMappingStaticMapPoint,
  IMappingStaticMapPolygon
} from "../static_map";
import { IMappingPolyline } from '../polyline';
import { googleSignUrl, googleUrlEncode } from './google_tools';
import { ALERT_GREEN_COLOR } from "../../colors";

const LETTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

interface IGoogleMappingStaticMapConfig {
  gsk: string;
  gak: string;
  mappingPolylineProvider: IMappingPolyline,
}

class GoogleMappingStaticMap implements IMappingStaticMap {
  config: IGoogleMappingStaticMapConfig;

  constructor(config: IGoogleMappingStaticMapConfig) {
    this.config = config;
  }

  MAP_BACKGROUND_COLOR = "#f4f4f4";

  async getRouteMapUrl(route: IMappingStaticMapPoint[], options: IMappingStaticMapOptions): Promise<string> {
    let params: string[] = [];
    let encPath = googleUrlEncode(await this.config.mappingPolylineProvider.getPolyline(route));

    params.push(`key=${this.config.gak}`);
    params.push(`size=${options.width}x${options.height}`);
    params.push(`path=enc:${encPath}`);
    params.push('scale=2');
    params.push('style=visibility:simplified');
    route.forEach((point, i) => {
      if (!point.latitude || !point.longitude) return;

      let markerParams = [];
      let color = point.color || ALERT_GREEN_COLOR;
      if (color.match(/^#?[a-f0-9]{6}$/i)) color = `0x${color.replace(/^#/, '')}`;

      markerParams.push(`color:${color}`);
      markerParams.push(`label:${LETTERS[i]}`);
      markerParams.push([point.latitude, point.longitude].join(','));

      params.push(`markers=${markerParams.join("%7C")}`);
    });

    let unsignedPath = "/maps/api/staticmap?" + params.join("&");
    return `https://maps.googleapis.com${unsignedPath}&signature=${googleSignUrl(unsignedPath, this.config.gsk)}`;
  }

  getServiceAreaMapUrl(shapes: (IMappingStaticMapPolygon | IMappingStaticMapCircle)[], options: IMappingStaticMapOptions): string {
    let params: string[] = [];

    params.push(`key=${this.config.gak}`);
    params.push(`size=${options.width}x${options.height}`);
    params.push('scale=2');
    params.push('style=visibility:simplified');
    shapes.forEach((shape, i) => {
      let pathParams = [];

      if ('radiusInMeters' in shape) {
        let color = shape.color || ALERT_GREEN_COLOR;
        if (color.match(/^#?[a-f0-9]{6}$/i)) color = `0x${color.replace(/^#/, '')}`;
        pathParams.push(`fillcolor:${color}`);
        pathParams.push(`color:${color}`);
        pathParams.push(`weight:2`);
        pathParams.push(drawCirclePath(parseFloat(shape.latitude), parseFloat(shape.longitude), shape.radiusInMeters).map((point) => {
          return `${point[0]},${point[1]}`;
        }).join("%7C"));
      } else if ('points' in shape) {
        let color = shape.color || ALERT_GREEN_COLOR;
        if (color.match(/^#?[a-f0-9]{6}$/i)) color = `0x${color.replace(/^#/, '')}`;
        pathParams.push(`fillcolor:${color}`);
        pathParams.push(`color:${color}`);
        pathParams.push(`weight:2`);
        pathParams.push(shape.points.map((point) => {
          return `${point.latitude},${point.longitude}`;
        }).join("%7C"));
      }

      params.push(`path=${pathParams.join("%7C")}`);
    });

    let unsignedPath = "/maps/api/staticmap?" + params.join("&");
    return `https://maps.googleapis.com${unsignedPath}&signature=${googleSignUrl(unsignedPath, this.config.gsk)}`;
  }

  getMarkerMapUrl(point: IMappingStaticMapPoint | IMappingStaticMapAddress, options: IMappingStaticMapOptions): string {
    let params: string[] = [];

    params.push(`key=${this.config.gak}`);
    params.push(`size=${options.width}x${options.height}`);
    let color = point.color || ALERT_GREEN_COLOR;
    if (color.match(/^#?[a-f0-9]{6}$/i)) color = `0x${color.replace(/^#/, '')}`;
    if ('addressLine' in point) {
      params.push(`center=${googleUrlEncode(point.addressLine)}`);
      params.push(`zoom=8`);
      params.push(`markers=color:${color}%7C${googleUrlEncode(point.addressLine)}`);
    } else {
      params.push(`markers=color:${color}%7C${point.latitude},${point.longitude}`);
    }
    params.push('scale=2');
    params.push('style=visibility:simplified');

    let unsignedPath = "/maps/api/staticmap?" + params.join("&");
    return `https://maps.googleapis.com${unsignedPath}&signature=${googleSignUrl(unsignedPath, this.config.gsk)}`;
  }
}

function drawCirclePath(lat: number, lng: number, radiusInMeters: number, detail = 8): number[][] {
  let R = 6371 * 1000;

  let pi = Math.PI;

  lat = (lat * pi) / 180;
  lng = (lng * pi) / 180;
  let d = radiusInMeters / R;

  let points: any = [];
  let i = 0;

  for (i = 0; i <= 360; i += detail) {
    let brng = (i * pi) / 180;

    let plat = Math.asin(
      Math.sin(lat) * Math.cos(d) +
      Math.cos(lat) * Math.sin(d) * Math.cos(brng)
    );
    let plng =
      ((lng +
          Math.atan2(
            Math.sin(brng) * Math.sin(d) * Math.cos(lat),
            Math.cos(d) - Math.sin(lat) * Math.sin(plat)
          )) *
        180) /
      pi;
    plat = (plat * 180) / pi;

    let currentPoints: any = [plat, plng];
    points.push(currentPoints);
  }

  return points;
}


export { GoogleMappingStaticMap };
