import { Geocode } from "@core";
import { IStoreOptions, RootStore } from "../root/root_store";
import { computed, makeObservable, observable } from "mobx";
import { LocationAddressPartsType, LocationCoordinatesType, LocationType } from "shared/utils";
import { LOCATION_TYPE } from "shared/enums";

class LocationStore {
  private rootStore: RootStore = null;

  isInitialized: boolean = false;
  isVerifying: boolean = false;
  addressLine: string = '';
  locationType: LOCATION_TYPE | null = LOCATION_TYPE.ADDRESS;
  location: LocationType = {
    street1: null,
    street2: null,
    municipality: null,
    region: null,
    postalCode: null,
    countryCode: null,
    latitude: null,
    longitude: null
  };
  geocode: Geocode = new Geocode({});
  errorMessage: string = null;

  constructor() {
    makeObservable<LocationStore, "rootStore">(this, {
      rootStore: observable,
      isInitialized: observable,
      isVerifying: observable,
      addressLine: observable,
      locationType: observable,
      location: observable,
      geocode: observable,
      errorMessage: observable,
      verifiedAddress: computed,
    });
  }

  initializeData(rootStore: RootStore, options: IStoreOptions) {
    this.rootStore = rootStore;

    if (options.useTestData) {
      this.addressLine = '1295 Amber Ridge Rd NW, Concord, NC, United States';
      this.isVerifying = false;
      this.locationType = LOCATION_TYPE.ADDRESS;
      this.location = {
        street1: '1295 Amber Ridge Rd NW',
        street2: null,
        municipality: 'Concord',
        region: 'NC',
        postalCode: '28027',
        countryCode: 'US',
      };
      this.geocode = new Geocode({lat: 35.4298145, lng: -80.737666});
    }
  }

  get verifiedAddress(): boolean {
    if (this.locationType === LOCATION_TYPE.COORDINATES) {
      return !!(
        !this.isVerifying
        && this.rootStore
        && this.location
        && (this.location as LocationCoordinatesType).latitude && (this.location as LocationCoordinatesType).longitude);
    } else {
      return !!(!this.isVerifying
        && this.rootStore
        && this.location
        && (
          (this.location as LocationAddressPartsType).street1
          || (this.location as LocationAddressPartsType).street2
          || (this.location as LocationAddressPartsType).municipality
          || (this.location as LocationAddressPartsType).region
          || (this.location as LocationAddressPartsType).postalCode
        )
        && this.geocode.lat
        && this.geocode.lng);
    }
  }

  get isComplete(): boolean {
    return this.verifiedAddress;
  }
}

export { LocationStore };