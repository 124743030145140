import gql from "graphql-tag";

export const PrivateScheduledMessageTemplateFragment = gql`
  fragment PrivateScheduledMessageTemplateFragment on PrivateScheduledMessageTemplate {
    id type name subject template order
  }
`;

// ### QUERIES ############################################################################################

export const PrivateScheduledMessageTemplatesQuery = gql`
  query {
    allScheduledMessageTemplates(first: 500) {
      nodes {
        ...PrivateScheduledMessageTemplateFragment
      }
      pageInfo {
        startCursor endCursor hasNextPage hasPreviousPage
      }
    }
  }

  ${PrivateScheduledMessageTemplateFragment}
`;

// ### MUTATIONS ###########################################################################################

export const PrivateUpdateScheduledMessageTemplateMutation = gql`
  mutation ($input: PrivateScheduledMessageTemplateInput! $id: String!, $updateScheduledMessages: Boolean) {
    updateScheduledMessageTemplate(input: $input, id: $id, updateScheduledMessages: $updateScheduledMessages) {
      mutationErrors { key fieldName messages errorReferenceId type }
      scheduledMessageTemplate {
        ...PrivateScheduledMessageTemplateFragment
      }
    }
  }

  ${PrivateScheduledMessageTemplateFragment}
`;

export const PrivateCreateScheduledMessageTemplateMutation = gql`
  mutation ($input: PrivateScheduledMessageTemplateInput!) {
    createScheduledMessageTemplate(input: $input) {
      mutationErrors { key fieldName messages errorReferenceId type }
      scheduledMessageTemplate {
        ...PrivateScheduledMessageTemplateFragment
      }
    }
  }

  ${PrivateScheduledMessageTemplateFragment}
`;

export const PrivateDeleteScheduledMessageTemplateMutation = gql`
  mutation ($id: String!) {
    deleteScheduledMessageTemplate(id: $id) {
      mutationErrors { fieldName key messages errorReferenceId type }
      isDeleted
    }
  }
`;
