import * as React from "react";
import 'moment-timezone';
import { initializeRootStore, RootLayout } from "./modules/root";
import { actionLogger, getUrlPart, setLoggedActionContext } from '@core';
import './styles.scss';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { Observer } from "mobx-react";
import { IntlProvider } from "react-intl";
import { getLocaleStoreInstance } from "shared/utils";
import { createRoot } from "react-dom/client";

console.log(React.version); // need to reference React so the import does not get removed by our editors

if (typeof SENTRY_CONFIG !== 'undefined') {
  Sentry.init({
    dsn: 'https://d02cfab5d20e49ccb886f62bc384d1c4@o128016.ingest.sentry.io/282566',
    release: SENTRY_CONFIG.release,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

setLoggedActionContext('client_scheduler', getUrlPart(1), {clientId: getUrlPart(2)});
initializeRootStore();
actionLogger.debug('pageLoad');

const container = document.getElementById('root');
const root = createRoot(container);
root.render((
  <Observer>
    {() => {
      /* just using en for the locale here because we are doing our own locale formattings */
      return (
        <IntlProvider key={getLocaleStoreInstance().localization.locale}
                      locale={'en'/* we are doing our own localization, so just hard code this */}
                      messages={getLocaleStoreInstance().getMessages()}>
          <RootLayout/>
        </IntlProvider>
      );
    }}
  </Observer>
));

