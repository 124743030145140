export const MSG_addressInputVerifiedMapMessage = {
  id: "597ef49f-4de8-4814-84ba-faba274a4f38",
  defaultMessage: "If you see a map to the side, that means this address has been verified and we know the exact location to use for optimizing your routes.  If no map is displayed, you can still use this address, but route optimization may not be as precise."
};

export const MSG_problemContactingMappingProvider = {
  id: "ada85151-3692-484b-88e2-a8e9f11a782e",
  defaultMessage: "There was a problem contacting our mapping provider to provide the address lookup service.  Please try again.",
};

export const MSG_problemFindingExactAddress = {
  id: "dfdb35d3-c023-4e7e-b8a5-a959f9a68ef3",
  defaultMessage: "There was a problem finding the exact address of the location you selected.  Make sure it is an actual street address, and not the name of a place or city.",
};

export const MSG_addressPositionNotFound = {
  id: "0f292915-6a9b-4118-b264-e512bee308f3",
  description: "The error message displayed when an address could not be found with our mappig provider.",
  defaultMessage: "The address could not be located with our mapping provider: {address}"
};

export const MSG_selectCoordinatesFromMap = {
  id: "8a5ea2a8-2db7-4198-9836-1eac52f4146d",
  description: "This is a message that is displayed to the user when they need to select coordinates from a map.",
  defaultMessage: "Click the map to select coordinates by dropping a pin on the location."
};

export const MSG_selectCoordinatesFromMapMobile = {
  id: "e3fb6857-e86d-46cd-bcc6-0c671278b1b1",
  description: "This is a message that is displayed to the user when they need to select coordinates from a map on a mobile device.",
  defaultMessage: "Tap the map to drop a pin."
};


export const MSG_enterAddressManuallyTitle = {
  id: "5eb50c48-81c9-4182-8f11-589069dbe0eb",
  description: "This is a button that lets them manually enter the parts of an address themselves (i.e. street, city, province, etc) rather than using a typeahead address finder.",
  defaultMessage: "Enter Address Manually",
};

export const MSG_noItemsInThisList = {
  id: "d3707765-741f-4c03-a32a-f0384cb371a5",
  description: "",
  defaultMessage: "There are no items in this list.",
};

export const MSG_mobileAppUpdatedToVersion = {
  id: "08a7dc6a-7362-4575-afc6-b49779b17822",
  defaultMessage: "The Gazelle mobile app has been updated to version {version}."
};

export const MSG_mobileAppUpdatedWhatsNew = {
  id: "466298bb-9501-4f0c-a38f-384a05618886",
  defaultMessage: "Here's what's new."
};

export const MSG_mobileAppUpdateFullListOfChanges = {
  id: "82d1e523-060d-4cea-880d-c4c506a6c5c0",
  defaultMessage: "A full list of all changes can always be found in the Settings tab.",
};

export const MSG_discardSupportRequestConfirmation = {
  id: "c65fb028-f86d-4b7e-8456-4e41ec7dd69a",
  defaultMessage: "Are you sure you want to discard this support request?"
};

export const MSG_isGazelleHelpingYouGrow = {
  id: "f07776e5-aaa9-4213-b043-0d4e4eea9ec9",
  defaultMessage: "Is Gazelle helping your business grow?"
};

export const MSG_doYouHaveTimeToWriteAReview = {
  id: "13906b2a-a78a-4442-a3b9-e2f67b815dff",
  defaultMessage: "You can help other piano technicians discover Gazelle by giving an honest review.  Do you have time to write a review?"
};

export const MSG_mobileBlurbHeading = {
  id: "51d3bd74-d3a9-4167-b6b3-160fcf706342",
  description: "This is the heading on a blurb we display if they are viewing our desktop website on a mobile phone.  We try and encourage them to install our native mobile app.",
  defaultMessage: "It's better in the app"
};

export const MSG_mobileBlurbMessage = {
  id: "008ce25b-c90e-4411-9ced-ff8297090a01",
  description: "This is the explanation on a blurb we display if they are viewing our desktop website on a mobile phone.  We try and encourage them to install our native mobile app.",
  defaultMessage: "Our native mobile app is much easier to use on phones and tablets."
};

export const MSG_mobileBlurbReason1 = {
  id: "3a3d671d-2d6c-43c4-b00d-be3fa2d2fc8e",
  description: "This is one of several reasons to download our native mobile app",
  defaultMessage: "Easier to read"
};

export const MSG_mobileBlurbReason2 = {
  id: "7a97e14b-1f73-4dca-9da7-f5d3f63993f0",
  description: "This is one of several reasons to download our native mobile app",
  defaultMessage: "Designed for small screens"
};

export const MSG_mobileBlurbReason3 = {
  id: "ff7d3551-3496-4155-affe-8e5e776d2894",
  description: "This is one of several reasons to download our native mobile app",
  defaultMessage: "Handles touch gestures"
};

export const MSG_mobileBlurbAction = {
  id: "47c47233-61bf-4bc3-98e2-0f6f2e6c9c68",
  defaultMessage: "Want to download our mobile app?"
};

export const MSG_mobileDownloadAndroid = {
  id: "b2433e9b-72bd-42a8-b88d-f1fd21fe13ce",
  description: "",
  defaultMessage: "Download for Android"
};

export const MSG_mobileDownloadIos = {
  id: "6f60dc26-6738-4afe-98e3-335c15840472",
  description: "",
  defaultMessage: "Download for iOS"
};

export const MSG_ascendingLabel = {
  id: "851df174-f4a1-4102-9317-9e771be1c72c",
  description: "The label for sorting a list in ascending (increasing) order.",
  defaultMessage: "Ascending"
};

export const MSG_descendingLabel = {
  id: "b9568842-9cf9-4e50-8c3b-6f56936f6708",
  description: "The label for sorting a list in descending (decreasing) order.",
  defaultMessage: "Descending"
};

export const MSG_searchingLabel = {
  id: "dc2c5c42-b435-4075-9903-e80173ef69a5",
  description: "A label that shown during while a search is running as the user types something into a text box.",
  defaultMessage: "Searching..."
};

// Note that this is used to pass to the AsyncTypeahead component (from react-bootstrap-typeahead).
// Sometimes this component displays this text very briefly instead of the placeholder text if the
// right async events are fired.
export const MSG_typeToSearchLabel = {
  id: "a836887e-5258-4496-9c7c-cacd9445dab7",
  description: "A label that is sometimes displayed in a box that a user can type in to search.",
  defaultMessage: "Type to search..."
};

export const MSG_redTitle = {
  id: "947244cd-e72b-4605-ad7a-e76eb4973ac2",
  description: "The color red in title case.",
  defaultMessage: "Red",
};

export const MSG_greenTitle = {
  id: "e27daf00-f58d-4779-981d-434d18d4a81a",
  description: "The color green in title case.",
  defaultMessage: "Green",
};

export const MSG_blueTitle = {
  id: "c1109454-7cb4-4da2-9290-c2eae56377f4",
  description: "The color blue in title case.",
  defaultMessage: "Blue",
};

export const MSG_newFeaturesTitle = {
  id: "4831cda2-32d3-4549-a1d1-a87f9982c2c5",
  description: "This is the title of a section where users can toggle new features on and off.",
  defaultMessage: "New Features"
};

export const MSG_recommendedTitle = {
  id: "de7c3e54-b831-41a5-ba9c-45580e36e765",
  description: "This is a badge that will be displayed on an option that is the recommended option.",
  defaultMessage: "Recommended"
};

export const MSG_expandTitle = {
  id: "053573d0-8785-49cd-b252-f0af0b2d5d99",
  description: "The label for a button that lets them expand something into a longer form.",
  defaultMessage: "Expand"
};

export const MSG_nameCopy = {
  id: "12fe949a-ace9-4b8f-9155-e2c9d945bd09",
  description: "This is used when cloning or copying something.  It is the default name of the new copy, which takes the original name and just adds 'copy' to the end in parenthesis.",
  defaultMessage: "{name} (copy)"
};

export const MSG_copyOfName = {
  id: "90ebd86a-8b05-4e19-a43b-34528b950255",
  defaultMessage: "Copy of {name}"
};

export const MSG_createACopy = {
  id: "c9ca01fe-d19c-4f03-a4fb-c06893fb55ce",
  description: "",
  defaultMessage: "Create a copy"
};

export const MSG_addTagTitle = {
  id: "84fd5f88-e312-4156-8ea0-12ceaad33883",
  description: "This is a button that lets them add a tag to a client, piano, invoice, or estimate.",
  defaultMessage: "Add Tag"
};

export const MSG_markAsCompleteTitle = {
  id: "a74d5f88-bf3d-41f6-9231-10aceaad34872", 
  description: "This is a button that lets the user mark appointments as complete in bulk.",
  defaultMessage: "Mark as Complete"
};


export const MSG_addATagLabel = {
  id: "c7e0d2d3-5e9d-4a9f-9e5b-6b7e3c9b1c7d",
  description: "This is a label for a text field where they can add a tag to a client, piano, invoice, or estimate.",
  defaultMessage: "Add a tag"
};

export const MSG_tagRemoveAreYouSureLabel = {
  id: "6e79da0e-47be-4734-92c7-8e72a7359f5a",
  description: "This is the message that is displayed when they try and remove a tag from a client, piano, invoice, or estimate.",
  defaultMessage: "Are you sure you want to remove the following tag:"
};

export const MSG_tagErrorWhileRemovingLabel = {
  id: "2f3a3d5e-0e5e-4d9b-bd3f-9e4e0a6d3c8d",
  description: "This is the message that is displayed when there is an error removing a tag from a client, piano, invoice, or estimate.",
  defaultMessage: "There was an error removing the tag: {error}"
};

export const MSG_anyOfTheseTags = {
  id: "69a3b972-5677-45a2-bb0f-f6bf87a0588d",
  description: "A filter for whether or not a record is tagged with any of a given set of tags.",
  defaultMessage: "Any of these tags"
};

export const MSG_allOfTheseTags = {
  id: "0e56f2c9-43a1-4080-a53b-3be2f5c74c1e",
  description: "A filter for whether or not a record is tagged with all of a given set of tags.",
  defaultMessage: "All of these tags"
};

export const MSG_noneOfTheseTags = {
  id: "df078a84-ed3a-4089-8ace-e5ddb4c62a58",
  description: "A filter for whether or not a record is tagged with none of a given set of tags.",
  defaultMessage: "None of these tags"
};

export const MSG_noExistingTagsMatching = {
  id: "86bb47a0-b326-42fa-9aba-8a6dfdc20332",
  description: "This is displayed when they are searching for tags, but no existing tags match the text they entered.  It is a message that is displayed to the user.",
  defaultMessage: "No existing tags match the text you entered."
};

export const MSG_createNewTagColon = {
  id: "098b692b-23e8-4756-86a8-81473de02c79",
  description: "",
  defaultMessage: "Create new tag:"
};

export const MSG_alreadyAddedTag = {
  id: "7910edfb-e85c-49f1-958b-78a49a042e08",
  description: "Something to show after a tag in the list of tags when adding a new tag to a client, piano, invoice, or estimate.  It indicates that the tag has already been added.",
  defaultMessage: "(already added)"
};


export const MSG_removeTagTitle = {
  id: "23cd313d-4dc0-414f-857a-e03f21ebeffc",
  description: "This is a button that lets them remove a tag from a client, piano, invoice, or estimate.",
  defaultMessage: "Remove Tag"
};

export const MSG_removeATagLabel = {
  id: "f09630de-f927-47fd-abe8-4625f1f496fb",
  description: "This is a label for a button that lets them remove a tag from a client, piano, invoice, or estimate.",
  defaultMessage: "Remove a tag"
};

export const MSG_existingTagsTitle = {
  id: "40fd7c1c-d0b3-4f99-87ed-03971af60c69",
  description: "A title for a list of existing tags that they can choose from when adding a tag to a client, piano, invoice, or estimate.  This is a heading for the list of tags.",
  defaultMessage: "Existing Tags"
};

export const MSG_useDefaultValueTitle = {
  id: "fa9ce63b-0470-4881-928f-2b41f4449990",
  description: "",
  defaultMessage: "Use Default Value"
};
