import gql from "graphql-tag";
import { PrivateUserDetailedInfoFragment, PrivateUserMobileSettingsFragment } from "./user";
import { PrivateCompanyDetailedInfoFragment } from "./company";
import { PrivateTaxFragment } from "./tax";
import { PrivateSupportedLocaleInfoFragment } from "./supported_locale_info";
import { PrivateJakklopsPricingPlanFragment } from "./billing";

export const PrivateBootstrapQuery = gql`
  query {
    globalConfig { gak gmk gsk azk mbpk intercomAppId }
    company { ...PrivateCompanyDetailedInfoFragment },
    user { ...PrivateUserDetailedInfoFragment ...PrivateUserMobileSettingsFragment }
    allUsers { ...PrivateUserDetailedInfoFragment }
    allTaxes { ...PrivateTaxFragment }
    allSupportedLocales { ...PrivateSupportedLocaleInfoFragment }
    allCurrencies { id code symbol decimalDigits label divisor }
    allSystemNotifications(excludeClientRelatedNotifications: true, first: 25) { edges { node {id groupingToken alertType type subType message isDismissable}} }
    allAddressDisplayTemplates { countryCode singleLine multiLine summaryLine }
    onboarding { visible selectedTier steps { key dismissed label tiers } }
    remoteAccountingIntegrations { quickbooksOnline { isConnectedPassive } }
    mailchimpIntegration { isConnectedPassive chosenAudienceId }
    legalContracts { id title type version explanation contractDate signedAt signedBy { id firstName lastName} }
    allLifecycles(first: 100) { nodes {id name}}
    allSupportedLocalesCountryNames { locale countries { code name}}
  }

  ${PrivateUserDetailedInfoFragment}
  ${PrivateUserMobileSettingsFragment}
  ${PrivateCompanyDetailedInfoFragment}
  ${PrivateTaxFragment}
  ${PrivateSupportedLocaleInfoFragment}
  ${PrivateJakklopsPricingPlanFragment}
`;

export const PrivateBootstrapUsersQuery = gql`
  query {
    user { ...PrivateUserDetailedInfoFragment ...PrivateUserMobileSettingsFragment }
    allUsers { ...PrivateUserDetailedInfoFragment }
  }
  ${PrivateUserDetailedInfoFragment}
  ${PrivateUserMobileSettingsFragment}
`;

export const PrivateBootstrapCompanyQuery = gql`
  query {
    company { ...PrivateCompanyDetailedInfoFragment }
  }
  ${PrivateCompanyDetailedInfoFragment}
`;
