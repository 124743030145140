import { formatMessage } from "./intl";
import { LOCATION_TYPE } from "../enums";
import { MSG_addressTitle, MSG_coordinatesTitle, MSG_what3wordsTitle } from "../strings";

const SortedLocationTypes = [
  LOCATION_TYPE.ADDRESS,
  LOCATION_TYPE.COORDINATES,
  LOCATION_TYPE.WHAT3WORDS,
];

const LocationTypeInfo: { [key: string]: { type: LOCATION_TYPE, getLabel: () => string } } = {
  [LOCATION_TYPE.ADDRESS]: { type: LOCATION_TYPE.ADDRESS, getLabel: () => formatMessage(MSG_addressTitle) },
  [LOCATION_TYPE.COORDINATES]: { type: LOCATION_TYPE.COORDINATES, getLabel: () => formatMessage(MSG_coordinatesTitle) },
  [LOCATION_TYPE.WHAT3WORDS]: { type: LOCATION_TYPE.WHAT3WORDS, getLabel: () => formatMessage(MSG_what3wordsTitle) },
};

export { SortedLocationTypes, LocationTypeInfo };
