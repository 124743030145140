import gql from "graphql-tag";

export const PrivateContactLocationFullInfoFragment = gql`
  fragment PrivateContactLocationFullInfoFragment on PrivateContactLocation {
    __typename id locationType usageType
    street1 street2 municipality region postalCode countryCode
    latitude longitude geocodeType isBadAddress
  }
`;

// ### MUTATIONS ###########################################################################################

export const PrivateCreateContactLocationMutation = gql`
  mutation ($contactId: String!, $input: PrivateContactLocationInput!, $replaceExistingType: Boolean) {
    createContactLocation(contactId: $contactId, input: $input, replaceExistingType: $replaceExistingType) {
      mutationErrors { type fieldName key messages errorReferenceId }
      location { ...PrivateContactLocationFullInfoFragment }
    }
  }

  ${PrivateContactLocationFullInfoFragment}
`;

export const PrivateUpdateContactLocationMutation = gql`
  mutation ($id: String!, $input: PrivateContactLocationInput!) {
    updateContactLocation(id: $id, input: $input) {
      mutationErrors { type fieldName key messages errorReferenceId }
      location { ...PrivateContactLocationFullInfoFragment }
    }
  }

  ${PrivateContactLocationFullInfoFragment}
`;

export const PrivateDeleteContactLocationMutation = gql`
  mutation ($id: String!) {
    deleteContactLocation(id: $id) {
      mutationErrors { type fieldName key messages errorReferenceId }
      isDeleted
    }
  }
`;
