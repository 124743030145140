import gql from "graphql-tag";
import { PrivateClientFullInfoFragment } from "./client";
import { PrivatePianoInfoFragment } from "./piano";
import { PrivateInvoiceFragment } from "./invoice";
import { PrivateInvoiceItemFragment } from "./invoice_item";

export const PrivateEventCoreInfoFragment = gql`
  fragment PrivateEventCoreInfoFragment on PrivateEvent {
    id type status title notes start duration buffer timezone isAllDay remoteType
    confirmedAt confirmationWarning lifecycleState
    createdViaSchedulerVersion travelMode
    location { id locationType singleLineAddress street1 street2 municipality region postalCode countryCode latitude longitude geocodeType }
    user { id color firstName lastName isTrafficEnabled }
    client { id }
  }
`;

export const PrivateEventPianoInfoFragment = gql`
  fragment PrivateEventPianoInfoFragment on PrivateEvent {
    allEventPianos {
      nodes { isTuning piano { ...PrivatePianoInfoFragment damppChaserInstalled nextServiceOverride serviceIntervalMonths }}
    }
  }
  ${PrivatePianoInfoFragment}
`;

export const PrivateEventUserInfoFragment = gql`
  fragment PrivateEventUserInfoFragment on PrivateEvent {
    user {
      id firstName lastName accessLevel isSchedulable status
    }
  }
`;

export const PrivateEventRecurrenceInfoFragment = gql`
  fragment PrivateEventRecurrenceInfoFragment on PrivateEvent {
    recurrenceId recurrenceType recurrenceWeekdays recurrenceWeeks
    recurrenceDates recurrenceInterval recurrenceEndingType
    recurrenceEndingOccurrences recurrenceEndingDate
  }
`;

export const PrivateEventWithLifecycleInfoFragment = gql`
  fragment PrivateEventWithLifecycleInfoFragment on PrivateEvent {
    client {
      lifecycle {
        id name confWindowCode
      }
    }
  }
`;

export const PrivateEventRemoteCalendarInfoFragment = gql `
  fragment PrivateEventRemoteCalendarInfoFragment on PrivateEvent {
    remoteCalendar { id calendarDisplayName type remoteCalendarIntegration {id} impactsAvailability importAsBusy}
  }
`;

// ### QUERIES ############################################################################################

export const PrivateEventsInRangeQuery = gql`
  query ($filters: PrivateAllEventsFilter) {
    allEvents(filters: $filters) {
      ...PrivateEventCoreInfoFragment
      ...PrivateEventRecurrenceInfoFragment
    }
  }
  ${PrivateEventCoreInfoFragment}
  ${PrivateEventRecurrenceInfoFragment}
`;

export const PrivateEventQuery = gql`
  query ($id: String!) {
    event(eventId: $id) {
      ...PrivateEventCoreInfoFragment
      ...PrivateEventPianoInfoFragment
      ...PrivateEventUserInfoFragment
      ...PrivateEventRecurrenceInfoFragment
      ...PrivateEventWithLifecycleInfoFragment
    }
  }
  ${PrivateEventCoreInfoFragment}
  ${PrivateEventPianoInfoFragment}
  ${PrivateEventUserInfoFragment}
  ${PrivateEventRecurrenceInfoFragment}
  ${PrivateEventWithLifecycleInfoFragment}

`;

export const PrivateDashboardEventsOnDateQuery = gql`
  query ($filters: PrivateAllEventsFilter) {
    allEvents(filters: $filters) {
      id start duration buffer timezone status
    }
  }
`;

export const PrivateDetailedEventQuery = gql`
  query ($id: String!) {
    event(eventId: $id) {
      ...PrivateEventCoreInfoFragment
      ...PrivateEventPianoInfoFragment
      ...PrivateEventUserInfoFragment
      ...PrivateEventRecurrenceInfoFragment
      ...PrivateEventWithLifecycleInfoFragment
      client {
        ...PrivateClientFullInfoFragment
      }
      remoteCalendar { id calendarDisplayName type remoteCalendarIntegration {id} impactsAvailability}
      confirmedByClient
    }
  }

  ${PrivateEventCoreInfoFragment}
  ${PrivateEventPianoInfoFragment}
  ${PrivateEventUserInfoFragment}
  ${PrivateEventRecurrenceInfoFragment}
  ${PrivateEventWithLifecycleInfoFragment}
  ${PrivateClientFullInfoFragment}
`;

export const PrivateDetailedEventWithLastInvoiceQuery = gql`
  query ($id: String!) {
    event(eventId: $id) {
      ...PrivateEventCoreInfoFragment
      ...PrivateEventPianoInfoFragment
      ...PrivateEventUserInfoFragment
      ...PrivateEventRecurrenceInfoFragment
      ...PrivateEventWithLifecycleInfoFragment
      client {
        ...PrivateClientFullInfoFragment
        lastInvoice {
          dueOn total status notes allInvoiceItems { edges { node {
            ...PrivateInvoiceItemFragment
          }}}
        }
      }
      remoteCalendar { id calendarDisplayName type remoteCalendarIntegration {id} impactsAvailability}
    }
  }

  ${PrivateEventCoreInfoFragment}
  ${PrivateEventPianoInfoFragment}
  ${PrivateEventUserInfoFragment}
  ${PrivateEventRecurrenceInfoFragment}
  ${PrivateEventWithLifecycleInfoFragment}
  ${PrivateClientFullInfoFragment}
  ${PrivateInvoiceItemFragment}
`;

export const PrivateEventSearchSortedQuery = gql`
  query ($filters: PrivateAllEventsFilter, $sortBy: [EventSort!]) {
    allEvents(filters: $filters, sortBy: $sortBy) {
        ...PrivateEventCoreInfoFragment
        ...PrivateEventPianoInfoFragment
        ...PrivateEventUserInfoFragment
        ...PrivateEventRecurrenceInfoFragment
        ...PrivateEventRemoteCalendarInfoFragment
        client {
            ...PrivateClientFullInfoFragment
        }
    }
  }
  ${PrivateEventCoreInfoFragment}
  ${PrivateEventPianoInfoFragment}
  ${PrivateEventUserInfoFragment}
  ${PrivateEventRecurrenceInfoFragment}
  ${PrivateEventRemoteCalendarInfoFragment}
  ${PrivateClientFullInfoFragment}
`;


export const PrivateAllEventsInfiniteQuery = gql`
  query PrivateAllEventsInfiniteQuery(
    $filters: PrivateAllEventsFilter, 
    $first: Int, 
    $after: String, 
    $sortBy: [EventSort!]
  ) {
    allEventsBatched(filters: $filters, first: $first, after: $after, sortBy: $sortBy) {
      nodes {
        ...PrivateEventCoreInfoFragment
        ...PrivateEventPianoInfoFragment
        ...PrivateEventUserInfoFragment
        ...PrivateEventRecurrenceInfoFragment
        ...PrivateEventRemoteCalendarInfoFragment
        client {
          ...PrivateClientFullInfoFragment
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
      totalCount
    }
  }
  ${PrivateEventCoreInfoFragment}
  ${PrivateEventPianoInfoFragment}
  ${PrivateEventUserInfoFragment}
  ${PrivateEventRecurrenceInfoFragment}
  ${PrivateEventRemoteCalendarInfoFragment}
  ${PrivateClientFullInfoFragment}
`;


// ### MUTATIONS ###########################################################################################

export const PrivateUpdateEventMutation = gql`
  mutation ($input: PrivateEventInput! $id: String!) {
    saveEvent: updateEvent(input: $input id: $id) {
      mutationErrors { fieldName key messages type errorReferenceId }
      event {
        ...PrivateEventCoreInfoFragment
        ...PrivateEventPianoInfoFragment
        ...PrivateEventUserInfoFragment
        ...PrivateEventRecurrenceInfoFragment
      }
    }
  }

  ${PrivateEventCoreInfoFragment}
  ${PrivateEventPianoInfoFragment}
  ${PrivateEventUserInfoFragment}
  ${PrivateEventRecurrenceInfoFragment}
`;

export const PrivateCreateEventMutation = gql`
  mutation ($input: PrivateEventInput!) {
    saveEvent: createEvent(input: $input) {
      mutationErrors { fieldName key messages type errorReferenceId }
      event {
        ...PrivateEventCoreInfoFragment
        ...PrivateEventPianoInfoFragment
        ...PrivateEventUserInfoFragment
        ...PrivateEventRecurrenceInfoFragment
      }
    }
  }

  ${PrivateEventCoreInfoFragment}
  ${PrivateEventPianoInfoFragment}
  ${PrivateEventUserInfoFragment}
  ${PrivateEventRecurrenceInfoFragment}
`;

export const PrivateDeleteEventMutation = gql`
  mutation ($id: String!, $recurrenceChangeType: EventRecurrenceChangeType) {
    deleteEvent(id: $id, recurrenceChangeType: $recurrenceChangeType) {
      mutationErrors { fieldName key messages type errorReferenceId }
      isDeleted
    }
  }
`;

export const PrivateCancelEventMutation = gql`
  mutation ($eventId: String!) {
    cancelEvent(eventId: $eventId) {
      mutationErrors { fieldName key messages type errorReferenceId }
      event { id status lifecycleState }
    }
  }
`;

export const PrivateUncancelEventMutation = gql`
  mutation ($eventId: String!) {
    uncancelEvent(eventId: $eventId) {
      mutationErrors { fieldName key messages type errorReferenceId }
      event { id status lifecycleState }
    }
  }
`;

export const PrivateConfirmEventMutation = gql`
  mutation ($id: String!, $input: PrivateConfirmEventInput!) {
    confirmEvent(id: $id, input: $input) {
      mutationErrors { fieldName key messages type errorReferenceId }
      event { id confirmedAt confirmationWarning timezone lifecycleState }
    }
  }
`;

export const PrivateUnconfirmEventMutation = gql`
  mutation ($id: String!) {
    unconfirmEvent(id: $id) {
      mutationErrors { fieldName key messages type errorReferenceId }
      event { id confirmedAt confirmationWarning timezone lifecycleState }
    }
  }
`;

export const PrivateUncompleteEventMutation = gql`
  mutation ($id: String!) {
    uncompleteEvent(id: $id) {
      mutationErrors { fieldName key messages type errorReferenceId }
      event { id status lifecycleState }
    }
  }
`;

export const PrivateCompleteEventMutation = gql`
  mutation ($id: String!, $input: PrivateCompleteEventInput) {
    completeEvent(eventId: $id, input: $input) {
      mutationErrors { fieldName key messages type errorReferenceId }
      event { id status lifecycleState }
      invoice { ...PrivateInvoiceFragment }
    }
  }
  ${PrivateInvoiceFragment}
`;
