import { APPOINTMENT_COMPLETION_RESULT_TYPE } from "../enums";
import { formatMessage } from "./intl";
import {
  MSG_canceledExplanation,
  MSG_canceledTitle,
  MSG_completedExplanation,
  MSG_completedTitle,
  MSG_noShowExplanation,
  MSG_noShowTitle,
  MSG_otherResultExplanation,
  MSG_otherResultTitle,
} from "../strings";

const SortedAppointmentCompletionTypes = [
  APPOINTMENT_COMPLETION_RESULT_TYPE.COMPLETE,
  APPOINTMENT_COMPLETION_RESULT_TYPE.NO_SHOW,
  APPOINTMENT_COMPLETION_RESULT_TYPE.CANCELED,
  APPOINTMENT_COMPLETION_RESULT_TYPE.OTHER,
];

const AppointmentCompletionTypeInfo: { [key: string]: { type: APPOINTMENT_COMPLETION_RESULT_TYPE, getLabel: () => string, getExplanation: () => string } } = {
  [APPOINTMENT_COMPLETION_RESULT_TYPE.COMPLETE]: {
    type: APPOINTMENT_COMPLETION_RESULT_TYPE.COMPLETE,
    getLabel: () => formatMessage(MSG_completedTitle),
    getExplanation: () => formatMessage(MSG_completedExplanation),
  },
  [APPOINTMENT_COMPLETION_RESULT_TYPE.NO_SHOW]: {
    type: APPOINTMENT_COMPLETION_RESULT_TYPE.NO_SHOW,
    getLabel: () => formatMessage(MSG_noShowTitle),
    getExplanation: () => formatMessage(MSG_noShowExplanation),
  },
  [APPOINTMENT_COMPLETION_RESULT_TYPE.CANCELED]: {
    type: APPOINTMENT_COMPLETION_RESULT_TYPE.CANCELED,
    getLabel: () => formatMessage(MSG_canceledTitle),
    getExplanation: () => formatMessage(MSG_canceledExplanation),
  },
  [APPOINTMENT_COMPLETION_RESULT_TYPE.OTHER]: {
    type: APPOINTMENT_COMPLETION_RESULT_TYPE.OTHER,
    getLabel: () => formatMessage(MSG_otherResultTitle),
    getExplanation: () => formatMessage(MSG_otherResultExplanation),
  },
};

export { SortedAppointmentCompletionTypes, AppointmentCompletionTypeInfo };
