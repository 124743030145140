import * as React from 'react';
import { IMappingInteractiveItineraryMapProps } from "../interactive_map";
import * as geolib from "geolib";
import { svgPathData } from "@fortawesome/pro-solid-svg-icons/faMapMarker";
import { ICoordinates } from "@gazelle/shared/interfaces";
import * as polyline from "@mapbox/polyline";
import {
  IMappingPolyline,
  POLYLINE_COLOR_HEX,
  POLYLINE_COLOR_OPACITY,
  POLYLINE_WIDTH
} from "@gazelle/shared/mapping/polyline";
import { GoogleMap, Marker, Polyline } from "@react-google-maps/api";
import { SCHEDULER_TRAVEL_MODE } from "@gazelle/shared/enums";

interface IGoogleInteractiveItineraryMapContainerConfig {
  defaultLat: number,
  defaultLng: number,
  polylineProvider: IMappingPolyline,
}

function getGoogleInteractiveItineraryMapContainer(config: IGoogleInteractiveItineraryMapContainerConfig) {
  interface IProps {
    loadingElement: React.ReactElement,
    containerElement: React.ReactElement,
    mapElement: React.ReactElement,
  }

  interface ITravelModeRoutes {
    travelMode: SCHEDULER_TRAVEL_MODE;
    route: ICoordinates[];
  }

  const ItineraryMap: React.FunctionComponent<IMappingInteractiveItineraryMapProps & IProps> = React.memo((props) => {
    const [bounds, setBounds] = React.useState<google.maps.LatLngBounds | null>(null);
    const [path, setPath] = React.useState<ICoordinates[] | null>(null);
    const mapRef = React.useRef<google.maps.Map>();
    const lastRouteHashRef = React.useRef<string>();

    React.useEffect(() => {
      if (!bounds) return;
      const ne = bounds.getNorthEast().toJSON();
      const sw = bounds.getSouthWest().toJSON();
      let distance = geolib.getDistance({latitude: ne.lat, longitude: ne.lng}, {latitude: sw.lat, longitude: sw.lng});
      if (distance > 100) {
        mapRef.current?.fitBounds(bounds);
      }
    }, [bounds]);

    React.useEffect(() => {
      let bounds = new google.maps.LatLngBounds();
      if (props.itinerary && props.itinerary.mappableItems[0]) {
        props.itinerary.mappableItems.forEach(item => {
          if (item.coordinates && item.coordinates.latitude && item.coordinates.longitude) {
            bounds.extend({
              lat: parseFloat(item.coordinates.latitude),
              lng: parseFloat(item.coordinates.longitude)
            });
          }
        });
      }
      setPath(null);
      setBounds(bounds);
      void renderPolyline();
    }, [props.itinerary]);

    async function renderPolyline() {
      let travelModeRoutes: ITravelModeRoutes[] = [];

      if (props.itinerary) {
        let currentTravelMode: SCHEDULER_TRAVEL_MODE | null = null;
        let currentRoute: ICoordinates[] = [];
        let lastPosition: ICoordinates | null = null;
        props.itinerary.mappableItems.forEach((item) => {
          if (item.travelMode !== currentTravelMode) {
            if (currentTravelMode) {
              travelModeRoutes.push({travelMode: currentTravelMode, route: currentRoute});
              currentRoute = [];
            }
            if (lastPosition) {
              currentRoute.push(lastPosition);
            }
            currentTravelMode = item.travelMode;
          }
          if (item.coordinates && !item.samePositionLink) {
            currentRoute.push(item.coordinates);
            lastPosition = item.coordinates;
          }
        });
        if (currentTravelMode) {
          travelModeRoutes.push({travelMode: currentTravelMode, route: currentRoute});
        }
      }

      let path: [number, number][] = [];
      let bounds = new google.maps.LatLngBounds();

      for (let i = 0; i < travelModeRoutes.length; i++) {
        let travelModeRoute = travelModeRoutes[i];
        let routeHash = JSON.stringify(travelModeRoute.route);
        if (routeHash === lastRouteHashRef.current) continue;
        lastRouteHashRef.current = routeHash;

        travelModeRoute.route.forEach(point => {
          if (point.latitude && point.longitude) {
            bounds.extend({
              lat: parseFloat(point.latitude),
              lng: parseFloat(point.longitude)
            });
          }
        });

        // Get the decoded path for the route, extend the bounds for it, and then add the points to the path.
        // Get the decoded path for the route, extend the bounds for it, and then add the points to the path.
        let decodedPath = polyline.decode(await config.polylineProvider.getPolyline(travelModeRoute.route, travelModeRoute.travelMode));
        decodedPath.forEach(point => bounds.extend({lat: point[0], lng: point[1]}));

        // if the travel mode is not transit, add decodedPath to the path
        if (travelModeRoute.travelMode !== SCHEDULER_TRAVEL_MODE.TRANSIT) {
          path = path.concat(decodedPath);
        }
      }

      setPath(path.map((p: number[]) => {
        return {
          latitude: p[0].toString(),
          longitude: p[1].toString()
        };
      }));
      setBounds(bounds);
    }

    return (
      <GoogleMap
        mapContainerStyle={{width: '100%', height: '100%'}}
        onLoad={map => {
          mapRef.current = map;
        }}
      >
        {path && path.length > 0 &&
          <Polyline
            options={{strokeColor: POLYLINE_COLOR_HEX, strokeOpacity: POLYLINE_COLOR_OPACITY, strokeWeight: POLYLINE_WIDTH}}
            path={path.map(c => ({lat: parseFloat(c.latitude), lng: parseFloat(c.longitude)}))}/>}
        {props.itinerary && props.itinerary.mappableItems.map((item, index) => {
          if (item.coordinates && !item.samePositionLink) {
            return (
              <Marker position={{lat: parseFloat(item.coordinates.latitude), lng: parseFloat(item.coordinates.longitude)}}
                      key={index}
                      label={{
                        color: item.pinFgColor,
                        text: item.pinLabel
                      }}
                      icon={{
                        path: svgPathData,
                        fillColor: item.pinColor,
                        fillOpacity: 1,
                        strokeOpacity: 1,
                        strokeWeight: 1,
                        strokeColor: '#fff',
                        scale: 0.075,
                        labelOrigin: new google.maps.Point(180, 200),
                        anchor: new google.maps.Point(172.268, 501.67),
                      }}/>
            );
          } else {
            return null;
          }
        })}
      </GoogleMap>
    );
  });

  return (props: IMappingInteractiveItineraryMapProps) => {
    return (
      <ItineraryMap
        itinerary={props.itinerary}
        loadingElement={<div style={{height: `100%`}}/>}
        containerElement={<div style={{height: `100%`}}/>}
        mapElement={<div style={{height: `100%`}}/>}
      />
    );
  };
}


export { getGoogleInteractiveItineraryMapContainer };
