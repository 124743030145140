import gql from "graphql-tag";

export const CoreLocalizationDetailedInfoFragment = gql`
  fragment CoreLocalizationDetailedInfoFragment on CoreLocalization {
    id isUserDefault isClientDefault
    locale dateFormatLocale timeFormatLocale
    numberFormat currencyFormat firstDayOfWeek
    dateFormat {
      date { moment unicode }
      weekdayDate { moment unicode }
      monthYear { moment unicode }
      dateTimeSeparator
    }
    timeFormat {
      time { moment unicode }
      timezoneTime { moment unicode }
    }
  }
`;

// ### QUERIES ############################################################################################

export const CoreLocalizationListQuery = gql`
  query {
    allLocalizations {
      ...CoreLocalizationDetailedInfoFragment
    }
  }
  ${CoreLocalizationDetailedInfoFragment}
`;

// ### MUTATIONS ############################################################################################

export const PrivateCreateLocalizationMutation = gql`
  mutation ($input: PrivateLocalizationInput!) {
    createLocalization(input: $input) {
      mutationErrors { fieldName key messages type errorReferenceId }
      localization {
        ...CoreLocalizationDetailedInfoFragment
      }
    }
  }
  ${CoreLocalizationDetailedInfoFragment}
`;

export const PrivateUpdateLocalizationMutation = gql`
  mutation ($id: String!, $input: PrivateLocalizationInput!) {
    updateLocalization(id: $id, input: $input) {
      mutationErrors { fieldName key messages type errorReferenceId }
      localization {
        ...CoreLocalizationDetailedInfoFragment
      }
    }
  }
  ${CoreLocalizationDetailedInfoFragment}
`;

export const PrivateDeleteLocalizationMutation = gql`
  mutation ($id: String!) {
    deleteLocalization(id: $id) {
      mutationErrors { fieldName key messages type errorReferenceId }
      isDeleted
    }
  }
`;
