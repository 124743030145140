import { ICoordinates } from "../interfaces";
import { SCHEDULER_TRAVEL_MODE } from "../enums";

interface IPolylineCacheEntry {
  routeKey: string;
  polyline: string;
  lastUsed: number;
}

const POLYLINE_CACHE: {[key: string]: IPolylineCacheEntry} = {};
const POLYLINE_CACHE_ENTRIES: IPolylineCacheEntry[] = [];
const MAX_POLYLINE_CACHE_ENTRIES = 50;

function routeToKey(route: ICoordinates[], travelMode: SCHEDULER_TRAVEL_MODE): string {
  return travelMode + ";" + route.map(point => `${point.latitude},${point.longitude}`).join("|");
}

function pushPolylineCache(route: ICoordinates[], polyline: string, travelMode: SCHEDULER_TRAVEL_MODE): string {
  const routeKey = routeToKey(route, travelMode);
  const entry = {
    routeKey: routeKey,
    polyline: polyline,
    lastUsed: new Date().getTime(),
  };
  POLYLINE_CACHE[routeKey] = entry;
  POLYLINE_CACHE_ENTRIES.push(entry);
  cleanupPolylineCache();
  return polyline;
}

function findPolylineCache(route: ICoordinates[], travelMode: SCHEDULER_TRAVEL_MODE): string {
  const entry = POLYLINE_CACHE[routeToKey(route, travelMode)];
  if (entry) {
    entry.lastUsed = new Date().getTime();
    return entry.polyline;
  }
  return null;
}

function cleanupPolylineCache() {
  POLYLINE_CACHE_ENTRIES.sort((a, b) => {
    if (a.lastUsed > b.lastUsed) return -1;
    if (a.lastUsed < b.lastUsed) return 1;
    return 0;
  });

  for (let i = POLYLINE_CACHE_ENTRIES.length - 1; i > MAX_POLYLINE_CACHE_ENTRIES; i--) {
    let entry = POLYLINE_CACHE_ENTRIES.pop();
    delete POLYLINE_CACHE[entry.routeKey];
  }
}

export interface IMappingPolyline {
  getPolyline(route: ICoordinates[], travelMode?: SCHEDULER_TRAVEL_MODE): Promise<string>;
}

const POLYLINE_COLOR_HEX = '#526DFF';
const POLYLINE_COLOR_OPACITY = 0.75;
const POLYLINE_COLOR_RGBA = 'rgba(82, 109, 255, 0.75)';
const POLYLINE_WIDTH = 6;

export { POLYLINE_WIDTH, POLYLINE_COLOR_RGBA, POLYLINE_COLOR_OPACITY, POLYLINE_COLOR_HEX, findPolylineCache, pushPolylineCache };
